import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// HOOKS....
import useApi from '../../../../helpers/hooks/useApi';
import { useAuth } from '../../../../helpers/hooks/useAuth';

// COMPONENTS...
import { LayoutDashboardPanel } from '../../../../components/Layout/LayoutDashboard';
import { Fieldset, Input } from '../../../../components/Form/Form';
import { Button } from '../../../../components/Button/Button';
import { Alert } from '../../../../components/Alert/Alert';

// STYLES...
import { Form, FormGroup, FormItem, Label } from '../../../../components/Form/FormStyled';
import { FakeLoader } from '../../../../components/FakeLoader/FakeLoaderStyled';

// TYPES...
import { SectorType } from '../../../../helpers/types/Types';

export function SectorUpdate() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const api = useApi();
    const auth = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();
    const token = auth.getToken();

    const [backendToken, setBackendToken] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [sector, setSector] = useState<SectorType>();
    const [name, setName] = useState(sector?.name);
    // const [type, setType] = useState(sector?.type?.original);

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    useEffect(() => {
        const getSector = async () => {
            const cat = await api.getSector(host, token, id);

            setSector(cat);
            setName(cat?.name);
            // setType(cat?.type);
            setLoading(false);
        }
        getSector();
    }, []);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = await api.setSectorUpdate(host, token, id, name);

        if (data.error) {
            setError(data.error);
        } else {
            setName(data.name);
            navigate('/panel/client/sectors');
        }
    }

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="dashboardPanel"
                title="Editar setor"
                description="Altere os dados da setor"
                linkId="returnListSectors"
                linkRoute="/panel/client/sectors"
                linkName="Voltar"
                linkIcon="text__undo"
            >
                {error &&
                    <Alert
                        isError
                        closeAfter={7000}
                        title="Erro"
                        icon="code__errorCircle"
                        message={error}
                        state={error}
                        setState={setError}
                    />
                }

                <Form id="formSector" onSubmit={handleSubmit}>
                    <Fieldset
                        toTheForm="formSector"
                        name="dadosSector"
                        title="Dados"
                    >
                        <FormGroup>
                            {loading
                                ? <FakeLoader width={90} height={4} />
                                : <FormItem>
                                    <Label htmlFor="name"> Nome </Label>
                                    <Input
                                        otherClass="name"
                                        id="name"
                                        type="text"
                                        name="name"
                                        value={name}
                                        onChange={event => setName(event.target.value)}
                                    />
                                </FormItem>
                            }

                            {/* {loading
                                ? <FakeLoader width={90} height={4} />
                                : <FormItem>
                                    <Label htmlFor="sector"> Tipo <span id="info" className="required__field"> * </span> </Label>
                                    <Select
                                        className="" name="type" id="type" required aria-describedby="info"
                                        value={type}
                                        onChange={event => setType(parseInt(event.target.value))}
                                    >
                                        <option value="1"> Ouvidoria </option>
                                        <option value="2"> e-SIC </option>
                                        <option value="3"> Poda de árvore </option>
                                        <option value="4"> Iluminação pública </option>
                                    </Select>
                                </FormItem>
                            } */}
                        </FormGroup>
                    </Fieldset>

                    <FormGroup>
                        <FormItem>
                            <Button type="submit" name="update_category"> Enviar </Button>
                        </FormItem>
                    </FormGroup>
                </Form>
            </LayoutDashboardPanel>
            : <></>
    );
}