import { Link } from "react-router-dom";

// COMPONENTS...
import { Aside } from "../Aside/Aside";
import { HeaderMenu } from "../Navbar/Navbar";
import { Li } from "../List/List";
import { LinkButton } from "../Link/Link";

// STYLES...
import { LayoutDashboardContainer } from "./LayoutDashboardStyled";

// TYPES...
import { LayoutDashboardPanelType } from "./LayoutDashboardType";

export function LayoutDashboardPanel({ id, title, description, linkId, linkRoute, linkName, linkIcon, children }: LayoutDashboardPanelType) {
    return (
        <LayoutDashboardContainer id={id}>
            <div className="dashboard__wrapper">
                <Aside>
                    <Li dataRef="itemDash" classe="sidebar__item have__children active">
                        <Link to="/panel/client/dashboard" aria-label="Dashboard">
                            <span className="icon__ layout__arrange"></span>
                            <span className="text__"> Dashboard </span>
                        </Link>
                    </Li>

                    <Li dataRef="itemDash" classe="sidebar__item">
                        <Link to="/panel/client/sectors" aria-label="Setores">
                            <span className="icon__ shopping__sort"></span>
                            <span className="text__"> Setores </span>
                        </Link>
                    </Li>

                    <Li dataRef="itemDash" classe="sidebar__item">
                        <Link to="/panel/client/categories" aria-label="Categorias">
                            <span className="icon__ shopping__sort2"></span>
                            <span className="text__"> Categorias </span>
                        </Link>
                    </Li>

                    <Li dataRef="itemDash" classe="sidebar__item">
                        <Link to="/panel/client/ombudsmans" aria-label="Ouvidoria">
                            <span className="icon__ device__mic"></span>
                            <span className="text__"> Ouvidoria </span>
                        </Link>
                    </Li>

                    <Li dataRef="itemDash" classe="sidebar__item">
                        <Link to="/panel/client/e-sics" aria-label="e-SIC">
                            <span className="icon__ code__infoCircle"></span>
                            <span className="text__"> e-SIC </span>
                        </Link>
                    </Li>

                    <Li dataRef="itemDash" classe="sidebar__item">
                        <Link to="/panel/client/pruning-trees" aria-label="Poda de árvore">
                            <span className="icon__ home__wood"></span>
                            <span className="text__"> Poda de árvore </span>
                        </Link>
                    </Li>

                    <Li dataRef="itemDash" classe="sidebar__item">
                        <Link to="/panel/client/street-lightings" aria-label="Iluminação pública">
                            <span className="icon__ general__thunder"></span>
                            <span className="text__"> Iluminação pública </span>
                        </Link>
                    </Li>

                    {/* <Li
                        classe="sidebar__item"
                        dataRef="itemSector"
                        isDrop
                    // openDrop={() => handleDropdown}
                    >
                        <a href="#" onClick={() => setDrop(!drop)}>
                            <span className="icon__ shopping__sort"></span>
                            <span className="text__"> Setores </span>
                        </a>

                        {drop}

                        <MenuDropdown id="dropSector">
                            <li className="dropdown__item"> <Link to="/panel/dashboard"> Children </Link> </li>
                            <li className="dropdown__item"> <Link to="/panel/dashboard"> Children </Link> </li>
                            <li className="dropdown__item"> <Link to="/panel/dashboard"> Children </Link> </li>
                        </MenuDropdown>
                    </Li> */}
                </Aside>

                <div className="dashboard__inner">
                    <HeaderMenu />

                    <main className="dashboard__content">
                        <div className="dashboard__title">
                            <div className="title__infos">
                                <h3> {title} </h3>
                                {description && <p> {description} </p> }
                            </div>

                            {linkRoute &&
                                <div className="title__action">
                                    <LinkButton
                                        isClear
                                        id={linkId}
                                        link={linkRoute}
                                    >
                                        <span className={`icon__ ${linkIcon}`}> </span>
                                        {linkName}
                                    </LinkButton>
                                </div>
                            }
                        </div>

                        <div className="dashboard__infos">
                            <div className="dashboard__info__content"> {children} </div>
                        </div>
                    </main>
                </div>
            </div>
        </LayoutDashboardContainer>
    );
}