// COMPONENTS...
import { useEffect, useState } from 'react';
import { LayoutDashboardPanel } from '../../../../components/Layout/LayoutDashboard';

// HOOKS...
import useApi from '../../../../helpers/hooks/useApi';
import { useAuth } from '../../../../helpers/hooks/useAuth';

export function DashboardPanel() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const api = useApi();
    const auth = useAuth();

    const token = auth.getToken();

    const [backendToken, setBackendToken] = useState(false);

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="dashboardPanel"
                title="Dashboard"
                description="Painel administrador do sistema"
            >
                Dashboard do cliente
            </LayoutDashboardPanel>
            : <></>
    );
}