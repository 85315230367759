import styled from "styled-components";

export const LayoutAuthContainer = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: ${props => props.theme.themeColors.backgroundTertiary};

    display: flex;
    align-items: center;

    .auth__wrapper {
        position: relative;

        flex: 9;

        height: inherit;
        overflow-y: auto;
        padding: 2rem;

        display: flex;
        align-items: center;
        justify-content: center;

        #returnBack {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .auth__content {
            width: 100%;
            max-width: 40%;

            text-align: center;

            display: flex;
            flex-direction: column;
            align-items: stretch;

            >img { // ">" Para pegar somente a imagem dentro do auth__content.
                align-self: center;
            }

            .auth__separator {
                margin: 2rem 0 1rem 0;

                display: flex;
                align-items: center;

                font-size: 0.8rem;
                color: ${props => props.theme.themeColors.textSecondary};

                &::before,
                &::after {
                    content: '';
                    flex: 1;

                    height: 0.063rem;
                    background-color: ${props => props.theme.themeColors.backgroundPrimary};
                }

                &::before {
                    margin-right: 1rem;
                }

                &::after {
                    margin-left: 1rem;
                }
            }

            h3 {
                text-align: center;
            }

            form.formAuth {
                padding: 0;

                fieldset {
                    padding: 0;
                    border: 0;

                    legend {
                        display: none;
                    }
                }

                label {
                    font-weight: 600;
                    font-size: 0.8rem;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        flex-direction: column;

        .auth__wrapper {
            flex: none;

            width: 100%;
        }
    }

    @media (max-width: 768px) {
        .auth__wrapper {
            .auth__content {
                max-width: 70%;
            }
        }
    }

    @media (max-width: 425px) {
        .auth__wrapper {
            .auth__content {
                max-width: 100%;
            }
        }
    }
`;

export const AsideAuthContainer = styled.aside`
    position: relative;

    flex: 4;

    height: inherit;
    padding: 7.5rem 5rem;
    border-right: 0.063rem solid ${props => props.theme.themeColors.backgroundPrimary};
    background-color: ${props => props.theme.themeColors.backgroundSecondary};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        max-width: 15rem;
    }

    h1 {
        text-align: center;
    }

    @media(max-width: 1024px) {
        display: none;
    }
`;