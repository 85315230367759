// STYLES...
import { LinkContainer, LinkButtonContainer } from './LinkStyled';

// TYPES...
import { LinkType } from './LinkType';

export function LinkSimple({ id, link, linkType, target, children }: LinkType) {
    return (
        <LinkContainer
            id={id}
            to={link}
            rel={linkType}
            target={target}
        > {children} </LinkContainer>
    );
}

export function LinkButton({ id, link, children, isOutlined = false, isDanger = false, isClear = false }: LinkType) {
    return (
        <LinkButtonContainer
            id={id}
            to={link}
            className={`${isOutlined ? 'outlined__' : ''} ${isDanger ? 'danger__' : ''} ${isClear ? 'clear__' : ''} `}
        > {children} </LinkButtonContainer>
    );
}

