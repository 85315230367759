import { useEffect, useState } from 'react';

// HOOKS...
import useApi from '../../../../helpers/hooks/useApi';
import { useAuth } from '../../../../helpers/hooks/useAuth';

// COMPONENTS...
import { LayoutDashboardPanel } from '../../../../components/Layout/LayoutDashboard';
import { Table, TableHead, TableBody } from '../../../../components/Table/Table';
import { Badge } from '../../../../components/Badge/Badge';
import { DropdownButton, DropdownItemLink } from '../../../../components/Dropdown/Dropdown';
import { Alert } from '../../../../components/Alert/Alert';

// TYPES...
import { OmbudsmanType } from '../../../../helpers/types/Types';

// STYLES...
import { Form } from '../../../../components/Form/FormStyled';

export function OmbudsmanRead() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const api = useApi();
    const auth = useAuth();
    const token = auth.getToken();

    const [backendToken, setBackendToken] = useState(false);
    const [error, setError] = useState('');
    const [ombudsman, setOmbugsman] = useState<OmbudsmanType[]>([]);

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    useEffect(() => {
        getOmbudsmans();
    }, []);

    const getOmbudsmans = async () => {
        const omb = await api.getOmbudsmans(host, token);
        setOmbugsman(omb.data);
    }

    const handleStatus = async (uuid: string, status: number) => {
        const data = await api.setOmbudsmanStatus(host, token, uuid, status);

        if (data.error) {
            setError(data.error);
        } else {
            getOmbudsmans();
        }
    }
    
    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="dashboardPanel"
                title="Ouvidorias"
                description="Lista de ouvidorias solicitadas"
            >
                {error &&
                    <Alert
                        isError
                        closeAfter={7000}
                        title="Erro"
                        icon="code__errorCircle"
                        message={error}
                        state={error}
                        setState={setError}
                    />
                }

                <Table id="tableSectors">
                    <TableHead>
                        <tr>
                            <th scope="col"> # </th>
                            <th scope="col"> Código </th>
                            <th scope="col"> Setor </th>
                            <th scope="col"> Categoria </th>
                            <th scope="col"> Nome </th>
                            <th scope="col"> Data </th>
                            <th scope="col"> Status </th>
                            <th scope="col"> Ações </th>
                        </tr>
                    </TableHead>

                    <TableBody>
                        {ombudsman.map((ombudsman, index) => {
                            return (
                                <tr key={index}>
                                    <td> {ombudsman.id} </td>
                                    <td> {ombudsman.code} </td>
                                    <td> {ombudsman.sector.name} </td>
                                    <td> {ombudsman.category.name} </td>
                                    <td> {ombudsman.anonymous ? 'Anônimo' : ombudsman.name} </td>
                                    <td> {ombudsman.created_at} </td>
                                    <td>
                                        {(() => {
                                            if (ombudsman.status === 'Pendente') {
                                                return <Badge isWarning> {ombudsman.status} </Badge>

                                            } else if (ombudsman.status === 'Em andamento') {
                                                return <Badge isProcess> {ombudsman.status} </Badge>

                                            } else if (ombudsman.status === 'Concluída') {
                                                return <Badge isSuccess> {ombudsman.status} </Badge>

                                            } else if (ombudsman.status === 'Negada') {
                                                return <Badge isDanger> {ombudsman.status} </Badge>
                                            }
                                        })()}
                                    </td>
                                    <td className="td__action">
                                        <DropdownButton
                                            id={ombudsman.uuid}
                                            childrenId={ombudsman.uuid}
                                            name="Ações"
                                        >
                                            <DropdownItemLink
                                                link={`/panel/client/ombudsmans/show/${ombudsman.uuid}`}
                                                name="Detalhes"
                                                icon="general__visible"
                                            />

                                            {(ombudsman.status === 'Pendente' || ombudsman.status === 'Em andamento') &&
                                                <DropdownItemLink
                                                    link={`/panel/client/ombudsmans/reply/${ombudsman.uuid}`}
                                                    name="Responder"
                                                    icon="communication__chat5"
                                                />
                                            }

                                            <Form
                                                className="full__"
                                                onClick={() => handleStatus(ombudsman.uuid, 3)}
                                            >
                                                <DropdownItemLink
                                                    link=""
                                                    name="Concluir solicitação"
                                                    icon="navigation__check"
                                                />
                                            </Form>

                                            {/* <Form
                                                className="full__"
                                                onClick={() => handleStatus(ombudsman.uuid, 4)}
                                            >
                                                <DropdownItemLink
                                                    link=""
                                                    name="Negar solicitação"
                                                    icon="navigation__close"
                                                />
                                            </Form> */}
                                        </DropdownButton>
                                    </td>
                                </tr>
                            );
                        })}
                    </TableBody>
                </Table>
            </LayoutDashboardPanel>
            : <></>
    );
}