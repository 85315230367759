import styled from "styled-components";

export const AlertContainer = styled.div`
    z-index: 20;
    position: fixed;
    top: 30px;
    right: 30px;

    width: fit-content;
    max-width: 30rem;
    min-height: 6.25rem;
    height: auto;
    padding: 1rem;
    border-radius: 1.25rem;
    border: 0.063rem solid ${props => props.theme.themeColors.backgroundPrimary};
    background-color: ${props => props.theme.themeColors.colorGlass};
    -webkit-backdrop-filter: blur(0.375rem);
    backdrop-filter: blur(0.375rem);
    box-shadow: 0 1.25rem 3rem rgb(0 0 0 / 20%);

    .alert__inner {
        width: 100%;
        height: 100%;

        .alert__header {
            margin-bottom: 1rem;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            h6 {
                margin: 0;
                text-transform: uppercase;
            }

            .icon__alert {
                width: 2rem;
                height: 2rem;
                margin-right: 1rem;
            }
        }

        .alert__content {
            padding: 0 1rem 1rem 1rem;

            p {
                /* font-size: 0.8rem; */
                text-align: left;
                color: ${props => props.theme.themeColors.textPrimary};
            }
        }
    }

    #close__alert {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }

    &.alert__remove {
        display: none;
    }

    &.alert__error {
        .alert__inner .alert__header .icon__alert {
            background-color: ${props => props.theme.colors.red};
        }
    }

    &.alert__success {
        .icon__alert {
            background-color: ${props => props.theme.colors.green};
        }
    }

    &.alert__warning {
        .icon__alert {
            background-color: ${props => props.theme.colors.yellow};
        }
    }

    &.alert__code {
        .icon__alert {
            background-color: ${props => props.theme.colors.blue};
        }

        .alert__content {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                margin-left: 0.5rem;
            }
        }
    }
`;