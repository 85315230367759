import styled from "styled-components";

export const LayoutDashboardContainer = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .dashboard__wrapper {
        width: 100%;
        height: 100%;

        display: flex;

        .dashboard__inner {
            position: relative;

            width: 100%;
            height: 100%;

            .dashboard__content {
                height: calc(100% - 5rem);
                overflow-Y: auto;
                margin-top: 5rem;

                .dashboard__title {
                    width: 100%;
                    height: 7.5rem;
                    padding: 1rem 2rem;
                    background-color: ${props => props.theme.themeColors.backgroundPrimary};

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .title__infos {
                        flex: 1;

                        p {
                            font-style: italic;
                        }
                    }
                }

                .dashboard__infos {
                    min-height: calc(100% - 7.5rem);
                    padding: 2rem;
                    background-color: ${props => props.theme.themeColors.backgroundSecondary};

                    .dashboard__info__content {
                        width: 100%;
                        min-height: 100%;
                        padding: 1.5rem;
                        border-radius: 0.75rem;
                        background-color: ${props => props.theme.themeColors.backgroundPrimary};
                    }
                }
            }
        }
    }
`;