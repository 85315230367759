import { FormEvent, useState, useEffect } from "react";
import { Link } from "react-router-dom";

// HOOKS...
import useApi from "../../../helpers/hooks/useApi";

// COMPONENTS...
import { Chat, ChatMessage } from "../../../components/Chat/Chat";
import { CardDlInfo } from "../../../components/Card/Card";
import { Header } from "../../../components/Header/Header";
import { ListNav } from "../../../components/List/List";
import { Alert } from "../../../components/Alert/Alert";

// STYLES...
import { SolicitationChat, SolicitationContent, SolicitationPage } from "./SolicitationStyled";
import { FakeLoader } from "../../../components/FakeLoader/FakeLoaderStyled";

// TYPES...
import { ReplyType } from "../../../helpers/types/Types";

export function Solicitation() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
    const api = useApi();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [solicitation] = useState(JSON.parse(localStorage.getItem('solicitation') || '{}')); // Solicitação...
    const [replies, setReplies] = useState<ReplyType[]>([]); // Respostas da solicitação...
    const [reply, setReply] = useState('');

    useEffect(() => {
        getReplies();
    }, []);

    const getReplies = async () => {
        const response = await api.getRepliesSolicitation(host, solicitation.data.code);

        setReplies(response);
        setLoading(false);
    };

    const handleSubmitResply = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const code = solicitation.data.code;
        const data = await api.setReplyCidadao(host, code, reply);

        if (data.error) {
            setReply('');
            setError(data.error);
        } else {
            setReply('');
            getReplies();
        }
    }

    const handleReturnHome = () => {
        let uri = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
        var captureUrl = 0;

        if (process.env.NODE_ENV !== 'production') {
            captureUrl = uri.indexOf('localhost:3000');
        } else {
            captureUrl = uri.indexOf('servicosaocidadao.net.br');
        }

        if (captureUrl !== -1) {
            let url = uri.slice(captureUrl);
            window.location.href = `http://${url}`;
        } else {
            console.log('Não existe URL padrão na URI');
        }
    }

    return (
        <SolicitationPage>
            {error &&
                <Alert
                    isError
                    closeAfter={7000}
                    title="Erro"
                    icon="code__errorCircle"
                    message={error}
                    state={error}
                    setState={setError}
                />
            }

            <SolicitationContent>
                <Header>
                    <ListNav>
                        <li>
                            <span className="itemButton__" onClick={handleReturnHome}>
                                <span className="icon__ home"></span>
                                Home
                            </span>
                        </li>

                        <li>
                            <Link to="/services">
                                <span className="icon__ general__settings2"></span>
                                Serviços
                            </Link>
                        </li>
                    </ListNav>
                </Header>

                <div className="solicitation__header">
                    {loading
                        ? <FakeLoader width={100} height={4.375} />
                        : <>
                            <h2> Solicitação<span className="full__stop">.</span> <br /> {solicitation.data.code} </h2>
                            <p> Acompanhe abaixo todos os dados base da sua solicitação e o estatus em que se encontra. </p>
                        </>
                    }
                </div>

                <dl>
                    <div className="line__itens">
                        {loading
                            ? <FakeLoader width={21.875} height={4.375} />
                            : <CardDlInfo
                                isStatus={solicitation.data.status}
                                icon="code__doneCircle"
                                name="Status"
                                info={solicitation.data.status}
                            />
                        }

                        {loading
                            ? <FakeLoader width={21.875} height={4.375} />
                            : <CardDlInfo
                                icon="text__code"
                                name="Código"
                                info={solicitation.data.code}
                            />
                        }

                        {loading
                            ? <FakeLoader width={21.875} height={4.375} />
                            : <CardDlInfo
                                icon="home__timer"
                                name="Aberta em"
                                info={solicitation.data.created_at}
                            />
                        }
                    </div>

                    <div className="line__itens">
                        {solicitation.data.sector
                            ? loading
                                ? <FakeLoader width={21.875} height={4.375} />
                                : <CardDlInfo
                                    icon="media__playlist2"
                                    name="Setor"
                                    info={solicitation.data.sector?.name}
                                />
                            : ''
                        }

                        {solicitation.data.category
                            ? loading
                                ? <FakeLoader width={21.875} height={4.375} />
                                : <CardDlInfo
                                    icon="text__bulletList"
                                    name="Categoria"
                                    info={solicitation.data.category.name}
                                />
                            : ''
                        }
                    </div>

                    {solicitation.data.anonymous === 0 &&
                        <>
                            <div className="line__itens">
                                {loading
                                    ? <FakeLoader width={21.875} height={4.375} />
                                    : <CardDlInfo
                                        icon="general__user"
                                        name="Nome"
                                        info={solicitation.data.name}
                                    />
                                }

                                {loading
                                    ? <FakeLoader width={21.875} height={4.375} />
                                    : <CardDlInfo
                                        icon="communication__group"
                                        name="Sexo"
                                        info={solicitation.data.sex}
                                    />
                                }

                                {loading
                                    ? <FakeLoader width={21.875} height={4.375} />
                                    : <CardDlInfo
                                        icon="communication__mail"
                                        name="E-mail"
                                        info={solicitation.data.email}
                                    />
                                }
                            </div>

                            <div className="line__itens">
                                {loading
                                    ? <FakeLoader width={21.875} height={4.375} />
                                    : <CardDlInfo
                                        icon="general__edit"
                                        name="Escolaridade"
                                        info={solicitation.data.schooling ? solicitation.data.schooling : 'Não informado'}
                                    />
                                }

                                {loading
                                    ? <FakeLoader width={21.875} height={4.375} />
                                    : <CardDlInfo
                                        icon="clothes__shirt"
                                        name="Ocupação"
                                        info={solicitation.data.occupation}
                                    />
                                }
                            </div>

                            <div className="line__itens">
                                {loading
                                    ? <FakeLoader width={21.875} height={4.375} />
                                    : <CardDlInfo
                                        icon="home"
                                        name="Rua"
                                        info={solicitation.data.road}
                                    />
                                }

                                {loading
                                    ? <FakeLoader width={21.875} height={4.375} />
                                    : <CardDlInfo
                                        icon="communication__flag"
                                        name="Número"
                                        info={solicitation.data.number}
                                    />
                                }

                                {loading
                                    ? <FakeLoader width={21.875} height={4.375} />
                                    : <CardDlInfo
                                        icon="communication__flag"
                                        name="Bairro"
                                        info={solicitation.data.district}
                                    />
                                }

                                {loading
                                    ? <FakeLoader width={21.875} height={4.375} />
                                    : <CardDlInfo
                                        icon="code__infoCircle"
                                        name="Complemento"
                                        info={solicitation.data.complement}
                                    />
                                }

                                {loading
                                    ? <FakeLoader width={21.875} height={4.375} />
                                    : <CardDlInfo
                                        icon="home__earth"
                                        name="Cidade"
                                        info={solicitation.data.city}
                                    />
                                }

                                {loading
                                    ? <FakeLoader width={21.875} height={4.375} />
                                    : <CardDlInfo
                                        icon="home__earth"
                                        name="Cep"
                                        info={solicitation.data.zip_code}
                                    />
                                }

                                {loading
                                    ? <FakeLoader width={21.875} height={4.375} />
                                    : <CardDlInfo
                                        icon="home__earth"
                                        name="Estado"
                                        info={solicitation.data.state}
                                    />
                                }
                            </div>
                        </>
                    }

                    <div className="line__itens">
                        {(solicitation.data.code.slice(0, 4) === 'OUVI' || solicitation.data.code.slice(0, 4) === 'ESIC')
                            ? loading
                                ? <FakeLoader width={21.875} height={4.375} />
                                : <CardDlInfo
                                    icon="communication__chat6"
                                    name="Resumo"
                                    info={solicitation.data.resume}
                                />
                            : ''
                        }

                        {(solicitation.data.code.slice(0, 4) === 'PODA' || solicitation.data.code.slice(0, 4) === 'ILUM')
                            ? loading
                                ? <FakeLoader width={21.875} height={4.375} />
                                : <CardDlInfo
                                    icon="communication__thumbtack"
                                    name="Motivo"
                                    info={solicitation.data.reason}
                                />
                            : ''
                        }
                    </div>
                </dl>
            </SolicitationContent>

            <SolicitationChat>
                <Chat
                    reply={reply}
                    setReply={event => setReply(event.target.value)}
                    onReplySubmission={event => handleSubmitResply(event)}
                    newMessage={(solicitation.data.status === 'Pendente' || solicitation.data.status === 'Em andamento') ? false : true}
                >
                    {loading
                        ? <FakeLoader width={100} height={4.375} />
                        : <ChatMessage
                            message={solicitation.data.request}
                        />
                    }

                    {replies.map((replies, index) => {
                        if (replies.author.original === 1) { // cidadão...
                            return (
                                <>
                                    {loading
                                        ? <FakeLoader width={20} height={4.375} />
                                        : <ChatMessage
                                            key={index}
                                            message={replies.reply}
                                            author={replies.author.formated}
                                        />
                                    }
                                </>
                            );
                        } else {
                            return (
                                <>
                                    {loading
                                        ? <FakeLoader width={15} height={4.375} />
                                        : <ChatMessage
                                            isEntrance
                                            key={index}
                                            message={replies.reply}
                                            author={replies.author.formated}
                                        />
                                    }
                                </>
                            );
                        }
                    })}
                </Chat>
            </SolicitationChat>
        </SolicitationPage>
    );
}