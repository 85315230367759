import styled from "styled-components";

export const TableContainer = styled.table`
    width: 100%;
    height: auto;
    margin: 1rem 0;
    border-radius: 0.75rem;
    border: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};
`;

export const TheadContainer = styled.thead`
    width: 100%;
    height: 3.75rem;
    background-color: ${props => props.theme.themeColors.backgroundSecondary};
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;

    tr {
        width: 100%;
        
        th {
            border-bottom: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};
            background-color: transparent;

            /* color: ${props => props.theme.themeColors.textPrimary}; */
            text-transform: uppercase;

            &:first-child {
                border-top-left-radius: 0.75rem;
            }

            &:last-child {
                border-top-right-radius: 0.75rem;
            }
        }
    }
`;

export const TbodyContainer = styled.tbody`
    width: 100%;
    max-height: 41.25rem;
    overflow: auto;

    tr {
        height: 3.438rem;
        
        td {
            height: inherit;
            border-right: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};
            border-bottom: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};

            color: ${props => props.theme.themeColors.textPrimary};
            text-align: center;

            &:first-child {
                border-left: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};
            }

            &.td__action {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                
                a, button {
                    /* padding: 0 0.5rem; */

                    .icon__ {
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }
            }
        }

        &:last-child {
            td {
                border-bottom: none;

                &:first-child {
                    border-bottom-left-radius: 0.75rem;
                }

                &:last-child {
                    border-bottom-right-radius: 0.75rem;
                }
            }
        }

        &:hover {
            transition: .2s background-color ease;
            background-color: ${props => props.theme.themeColors.backgroundSecondary};
        }
    }
`;