// STYLES...
import { ButtonContainer } from './ButtonStyled';
import { Link } from "react-router-dom";

// TYPES...
import { ButtonType, ButtonItemLinkType } from './ButtonType';

export function Button({
    isOutlined = false,
    isDanger = false,
    isClear = false,
    isIcon = false,
    isFull = false,
    ...props
}: ButtonType) {
    return (
        <ButtonContainer
            className={`button__ ${isFull ? 'full__' : ''} ${isOutlined ? 'outlined__' : ''} ${isDanger ? 'danger__' : ''} ${isClear ? 'clear__' : ''} ${isIcon ? 'icon__bt' : ''}`}
            {...props}>
        </ButtonContainer>
    );
}

export function ButtonItemLink({ name, link, icon, target }: ButtonItemLinkType) {
    return (
        <Link to={link} target={target}>
            {icon && <span className={`icon__ ${icon}`}></span>}
            {name}
        </Link>
    );
}


export function ButtonPrintPdf({title, link, icon, target}: any)
{
    return (
        <a href={link} target={target}>
            <Button isOutlined>
                {icon && <span className={`icon__ ${icon}`}></span>}
                {title}
            </Button>
        </a>
    );
}
