import { Link } from "react-router-dom";

// HOOKS...
import useApi from "../../helpers/hooks/useApi";
import { useAuth } from "../../helpers/hooks/useAuth";

// COMPONENTS...
import { ListNav } from "../List/List";
// import { DropdownButton, DropdownItemButtom, DropdownItemLink } from "../Dropdown/Dropdown";

// STYLES...
import avatarImg from '../../assets/images/avatar.png';
import { HeaderContainer } from "./NavbarStyled";

export function HeaderMenu() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
    const api = useApi();
    const logged = useAuth();
    const user = useAuth().getUser();

    // const handleLogout = async () => {
    //     if (logged.isLogged()) {
    //         await api.doLogout(host, logged.doLogout());
    //         window.location.href = '/';
    //     }
    // }

    return (
        <HeaderContainer>
            {/* <ListNav isStart>
                <li className="just__icon">
                    <Button isClear onClick={handleSidebar}>
                        <span className="icon__ text__menu"></span>
                    </Button>
                </li>
            </ListNav> */}

            <ListNav>
                <li className="just__icon">
                    <Link to="/dashboard">
                        <span className="icon__ general__notifications"></span>
                    </Link>
                </li>

                {/* <li>
                    <DropdownButton
                        id="userMenu"
                        childrenId="userMenu"
                        name="Usuário"
                    >
                        <DropdownItemLink
                            link=""
                            name="Configurações"
                            icon="general__setings"
                        />

                        <DropdownItemButtom 
                            name="Sair"
                            icon="electric__shutdown"
                            onClick={handleLogout}
                        />
                    </DropdownButton>
                </li> */}

                <li className="li__profile">
                    <Link to={`/panel/client/user/profile/${user.userId}`}>
                        <img src={avatarImg} alt="" />
                    </Link>
                </li>
            </ListNav>
        </HeaderContainer>
    );
}