import { FormEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// HOOKS....
import useApi from "../../../helpers/hooks/useApi";

// COMPONENTS...
import { LayoutBase } from "../../../components/Layout/LayoutBase";
import { ListNav } from "../../../components/List/List";
import { Header } from "../../../components/Header/Header";
import { Fieldset, Input } from "../../../components/Form/Form";
import { Button } from "../../../components/Button/Button";
import { Alert } from "../../../components/Alert/Alert";

// STYLES...
import { CheckboxContainer, Form, FormGroup, FormItem, Label, Select, Textarea } from '../../../components/Form/FormStyled';

// TYPES...
import { CategoryType, SectorType } from "../../../helpers/types/Types";

export function Ombudsman() {
    const api = useApi();
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const [error, setError] = useState('');
    const [anonymous, setAnonymous] = useState(false);
    const [codeSolicitation, setCodeSolicitation] = useState('');
    const [sectorList, setSectorList] = useState<SectorType[]>([]);
    const [categoryList, setCategoryList] = useState<CategoryType[]>([]);
    const [sector, setSector] = useState('');
    const [category, setCategory] = useState('');
    const [name, setName] = useState('');
    const [sex, setSex] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [cellphone, setcellphone] = useState('');
    const [schooling, setSchooling] = useState('');
    const [occupation, setOccupation] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [road, setRoad] = useState('');
    const [number, setNumber] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [complement, setComplement] = useState('');
    const [reference, setReference] = useState('');
    const [resume, setResume] = useState('');
    const [request, setRequest] = useState('');

    useEffect(() => {
        const getSectors = async () => { // Pega todos os states do bd.
            const slist = await api.getSectors(host);
            setSectorList(slist.data);
        }

        const getCategories = async () => { // Pega todos as categories do bd.
            const clist = await api.getCategories(host);
            setCategoryList(clist.data);
        }

        getSectors();
        getCategories();
    }, [api, host]);

    const handleClear = () => {
        setAnonymous(false);
        setSector('');
        setCategory('');
        setName('');
        setSex('');
        setEmail('');
        setTelephone('');
        setcellphone('');
        setSchooling('');
        setOccupation('');
        setZipCode('');
        setRoad('');
        setNumber('');
        setDistrict('');
        setCity('');
        setState('');
        setComplement('');
        setReference('');
        setResume('');
        setRequest('');
    }

    const handleQueryCep = async (event: any) => {
        const cep = event.target.value?.replace(/[^0-9]/g, '');
        setZipCode(cep);

        if (cep?.length === 8) {
            const data = await api.getCep(cep);

            setZipCode(data.cep.replace(/[^0-9]/g, ''));
            setState(data.uf);
            setCity(data.localidade);
            setDistrict(data.bairro);
            setRoad(data.logradouro);
            setComplement(data.complemento);
        }
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = await api.setOmbudsman(host, anonymous, sector, category, name, sex, email, telephone, cellphone, schooling, occupation, zipCode, road, number, district, city, state, complement, resume, request);

        if (data.code) {
            setCodeSolicitation(data.code);
            handleClear();
        } else {
            setError(data.error);
        }
    }

    const handleReturnHome = () => {
        let uri = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
        var captureUrl = 0;

        if (process.env.NODE_ENV !== 'production') {
            captureUrl = uri.indexOf('localhost:3000');
        } else {
            captureUrl = uri.indexOf('servicosaocidadao.net.br');
        }

        if (captureUrl !== -1) {
            let url = uri.slice(captureUrl);
            window.location.href = `http://${url}`;
        } else {
            console.log('Não existe URL padrão na URI');
        }
    }

    return (
        <LayoutBase name="About Page" id="aboutPage">
            <Header>
                <ListNav>
                    <li>
                        <span className="itemButton__" onClick={handleReturnHome}>
                            <span className="icon__ home"></span>
                            Home
                        </span>
                    </li>

                    <li>
                        <Link to="/services">
                            <span className="icon__ general__settings2"></span>
                            Serviços
                        </Link>
                    </li>
                </ListNav>
            </Header>

            <div className="container__">
                <div>
                    <h1> Solicitar Ouvidoria<span className="full__stop">.</span> </h1>
                    <p> É uma unidade do setor público, que acolhe as demandas dos cidadãos, zelando pela garantia da qualidade dos serviços públicos. É o canal por meio do qual o cidadão pode apresentar sugestões, reclamações, solicitações, elogios e denúncias sobre a prestação de serviços públicos. </p>
                </div>

                {error &&
                    <Alert
                        isError
                        closeAfter={7000}
                        title="Erro"
                        icon="code__errorCircle"
                        message={error}
                        state={error}
                        setState={setError}
                    />
                }

                {codeSolicitation &&
                    <Alert
                        isCode
                        title="Código da solicitação"
                        icon="shopping__ticket"
                        value={codeSolicitation}
                        state={codeSolicitation}
                        setState={setCodeSolicitation}
                    />
                }

                <Form id="formOuvidoria" onSubmit={handleSubmit}>
                    <Fieldset
                        toTheForm="formOuvidoria"
                        name="tipoContatoOuvidoria"
                        title="Anônima"
                    >
                        <FormGroup>
                            <CheckboxContainer className="custom-control custom-checkbox">
                                <input
                                    id="anonymous"
                                    type="checkbox"
                                    name="anonymous"
                                    className="custom-control-input"
                                    value={anonymous === true ? 1 : 0}
                                    checked={anonymous}
                                    onChange={event => setAnonymous(event.target.checked)}
                                />
                                <Label className="custom-control-label" htmlFor="anonymous"> Anônima </Label>
                            </CheckboxContainer>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="sector"> Setor <span id="info" className="required__field"> * </span> </Label>
                                <Select
                                    id="sector"
                                    name="sector"
                                    required
                                    aria-describedby="info"
                                    value={sector}
                                    onChange={event => setSector(event.target.value)}
                                >
                                    <option value="" disabled> Selecione uma opção </option>

                                    {sectorList && sectorList.map((ele, key) =>
                                        <option key={key} value={ele.id}> {ele.name} </option>
                                    )};
                                </Select>
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="category"> Categoria <span id="info" className="required__field"> * </span> </Label>
                                <Select
                                    id="category"
                                    name="category"
                                    required
                                    aria-describedby="info"
                                    value={category}
                                    onChange={event => setCategory(event.target.value)}
                                >
                                    <option value="" disabled> Selecione uma opção </option>

                                    {categoryList && categoryList.map((element, key) =>
                                        <option key={key} value={element.id}> {element.name} </option>
                                    )};
                                </Select>
                            </FormItem>
                        </FormGroup>
                    </Fieldset>

                    {!anonymous &&
                        <>
                            <Fieldset
                                otherClass="anonymousMode"
                                toTheForm="formOuvidoria"
                                name="dadosOuvidoria"
                                title="Dados"
                            >
                                <FormGroup className="anonymousHidden__">
                                    <FormItem>
                                        <Label htmlFor="name"> Nome {anonymous === false && <span id="info" className="required__field"> * </span>} </Label>
                                        <Input
                                            id="name"
                                            type="text"
                                            name="name"
                                            value={name}
                                            onChange={event => setName(event.target.value)}
                                            required={anonymous === false && true}
                                        // aria-describedby={anonymous === false && 'info'}
                                        />
                                    </FormItem>

                                    <FormItem>
                                        <Label htmlFor="sex"> Sexo {anonymous === false && <span id="info" className="required__field"> * </span>} </Label>
                                        <Select
                                            id="sex"
                                            name="sex"
                                            value={sex}
                                            onChange={event => setSex(event.target.value)}
                                            required={anonymous === false && true}
                                        >
                                            <option value="" disabled> Selecione uma opção </option>
                                            <option value="1"> Feminino </option>
                                            <option value="2"> Masculino </option>
                                            <option value="3"> Outro </option>
                                            <option value="4"> Prefiro não informar </option>
                                        </Select>
                                    </FormItem>
                                </FormGroup>

                                <FormGroup>
                                    <FormItem>
                                        <Label htmlFor="email"> E-mail {anonymous === false && <span id="info" className="required__field"> * </span>} </Label>
                                        <Input
                                            id="email"
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={event => setEmail(event.target.value)}
                                            required={anonymous === false && true}
                                        />
                                    </FormItem>
                                </FormGroup>

                                <FormGroup>
                                    <FormItem>
                                        <Label htmlFor="telephone"> Telefone </Label>
                                        <Input
                                            id="telephone"
                                            type="tel"
                                            name="telephone"
                                            minLength={8}
                                            maxLength={10}
                                            value={telephone}
                                            onChange={event => setTelephone(event.target.value)}
                                        />
                                    </FormItem>

                                    <FormItem>
                                        <Label htmlFor="cellphone"> Celular </Label>
                                        <Input
                                            id="cellphone"
                                            type="tel"
                                            name="cellphone"
                                            className="cellphone"
                                            minLength={11}
                                            maxLength={11}
                                            value={cellphone}
                                            onChange={event => setcellphone(event.target.value)}
                                        />
                                    </FormItem>
                                </FormGroup>

                                <FormGroup>
                                    <FormItem>
                                        <Label htmlFor="schooling"> Escolaridade {anonymous === false && <span id="info" className="required__field"> * </span>} </Label>
                                        <Select
                                            name="schooling"
                                            id="schooling"
                                            value={schooling}
                                            onChange={event => setSchooling(event.target.value)}
                                            required={anonymous === false && true}
                                        >
                                            <option value="" disabled> Selecione uma opção </option>
                                            <option value="1"> Analfabeto </option>
                                            <option value="2"> Ensino Fundamental incompleto </option>
                                            <option value="3"> Ensino Fundamental completo </option>
                                            <option value="4"> Ensino Médio completo </option>
                                            <option value="5"> Ensino Médio incompleto </option>
                                            <option value="6"> Graduação ou Tecnologo </option>
                                            <option value="7"> Pós-graduação </option>
                                            <option value="8"> Mestrado </option>
                                            <option value="9"> Doutorado </option>
                                            <option value="10"> Pós-Doutorado </option>
                                        </Select>
                                    </FormItem>

                                    <FormItem>
                                        <Label htmlFor="occupation"> Ocupação {anonymous === false && <span id="info" className="required__field"> * </span>} </Label>
                                        <Input
                                            type="text"
                                            name="occupation"
                                            className="occupation"
                                            id="occupation"
                                            value={occupation}
                                            onChange={event => setOccupation(event.target.value)}
                                            required={anonymous === false && true}
                                        />
                                    </FormItem>
                                </FormGroup>
                            </Fieldset>

                            <Fieldset
                                otherClass="anonymousMode"
                                toTheForm="formOuvidoria"
                                name="enderecoOuvidoria"
                                title="Endereço"
                            >
                                <FormGroup>
                                    <FormItem>
                                        <Label htmlFor="zip_code"> CEP {anonymous === false && <span id="info" className="required__field"> * </span>} </Label>
                                        <Input
                                            id="zip_code"
                                            type="text"
                                            name="zip_code"
                                            className="zip_code"
                                            minLength={8}
                                            maxLength={8}
                                            data-mask="00000-000"
                                            value={zipCode}
                                            onChange={handleQueryCep}
                                            required={anonymous === false && true}
                                        />
                                    </FormItem>

                                    <FormItem>
                                        <Label htmlFor="road"> Rua {anonymous === false && <span id="info" className="required__field"> * </span>} </Label>
                                        <Input
                                            id="road"
                                            type="text"
                                            name="road"
                                            className="road"
                                            value={road}
                                            onChange={event => setRoad(event.target.value)}
                                            required={anonymous === false && true}
                                        />
                                    </FormItem>

                                    <FormItem>
                                        <Label htmlFor="number"> Número {anonymous === false && <span id="info" className="required__field"> * </span>} </Label>
                                        <Input
                                            id="number"
                                            type="text"
                                            name="number"
                                            className="number"
                                            value={number}
                                            onChange={event => setNumber(event.target.value)}
                                            required={anonymous === false && true}
                                        />
                                    </FormItem>
                                </FormGroup>

                                <FormGroup>
                                    <FormItem>
                                        <Label htmlFor="district"> Bairro {anonymous === false && <span id="info" className="required__field"> * </span>} </Label>
                                        <Input
                                            id="district"
                                            type="text"
                                            name="district"
                                            className="district"
                                            value={district}
                                            onChange={event => setDistrict(event.target.value)}
                                            required={anonymous === false && true}
                                        />
                                    </FormItem>

                                    <FormItem>
                                        <Label htmlFor="city"> Cidade {anonymous === false && <span id="info" className="required__field"> * </span>} </Label>
                                        <Input
                                            id="city"
                                            type="text"
                                            name="city"
                                            className="city"
                                            value={city}
                                            onChange={event => setCity(event.target.value)}
                                            required={anonymous === false && true}
                                        />
                                    </FormItem>

                                    <FormItem>
                                        <Label htmlFor="state"> Estado {anonymous === false && <span id="info" className="required__field"> * </span>} </Label>
                                        <Input
                                            id="state"
                                            type="text"
                                            name="state"
                                            className="state"
                                            minLength={2}
                                            maxLength={2}
                                            value={state}
                                            onChange={event => setState(event.target.value)}
                                            required={anonymous === false && true}
                                        />
                                    </FormItem>
                                </FormGroup>

                                <FormGroup>
                                    <FormItem>
                                        <Label htmlFor="complement"> Complemento </Label>
                                        <Input
                                            id="complement"
                                            type="text"
                                            name="complement"
                                            className="complement"
                                            value={complement}
                                            onChange={event => setComplement(event.target.value)}
                                        />
                                    </FormItem>

                                    <FormItem>
                                        <Label htmlFor="reference"> Referência </Label>
                                        <Input
                                            id="reference"
                                            type="text"
                                            name="reference"
                                            className="reference"
                                            value={reference}
                                            onChange={event => setReference(event.target.value)}
                                        />
                                    </FormItem>
                                </FormGroup>
                            </Fieldset>
                        </>
                    }

                    <Fieldset
                        toTheForm="formOuvidoria"
                        name="mensagemOuvidoria"
                        title="Mensagem"
                    >
                        <div className="form-group">
                            <Label htmlFor="resume"> Resumo {anonymous === false && <span id="info" className="required__field"> * </span>} </Label>
                            <Input
                                id="resume"
                                type="text"
                                name="resume"
                                className="resume"
                                value={resume}
                                onChange={event => setResume(event.target.value)}
                                required={anonymous === false && true}
                            />
                        </div>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="request"> Mensagem <span id="info" className="required__field"> * </span> </Label>
                                <Textarea
                                    id="request"
                                    name="request"
                                    className="request"
                                    required
                                    aria-describedby="info"
                                    value={request}
                                    onChange={event => setRequest(event.target.value)}
                                ></Textarea>
                            </FormItem>
                        </FormGroup>
                    </Fieldset>

                    <FormGroup>
                        <FormItem>
                            <Button type="submit" name="enviar__ouvidoria"> Enviar </Button>
                        </FormItem>
                    </FormGroup>
                </Form>
            </div>
        </LayoutBase>
    );
}
