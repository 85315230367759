const dark = {
    title: 'darkTheme',

    themeColors: {
        textPrimary: '#D8DBE3',
        textSecondary: '#717274',

        backgroundPrimary: '#151B26',
        backgroundSecondary: '#0F141F',
        backgroundTertiary: '#06090F',

        colorGlass: 'rgba(6, 9, 15, 0.4)',
    },
};

export default dark;