// HOOKS....
// import useApi from '../../../../hooks/useApi';

// COMPONENTS...
import { LayoutAuth } from '../../../../components/Layout/LayoutAuth';
import { Fieldset, Input } from '../../../../components/Form/Form';
import { Button } from '../../../../components/Button/Button';
import { Alert } from '../../../../components/Alert/Alert';

// STYLES...
import { FormItem, Label } from '../../../../components/Form/FormStyled';
import { useState } from 'react';

export function ResetClient() {
    // const api = useApi();
    // const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    // STATES...
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    // const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [disabled] = useState(false);

    // const handleClear = () => {
    //     setEmail('');
    //     setPassword('');
    //     setConfirmPassword('');
    // }

    return (
        <LayoutAuth
            title="Altere sua senha"
            returnText="home"
            returnTo="/"
            notHave="Lembrou a senha?"
            notHaveText="Logar"
            notHaveTo="/auth/client/login"
        >
            {error &&
                <Alert
                    isError
                    closeAfter={7000}
                    title="Erro"
                    icon="code__errorCircle"
                    message={error}
                    state={error}
                    setState={setError}
                />
            }

            <form className="formAuth" id="formLogin">
                <Fieldset
                    toTheForm="formAuthReset"
                    name="Reset"
                    title="Reset"
                >
                    <FormItem>
                        <Label htmlFor="name"> Nome </Label>
                        <Input
                            id="name" type="text" placeholder="Informe o nome"
                            value={name} onChange={event => setName(event.target.value)} disabled={disabled}
                        />
                    </FormItem>

                    <FormItem>
                        <Label htmlFor="password"> Password </Label>
                        <Input
                            id="password" type="password" placeholder="Informe a senha"
                            value={password} onChange={event => setPassword(event.target.value)} disabled={disabled}
                        />
                    </FormItem>

                    <FormItem>
                        <Label htmlFor="password-confirm"> Confirme password </Label>
                        <Input
                            id="password-confirm" type="password" placeholder="Repita a senha"
                            value={confirmPassword} onChange={event => setConfirmPassword(event.target.value)} disabled={disabled}
                        />
                    </FormItem>
                </Fieldset>

                <FormItem>
                    <Button isFull type="submit" disabled={disabled}> Salvar </Button>
                </FormItem>
            </form>
        </LayoutAuth>
    );
}