import styled from "styled-components";

export const PaginateContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 0 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    small {
        display: block;
    }

    ul {
        list-style-type: none;
        padding: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
`;

export const PaginateItemContainer = styled.li`
    width: 2rem;
    height: 2rem;
    margin: 0 0.2rem;
    border-radius: 0.44rem;
    background-color: ${props => props.theme.themeColors.backgroundTertiary};

    display: flex;
    align-items: center;
    justify-content: center;

    &.active__ {
        background-color: ${props => props.theme.colors.colorSecondary};

        color: ${props => props.theme.themeColors.textPrimary};
    }
`;

export const PaginateItemButtonContainer = styled.button`
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    margin: 0 0.2rem;
    border: none;
    border-radius: 0.44rem;
    background-color: ${props => props.theme.themeColors.backgroundTertiary};

    display: flex;
    align-items: center;
    justify-content: center;

    .icon__ {
        width: 1.5rem;
        height: 1.5rem;
        background-color: ${props => props.theme.themeColors.textPrimary};
    }

    &:hover {
        background-color: ${props => props.theme.colors.colorPrimary};
    }
`;