// STYLES...
import { FieldsetContainer, InputContainer } from './FormStyled';

// TYPES...
import { FieldsetType, InputType } from './FormType';

export function Fieldset({ isDisabled = false, toTheForm, name, title, children, otherClass }: FieldsetType) {
    return (
        <FieldsetContainer form={toTheForm} name={name} className={`${otherClass}`}>
            <legend> {title} </legend>
            {children}
        </FieldsetContainer>
    );
}

export function Input({ isRequired = false, isDisabled = false, otherClass, ...props }: InputType) {
    return (
        <InputContainer
            className={`input__ ${otherClass}`}
            {...props}
        />
    );
}