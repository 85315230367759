import Switch from 'react-switch';
import { BrowserRouter } from "react-router-dom";
import { DefaultTheme, ThemeProvider } from "styled-components";

// ROUTERS...
import { Routers } from "./routers/Routes";

// CONTEXTS...
import { AuthContextProvider } from "./helpers/contexts/AuthContext";

// HOOKS...
import { usePersistedState } from "./helpers/hooks/usePersistedState";

// STYLES...
import './styles/icons.scss';
import GlobalStyle from './styles/global';
import { combineTheme } from "./styles/themes/default";
import darkTheme from "./styles/themes/dark";
import lightTheme from "./styles/themes/light";

function App() {
	const [theme, setTheme] = usePersistedState<DefaultTheme>('theme', combineTheme(darkTheme));

	const toggleTheme = () => {
		setTheme(theme.title === 'darkTheme' ? combineTheme(lightTheme) : combineTheme(darkTheme));
	};

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<AuthContextProvider>
					<GlobalStyle />

					<Routers />

					<Switch
						className="switch__absolute"
						onChange={toggleTheme}
						checked={false}
						checkedIcon={false}
						uncheckedIcon={false}
						height={30}
						width={70}
						handleDiameter={40}
					/>
				</AuthContextProvider>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
