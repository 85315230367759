import styled from "styled-components";

export const ButtonContainer = styled.button`
    cursor: pointer;

    height: 3rem;
    padding: 0 1.5rem;
    border: none;
    border-radius: 0.75rem;
    background: ${props => props.theme.colors.colorGradient};
    transition: background-color .2s;

    font-weight: 500;
    font-size: 1rem;
    color: ${props => props.theme.themeColors.textPrimary};

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        margin-right: 0.5rem;
    }

    .icon__ {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.8rem;
        background-color: ${props => props.theme.themeColors.textPrimary};
    }

    &:not(:disabled):hover {
        background-color: ${props => props.theme.colors.colorSecondary};
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    &.full__ {
        width: 100%;
    }
    
    &.outlined__ {
        border: 0.150rem solid ${props => props.theme.colors.colorGradient};
        background: transparent;

        color: ${props => props.theme.colors.colorPrimary};

        &:not(:disabled):hover {
            background: ${props => props.theme.colors.colorGradient};
            
            color: ${props => props.theme.themeColors.textPrimary};
        }
    }

    &.danger__ {
        background: ${props => props.theme.colors.red};

        &:not(:disabled):hover {
            filter: brightness(0.9);
        }
    }

    &.clear__ {
        background: ${props => props.theme.themeColors.backgroundSecondary};
        color: ${props => props.theme.themeColors.textPrimary};

        &:not(:disabled):hover {
            filter: brightness(0.9);
        }
    }

    &.icon__bt {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        border-radius: 0.5rem;

        .icon__ {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0;
        }
    }
`;