import styled from "styled-components";
import { Link } from "react-router-dom";

export const LinkContainer = styled(Link)`
    width: fit-content;
    height: auto;
    padding: 0.3rem 0.7rem;
    border-radius: 0.50rem;
    transition: 1s filter ease;
    
    font-style: normal;
    font-size: 0.9rem;
    color: ${props => props.theme.themeColors.textSecondary};
    text-decoration: none;
    outline: none;
    
    .icon__ {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.8rem;
        background-color: ${props => props.theme.themeColors.textPrimary};
    }

    &:visited, // Quando o link for visitado.
    &:hover { // Quando o link recebe o mouse.
        color: ${props => props.theme.colors.colorSecondary};
    }

    &:active { // Quando o link é clicado.
        color: ${props => props.theme.colors.colorPrimary};
    }
`;

export const LinkButtonContainer = styled(Link)`
    cursor: pointer;

    height: 3rem;
    padding: 0 1.5rem;
    border: none;
    border-radius: 0.75rem;
    border: 0.150rem solid ${props => props.theme.colors.colorSecondary};
    background-color: transparent;
    transition: background-color .2s;

    font-weight: 500;
    font-size: 1rem;
    color: ${props => props.theme.colors.colorPrimary};

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        margin-right: 0.5rem;
    }

    .icon__ {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.8rem;
        background-color: ${props => props.theme.colors.colorSecondary};
    }

    &:not(:disabled):hover {
        background: ${props => props.theme.colors.colorGradient};
        
        color: ${props => props.theme.themeColors.textPrimary};
    }
`;