import { Route, Routes, Navigate } from "react-router-dom";

// HOOKS...
import { useAuth } from "../helpers/hooks/useAuth";

// COMPONENTS...
import { DashboardPanel } from "../pages/Panel/Client/Dashboard/Dashboard";

import { CategoryRead } from "../pages/Panel/Client/Category/Show";
import { CategoryCreate } from "../pages/Panel/Client/Category/Create";
import { CategoryUpdate } from "../pages/Panel/Client/Category/Update";

import { SectorRead } from "../pages/Panel/Client/Sector/Show";
import { SectorCreate } from "../pages/Panel/Client/Sector/Create";
import { SectorUpdate } from "../pages/Panel/Client/Sector/Update";

import { OmbudsmanRead } from "../pages/Panel/Client/Ombudsman/Index";
import { OmbudsmanShow } from "../pages/Panel/Client/Ombudsman/Show";
import { OmbudsmanReply } from "../pages/Panel/Client/Ombudsman/Reply";

import { EsicRead } from "../pages/Panel/Client/Esic/Index";
import { EsicShow } from "../pages/Panel/Client/Esic/Show";
import { EsicReply } from "../pages/Panel/Client/Esic/Reply";

import { PruningTreeRead } from "../pages/Panel/Client/PruningTree/Index";
import { PruningTreeShow } from "../pages/Panel/Client/PruningTree/Show";
import { PruningTreeReply } from "../pages/Panel/Client/PruningTree/Reply";

import { StreetLightingRead } from "../pages/Panel/Client/StreetLighting/Index";
import { StreetLightingShow } from "../pages/Panel/Client/StreetLighting/Show";
import { StreetLightingReply } from "../pages/Panel/Client/StreetLighting/Reply";

import { UserProfile } from "../pages/Panel/Client/User/Profile";

// TYPES...
import { PrivateRouteType } from "../helpers/types/Types";
import { NotFound } from "../pages/Site/NotFound/NotFound";

function PrivateGuard({ children }: PrivateRouteType) {
    const isLogged = useAuth().isLogged();

    if (!isLogged) {
        return <Navigate to="/auth/client/login" />;
    }

    return <> {children} </>;
}

const PanelClient = () => {
    return (
        <Routes>
            <Route path="/dashboard" element={<PrivateGuard> <DashboardPanel /> </PrivateGuard>} />

            <Route path="/categories" element={<PrivateGuard> <CategoryRead /> </PrivateGuard>} />
            <Route path="/categories/create" element={<PrivateGuard> <CategoryCreate /> </PrivateGuard>} />
            <Route path="/categories/edit/:id" element={<PrivateGuard> <CategoryUpdate /> </PrivateGuard>} />

            <Route path="/sectors" element={<PrivateGuard> <SectorRead /> </PrivateGuard>} />
            <Route path="/sectors/create" element={<PrivateGuard> <SectorCreate /> </PrivateGuard>} />
            <Route path="/sectors/edit/:id" element={<PrivateGuard> <SectorUpdate /> </PrivateGuard>} />

            <Route path="/ombudsmans" element={<PrivateGuard> <OmbudsmanRead /> </PrivateGuard>} />
            <Route path="/ombudsmans/show/:uuid" element={<PrivateGuard> <OmbudsmanShow /> </PrivateGuard>} />
            <Route path="/ombudsmans/reply/:uuid" element={<PrivateGuard> <OmbudsmanReply /> </PrivateGuard>} />

            <Route path="/e-sics" element={<PrivateGuard> <EsicRead /> </PrivateGuard>} />
            <Route path="/e-sics/show/:uuid" element={<PrivateGuard> <EsicShow /> </PrivateGuard>} />
            <Route path="/e-sics/reply/:uuid" element={<PrivateGuard> <EsicReply /> </PrivateGuard>} />

            <Route path="/pruning-trees" element={<PrivateGuard> <PruningTreeRead /> </PrivateGuard>} />
            <Route path="/pruning-trees/show/:uuid" element={<PrivateGuard> <PruningTreeShow /> </PrivateGuard>} />
            <Route path="/pruning-trees/reply/:uuid" element={<PrivateGuard> <PruningTreeReply /> </PrivateGuard>} />

            <Route path="/street-lightings" element={<PrivateGuard> <StreetLightingRead /> </PrivateGuard>} />
            <Route path="/street-lightings/show/:uuid" element={<PrivateGuard> <StreetLightingShow /> </PrivateGuard>} />
            <Route path="/street-lightings/reply/:uuid" element={<PrivateGuard> <StreetLightingReply /> </PrivateGuard>} />

            <Route path="/user/profile/:id" element={<PrivateGuard> <UserProfile /> </PrivateGuard>} />

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default PanelClient;
