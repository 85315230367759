// import Cookies from 'js-cookie';
import qs from 'qs';

// ENDPOINTS...
const BASEAPI = process.env.NODE_ENV !== 'production' ? 'http://api.servicoaocidadao.test/' : 'https://api.servicosaocidadao.net.br/';
const BASEAPICEP = 'https://viacep.com.br/ws/';

// FUNCTIONS...

function setHeaders(host, token) {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'hostClient': host,
        'Authorization': token ? `bearer ${token}` : '',
        // 'replyAuthor': author ? author : '' // Author da resposta...
    }
    return headers;
}

function verifyToken(tok) {
    if (!tok) {
        // let token = Cookies.get('token');
        let token = localStorage.getItem('token');

        if (token) {
            tok = token;
        }
    }
}

// REQUISITIONS...

const apiFetchGet = async (endpoint, host = '', body = []) => {
    verifyToken(body.token);

    const response = await fetch(`${BASEAPI + endpoint}?${qs.stringify(body)}`, {
        method: 'GET',
        headers: setHeaders(host, body.token),

        // O then não rejeita o status 404 e 500 como erro, apenas retorna a promise com status de false...
    }).then(function (response) {
        if (response.ok) {
            return response;

        } else {
            console.error(`ERROR => ${response.status} - ${response.statusText}`);
            return response;
        }

    }).catch(function (error) {
        console.error(`ERROR CATCH -> ${error.response.data}`);
    });

    const data = await response.json();
    return data;
}

const apiFetchPost = async (endpoint, host = '', body) => {
    verifyToken(body.token);

    const response = await fetch(BASEAPI + endpoint, {
        method: 'POST',
        mode: 'cors',
        headers: setHeaders(host, body.token),
        body: JSON.stringify(body)

        // O then não rejeita o status 404 e 500 como erro, apenas retorna a promise com status de false...
    }).then(function (response) {
        if (response.ok) {
            return response;

        } else {
            console.error(`ERROR THEN -> ${response.status} - ${response.statusText}`);
            return response;
        }

    }).catch(function (error) {
        console.error(`ERROR CATCH -> ${error.response.data}`);
    });

    const data = await response.json();
    return data;
}

const apiFetchPut = async (endpoint, host = '', body) => {
    verifyToken(body.token);

    const response = await fetch(BASEAPI + endpoint, {
        method: 'PUT',
        mode: 'cors',
        headers: setHeaders(host, body.token),
        body: JSON.stringify(body)

        // O then não rejeita o status 404 e 500 como erro, apenas retorna a promise com status de false...
    }).then(function (response) {
        if (response.ok) {
            return response;

        } else {
            console.error(`ERROR THEN -> ${response.status} - ${response.statusText}`);
            return response;
        }

    }).catch(function (error) {
        console.error(`ERROR CATCH -> ${error.response.data}`);
    });

    const data = await response.json();
    return data;
}

const apiFetchDelete = async (endpoint, host = '', body) => {
    verifyToken(body.token);

    const response = await fetch(BASEAPI + endpoint, {
        method: 'DELETE',
        mode: 'cors',
        headers: setHeaders(host, body.token),
        body: JSON.stringify(body)

        // O then não rejeita o status 404 e 500 como erro, apenas retorna a promise com status de false...
    }).then(function (response) {
        if (response.ok) {
            return response;

        } else {
            console.error(`ERROR THEN -> ${response.status} - ${response.statusText}`);
            return response;
        }

    }).catch(function (error) {
        console.error(`ERROR CATCH -> ${error.response.data}`);
    });

    const data = await response.json();
    return data;
}

// API OBJECT...
const useApi = {
    // TENANTS...

    getTenants: async (host) => {
        const data = await apiFetchGet(
            'clients', host
        );
        return data;
    },

    // CLIENT...

    getClientInformation: async (host) => {
        const data = await apiFetchGet(
            'client-information', host
        );
        return data;
    },

    // SECTOR...

    getSectors: async (host) => { // Consulta todos os sectors...
        const data = await apiFetchGet(
            'sectors/get', host,
        );
        return data;
    },

    getSector: async (host, token, id) => { // Consulta um sector (id)...
        const data = await apiFetchGet(
            `sectors/${id}`, host,
            { token }
        );
        return data;
    },

    setSector: async (host, token, name) => { // Cadastra um sector...
        const data = await apiFetchPost(
            'sectors', host,
            { token, name }
        );
        return data;
    },

    setSectorUpdate: async (host, token, id, name) => { // Atualiza uma sector (id)...
        const data = await apiFetchPut(
            `sectors/${id}`, host,
            { token, name }
        );
        return data;
    },

    setSectorDelete: async (host, token, id) => {
        const data = await apiFetchDelete(
            `sectors/${id}`, host,
            { token }
        );
        return data;
    },

    // CATEGORY...

    getCategories: async (host) => { // Consulta todas as categories...
        const data = await apiFetchGet(
            'categories/get', host,
        );
        return data;
    },

    getCategory: async (host, token, cat_id) => { // Consulta uma category (id)...
        const data = await apiFetchGet(
            `categories/${cat_id}`, host,
            { token }
        );
        return data;
    },

    setCategory: async (host, token, name) => { // Cadastra uma category...
        const data = await apiFetchPost(
            'categories', host,
            { token, name }
        );
        return data;
    },

    setCategoryUpdate: async (host, token, id, name) => { // Atualiza uma category (id)...
        const data = await apiFetchPut(
            `categories/${id}`, host,
            { token, name }
        );
        return data;
    },

    setCategoryDelete: async (host, token, id) => {
        const data = await apiFetchDelete(
            `categories/${id}`, host,
            { token }
        );
        return data;
    },

    // OMBUDSMAN...

    getOmbudsmans: async (host, token) => { // Consulta todas as solicitações de ouvidorias...
        const data = await apiFetchGet(
            'ombudsmans/get', host,
            { token }
        );
        return data;
    },

    getOmbudsman: async (host, token, uuid) => { // Consulta a ouvuuidoria (uuid)...
        const data = await apiFetchGet(
            `ombudsmans/${uuid}`, host,
            { token }
        );
        return data;
    },

    setOmbudsman: async (
        host, anonymous, sector, category, name, sex, email, telephone, cellphone, schooling,
        occupation, zipCode, road, number, district, city, state, complement, resume, request
    ) => { // Cadastra a solicitação de ouvidoria...
        const data = await apiFetchPost(
            'ombudsmans', host,
            {
                anonymous, sector_id: sector, category_id: category, name, sex, email, telephone, cellphone, schooling, occupation,
                zip_code: zipCode, road, number, district, city, state, complement, resume, request
            }
        );
        return data;
    },

    setOmbudsmanReply: async (host, token, author, uuid, reply, status) => { // Cadastra a resposta à solicitação de ouvidoria...
        const data = await apiFetchPost(
            `agency/ombudsman/reply`, host,
            { token, author, uuid, reply, status }
        );
        return data;
    },

    setOmbudsmanStatus: async (host, token, uuid, status) => {
        const data = await apiFetchPut(
            `ombudsmans/${uuid}/update-status`, host,
            { token, status }
        );
        return data;
    },

    // ESIC...

    getEsics: async (host, token) => { // Consulta todos as solicitações de e-sics...
        const data = await apiFetchGet(
            'e-sics/get', host,
            { token }
        );
        return data;
    },

    getEsic: async (host, token, uuid) => { // Consulta o e-sic (uuid)...
        const data = await apiFetchGet(
            `e-sics/${uuid}`, host,
            { token }
        );
        return data;
    },

    setEsic: async (
        host, name, sex, email, telephone, cellphone, schooling, occupation, zipCode,
        state, city, district, road, number, complement, answerMode, resume, request
    ) => { // Cadastra a solicitação de e-sic...
        const data = await apiFetchPost(
            'e-sics', host,
            {
                name, sex, email, telephone, cellphone, schooling, occupation, zip_code: zipCode,
                state, city, district, road, number, complement, answer_mode: answerMode, resume, request
            }
        );
        return data;
    },

    setEsicReply: async (host, token, author, uuid, reply, status) => { // Cadastra a resposta à solicitação de ouvidoria...
        const data = await apiFetchPost(
            `agency/e-sic/reply`, host,
            { token, author, uuid, reply, status }
        );
        return data;
    },

    setEsicStatus: async (host, token, uuid, status) => {
        const data = await apiFetchPut(
            `e-sics/${uuid}/update-status`, host,
            { token, status }
        );
        return data;
    },

    // PRUNING-TREE...

    getPruningTrees: async (host, token) => { // Consulta todas as solicitações de poda de árvore...
        const data = await apiFetchGet(
            'pruning-trees/get', host,
            { token }
        );
        return data;
    },

    getPruningTree: async (host, token, uuid) => { // Consulta a poda de árvore (uuid)...
        const data = await apiFetchGet(
            `pruning-trees/${uuid}`, host,
            { token }
        );
        return data;
    },

    setPruningTree: async (
        host, name, email, telephone, cellphone, zipCode, road, number,
        district, city, state, complement, reference, reason, request
    ) => { // Cadastra a solicitação de poda de árvore...
        const data = await apiFetchPost(
            'pruning-trees', host,
            {
                name, email, telephone, cellphone, zip_code: zipCode, road, number,
                district, city, state, complement, reference, reason, request
            }
        );
        return data;
    },

    setPruningTreeReply: async (host, token, author, uuid, reply, status) => { // Cadastra a resposta à solicitação de ouvidoria...
        const data = await apiFetchPost(
            `agency/pruning-tree/reply`, host,
            { token, author, uuid, reply, status }
        );
        return data;
    },

    setPruningTreeStatus: async (host, token, uuid, status) => {
        const data = await apiFetchPut(
            `pruning-trees/${uuid}/update-status`, host,
            { token, status }
        );
        return data;
    },

    // STREET LIGHTING...

    getStreetLightings: async (host, token) => { // Consulta todas as solicitações de iluminação pública...
        const data = await apiFetchGet(
            'street-lightings/get', host,
            { token }
        );
        return data;
    },

    getStreetLighting: async (host, token, uuid) => { // Consulta a iluminação pública (uuid)...
        const data = await apiFetchGet(
            `street-lightings/${uuid}`, host,
            { token }
        );
        return data;
    },

    setStreetLighting: async (
        host, name, email, telephone, cellphone, zipCode, road, number,
        district, city, state, complement, reference, reason, request
    ) => { // Cadastra uma solicitação de iluminação pública...
        const data = await apiFetchPost(
            'street-lightings', host,
            {
                name, email, telephone, cellphone, zip_code: zipCode, road, number,
                district, city, state, complement, reference, reason, request
            }
        );
        return data;
    },

    setStreetLightingReply: async (host, token, author, uuid, reply, status) => { // Cadastra a resposta à solicitação de ouvidoria...
        const data = await apiFetchPost(
            `agency/street-lighting/reply`, host,
            { token, author, uuid, reply, status }
        );
        return data;
    },

    setStreetLightingStatus: async (host, token, uuid, status) => {
        const data = await apiFetchPut(
            `street-lightings/${uuid}/update-status`, host,
            { token, status }
        );
        return data;
    },

    // CIDADÃO...

    setSearchCode: async (host, searchCode) => { // Consulta o código da solicitação e retorna a solicitação...
        const data = await apiFetchPost(
            'search-code-query', host,
            { searchCode }
        );
        return data;
    },

    getRepliesSolicitation: async (host, code) => { // Consulta todas as respostas da solicitação...
        const data = await apiFetchGet(
            `replies/${code}`, host,
        );
        return data;
    },

    setReplyCidadao: async (host, code, reply) => { // Cadastra a resposta à solicitação do lado cidadão...
        const data = await apiFetchPost(
            'citizen/reply', host,
            { code, reply }
        );
        return data;
    },

    // AUTH CLIENT...

    doSignInClient: async (host, email, password) => { // Login do cliente...
        const data = await apiFetchPost(
            'auth/client/signin', host,
            { email, password }
        );
        return data;
    },

    forgotItClient: async (host, email) => { // Esqueceu a senha do cliente...
        const data = await apiFetchPost(
            'auth/client/forgot-it', host,
            { email }
        );
        return data;
    },

    ResestPasswordClient: async (host, name, password) => { // Resetar a senha do cliente...
        const data = await apiFetchPost(
            'auth/client/reset-password', host,
            { name, password }
        );
        return data;
    },

    doLogout: async (host, token) => { // Sair do sistema...
        const data = await apiFetchPost(
            'auth/client/logout', host,
            { token }
        );
        return data;
    },

    // USER...

    isMe: async (host, token) => { // Verifica se está logado...
        const data = await apiFetchPost(
            'auth/client/me', host,
            { token }
        );
        return data;
    },

    setUserUpdate: async (host, token, id, name, email, password) => { // Atualiza os dados do usuário pelo painel...
        const data = await apiFetchPut(
            `user/${id}`, host,
            { token, name, email, password }
        );
        return data;
    },

    // CEP... OTHER API

    getCep: async (cep) => { // Consulta o cep e retorna o endereço...
        const response = await fetch(`${BASEAPICEP + cep}/json`, {
            method: 'GET',
            mode: 'cors',
            cache: 'default',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

        }).then((response) => {
            return response;

        }).catch((error) => {
            console.error(`ERROR => ${error.response.data}`);
        });

        const data = await response.json();
        return data;
    },

    getApiBaseUrl: () => {
        return BASEAPI
    }
};

export default () => useApi;
