// COMPONENTS...
import { LayoutAuth } from '../../../../components/Layout/LayoutAuth';
import { Fieldset, Input } from '../../../../components/Form/Form';
import { Button } from '../../../../components/Button/Button';

// STYLES...
import { FormItem, Label } from '../../../../components/Form/FormStyled';

export function RegisterAdmin() {
    return (
        <LayoutAuth
            title="Informe as credênciais"
            subtitle="e registre-se"
            returnText="home"
            returnTo="/"
            notHave="Possui login?"
            notHaveText="Logar"
            notHaveTo="/auth/admin/login"
        >
            <form className="formAuth" id="formLogin">
                <Fieldset
                    toTheForm="formAuthRegister"
                    name="Register"
                    title="Register"
                >
                    <FormItem>
                        <Label htmlFor="name"> Nome </Label>
                        <Input
                            id="name"
                            type="text"
                            placeholder="Informe o nome"
                        />
                    </FormItem>

                    <FormItem>
                        <Label htmlFor="email"> E-mail </Label>
                        <Input
                            id="email"
                            type="email"
                            placeholder="Informe o email"
                        />
                    </FormItem>

                    <FormItem>
                        <Label htmlFor="password"> Password </Label>
                        <Input
                            id="password"
                            type="password"
                            placeholder="Informe o password"
                        />
                    </FormItem>

                    <FormItem>
                        <Label htmlFor="password-confirm"> Confirme password </Label>
                        <Input
                            id="password-confirm"
                            type="password"
                            placeholder="Confirme o password"
                        />
                    </FormItem>
                </Fieldset>

                <Button isFull type="submit"> Entrar </Button>
            </form>
        </LayoutAuth>
    );
}