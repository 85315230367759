// STYLES...
import { ListNavContainer } from './ListStyled';

// TYPES...
import { ListType, LiType } from './ListType';

export function ListNav({ isVertical = false, isStart = false, children}: ListType) {
    return (
        <ListNavContainer className={`${isVertical ? 'vertical__nav' : ''} ${isStart ? 'start__justify' : ''}`}>
            <ul> {children} </ul>
        </ListNavContainer>
    );
}

export function Li({ isDrop = false, dataRef, children, openDrop, classe }: LiType) {
    return (
        <li
            data-ref={dataRef}
            className={`${classe} ${isDrop ? 'dropdown__item' : ''}`}
        > {children} </li>
    );
}

