import { FormEvent, useState } from "react";
import { Link } from "react-router-dom";

// HOOKS....
import useApi from "../../../helpers/hooks/useApi";

// COMPONENTS...
import { LayoutBase } from "../../../components/Layout/LayoutBase";
import { ListNav } from "../../../components/List/List";
import { Header } from "../../../components/Header/Header";
import { Fieldset, Input } from "../../../components/Form/Form";
import { Button } from "../../../components/Button/Button";
import { Alert } from "../../../components/Alert/Alert";

// STYLES...
import { Form, FormGroup, FormItem, Label, Select, Textarea } from '../../../components/Form/FormStyled';

export function StreetLighting() {
    const api = useApi();
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    // STATES...
    const [error, setError] = useState('');
    const [codeSolicitation, setCodeSolicitation] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [cellphone, setcellphone] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [road, setRoad] = useState('');
    const [number, setNumber] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [complement, setComplement] = useState('');
    const [reference, setReference] = useState('');
    const [reason, setReason] = useState('');
    const [request, setRequest] = useState('');

    const handleClear = () => {
        setName('');
        setEmail('');
        setTelephone('');
        setcellphone('');
        setZipCode('');
        setRoad('');
        setNumber('');
        setDistrict('');
        setCity('');
        setState('');
        setComplement('');
        setReference('');
        setReason('');
        setRequest('');
    }

    const handleQueryCep = async (event: any) => {
        const cep = event.target.value?.replace(/[^0-9]/g, '');
        setZipCode(cep);

        if (cep?.length === 8) {
            const data = await api.getCep(cep);

            setZipCode(data.cep.replace(/[^0-9]/g, ''));
            setState(data.uf);
            setCity(data.localidade);
            setDistrict(data.bairro);
            setRoad(data.logradouro);
            setComplement(data.complemento);
        }
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = await api.setStreetLighting(host, name, email, telephone, cellphone, zipCode, road, number, district, city, state, complement, reference, reason, request);

        if (data.code) {
            setCodeSolicitation(data.code);
            handleClear();
        } else {
            setError(data.error);
        }
    }

    const handleReturnHome = () => {
        let uri = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
        var captureUrl = 0;

        if (process.env.NODE_ENV !== 'production') {
            captureUrl = uri.indexOf('localhost:3000');
        } else {
            captureUrl = uri.indexOf('servicosaocidadao.net.br');
        }

        if (captureUrl !== -1) {
            let url = uri.slice(captureUrl);
            window.location.href = `http://${url}`;
        } else {
            console.log('Não existe URL padrão na URI');
        }
    }

    return (
        <LayoutBase name="About Page" id="aboutPage">
            <Header>
                <ListNav>
                    <li>
                        <span className="itemButton__" onClick={handleReturnHome}>
                            <span className="icon__ home"></span>
                            Home
                        </span>
                    </li>

                    <li>
                        <Link to="/services">
                            <span className="icon__ general__settings2"></span>
                            Serviços
                        </Link>
                    </li>
                </ListNav>
            </Header>

            <div className="container__">
                <div>
                    <h1> Solicitar Iluminação Pública<span className="full__stop">.</span> </h1>
                    <p> Manutenção da rede de iluminação pública municipal nas áreas urbanas, incluindo a troca das lâmpadas, manutenção, extensão das redes. </p>
                </div>

                {error &&
                    <Alert
                        isError
                        closeAfter={7000}
                        title="Erro"
                        icon="code__errorCircle"
                        message={error}
                        state={error}
                        setState={setError}
                    />
                }

                {codeSolicitation &&
                    <Alert
                        isCode
                        title="Código da solicitação"
                        icon="shopping__ticket"
                        value={codeSolicitation}
                        state={codeSolicitation}
                        setState={setCodeSolicitation}
                    />
                }

                <Form id="formIluminacaoPublica" onSubmit={handleSubmit}>
                    <Fieldset
                        toTheForm="formIluminacao"
                        name="dadosIluminacao"
                        title="Dados"
                    >
                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="name"> Nome </Label>
                                <Input
                                    id="name"
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="email"> E-mail </Label>
                                <Input
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="telephone"> Telefone </Label>
                                <Input
                                    id="telephone"
                                    type="text"
                                    name="telephone"
                                    minLength={8}
                                    maxLength={10}
                                    value={telephone}
                                    onChange={event => setTelephone(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="cellphone"> Celular </Label>
                                <Input
                                    id="cellphone"
                                    type="text"
                                    name="cellphone"
                                    minLength={11}
                                    maxLength={11}
                                    value={cellphone}
                                    onChange={event => setcellphone(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>
                    </Fieldset>

                    <Fieldset
                        toTheForm="formIluminacao"
                        name="enderecoIluminacao"
                        title="Endereço"
                    >
                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="zip_code"> CEP <small id="info" className="required__field"> * </small> </Label>
                                <Input
                                    id="zip_code"
                                    type="text"
                                    name="zip_code"
                                    className="zip_code"
                                    required
                                    aria-describedby="info"
                                    minLength={8}
                                    maxLength={8}
                                    data-mask="00000-000"
                                    value={zipCode}
                                    onChange={handleQueryCep}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="road"> Rua <small id="info" className="required__field"> * </small> </Label>
                                <Input
                                    id="road"
                                    type="text"
                                    name="road"
                                    className="road"
                                    required
                                    aria-describedby="info"
                                    value={road}
                                    onChange={event => setRoad(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="number"> Número <small id="info" className="required__field"> * </small> </Label>
                                <Input
                                    id="number"
                                    type="text"
                                    name="number"
                                    className="number"
                                    required
                                    aria-describedby="info"
                                    value={number}
                                    onChange={event => setNumber(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="district"> Bairro <small id="info" className="required__field"> * </small> </Label>
                                <Input
                                    id="district"
                                    type="text"
                                    name="district"
                                    className="district"
                                    required
                                    aria-describedby="info"
                                    value={district}
                                    onChange={event => setDistrict(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="city"> Cidade <small id="info" className="required__field"> * </small> </Label>
                                <Input
                                    id="city"
                                    type="text"
                                    name="city"
                                    className="city"
                                    required
                                    aria-describedby="info"
                                    value={city}
                                    onChange={event => setCity(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="state"> Estado <small id="info" className="required__field"> * </small> </Label>
                                <Input
                                    id="state"
                                    type="text"
                                    name="state"
                                    className="state"
                                    minLength={2}
                                    maxLength={2}
                                    value={state}
                                    onChange={event => setState(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="complement"> Complemento </Label>
                                <Input
                                    id="complement"
                                    type="text"
                                    name="complement"
                                    className="complement"
                                    value={complement}
                                    onChange={event => setComplement(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="reference"> Referência </Label>
                                <Input
                                    id="reference"
                                    type="text"
                                    name="reference"
                                    className="reference"
                                    value={reference}
                                    onChange={event => setReference(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>
                    </Fieldset>

                    <Fieldset
                        toTheForm="formIluminacao"
                        name="solicitacaoIluminacao"
                        title="Solicitação"
                    >
                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="reason"> Motivo da Solicitação <small id="info" className="required__field"> * </small> </Label>
                                <Select
                                    id="reason"
                                    name="reason"
                                    required
                                    aria-describedby="info"
                                    value={reason}
                                    onChange={event => setReason(event.target.value)}
                                >
                                    <option value="" disabled> Selecione uma opção </option>
                                    <option value="1"> Lâmpada Apagada (noite) </option>
                                    <option value="2"> Lâmpada Acessa (dia) </option>
                                    <option value="3"> Lâmpada piscando (ascendendo-apagando) </option>
                                    <option value="4"> Poste/Luminária quebrada  </option>
                                    <option value="5"> Furto ou Vandalismo </option>
                                    <option value="5"> Outros (Favor Especificar) </option>
                                </Select>
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="request"> Outras informações <small id="info" className="required__field"> * </small> </Label>
                                <Textarea
                                    id="request"
                                    name="request"
                                    required
                                    aria-describedby="info"
                                    value={request}
                                    onChange={event => setRequest(event.target.value)}
                                ></Textarea>
                            </FormItem>
                        </FormGroup>
                    </Fieldset>

                    <FormGroup>
                        <FormItem>
                            <Button type="submit" name="enviar__iluminacao"> Enviar </Button>
                        </FormItem>
                    </FormGroup>
                </Form>
            </div>
        </LayoutBase>
    );
}