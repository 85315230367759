import { createContext } from "react";

// HOOKS...
import { useAuth } from "../hooks/useAuth";

// TYPES...
import { AuthContextProviderType, AuthType } from "../types/Types";

// CONTEXT...
export const AuthContext = createContext({} as AuthType);

// PROVIDER...
export function AuthContextProvider(props: AuthContextProviderType) {
    const { doLogout, doSignIn, isLogged, getToken } = useAuth();

    return (
        <AuthContext.Provider value={
            { doSignIn, doLogout, isLogged, getToken }
        }>
            {props.children}
        </AuthContext.Provider>
    );
}