import { useEffect, useState } from "react";
import { useParams } from "react-router";

// HOOKS...
import useApi from "../../../../helpers/hooks/useApi";
import { useAuth } from "../../../../helpers/hooks/useAuth";

// COMPONENTS...
import { LayoutDashboardPanel } from "../../../../components/Layout/LayoutDashboard";
import { Fieldset, Input } from "../../../../components/Form/Form";
import { ChatMessage } from "../../../../components/Chat/Chat";

// STYLES...
import { Form, FormGroup, FormItem, Label, Textarea } from "../../../../components/Form/FormStyled";
import { FakeLoader } from "../../../../components/FakeLoader/FakeLoaderStyled";

// TYPES...
import { StreetLightingType } from "../../../../helpers/types/Types";
import { ButtonPrintPdf } from "../../../../components/Button/Button";

export function StreetLightingShow() {
    const api = useApi();
    const auth = useAuth();
    const { uuid } = useParams();

    const token = auth.getToken();
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const [backendToken, setBackendToken] = useState(false);
    const [loading, setLoading] = useState(true);
    const [streetLighting, setStreetLighting] = useState<StreetLightingType>();

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    useEffect(() => {
        const getStreetLighting = async () => {
            const street = await api.getStreetLighting(host, token, uuid);
            setStreetLighting(street);
            setLoading(false);
        }
        getStreetLighting();
    }, []);

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="streetLightingPanel"
                title="Solicitação: Iluminação Pública"
                description="Veja todos os dados da solicitação"
                linkId="newCategory"
                linkRoute="/panel/client/street-lightings"
                linkName="Voltar"
                linkIcon="text__undo"
            >
                <ButtonPrintPdf
                    title="Imprimir" 
                    target="_blank" 
                    icon="device__printer" 
                    link={api.getApiBaseUrl() + "street-lightings/" + uuid + "/pdf?host_client=" + host + "&token=" + token} 
                />
                <Form id="formCategory">
                    <Fieldset
                        toTheForm="formCategory"
                        name="dadosCategory"
                        title="Informações"
                    >
                        <FormGroup>
                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.code && <Label htmlFor="code"> Código da solicitação </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.code && <Input type="text" name="code" value={streetLighting?.code} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.status && <Label htmlFor="status"> Status </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.status && <Input type="text" name="status" value={streetLighting?.status} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.created_at && <Label htmlFor="created_at"> Data da solicitação </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.created_at && <Input type="text" name="created_at" value={streetLighting?.created_at} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.name && <Label htmlFor="name"> Nome </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.name && <Input type="text" name="name" value={streetLighting?.name} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.email && <Label htmlFor="email"> E-mail </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.email && <Input type="text" name="email" value={streetLighting?.email} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.telephone && <Label htmlFor="telephone"> Telefone </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.telephone && <Input type="text" name="telephone" value={streetLighting?.telephone} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.cellphone && <Label htmlFor="cellphone"> Celular </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.cellphone && <Input type="text" name="cellphone" value={streetLighting?.cellphone} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.zip_code && <Label htmlFor="zip_code"> CEP </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.zip_code && <Input type="text" name="zip_code" value={streetLighting?.zip_code} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.state && <Label htmlFor="state"> Estado </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.state && <Input type="text" name="state" value={streetLighting?.state} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.city && <Label htmlFor="city"> Cidade </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.city && <Input type="text" name="city" value={streetLighting?.city} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.district && <Label htmlFor="district"> Bairro </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.district && <Input type="text" name="district" value={streetLighting?.district} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.road && <Label htmlFor="road"> Rua </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.road && <Input type="text" name="road" value={streetLighting?.road} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.number && <Label htmlFor="number"> Número </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.number && <Input type="text" name="number" value={streetLighting?.number} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.complement && <Label htmlFor="complement"> Complemento </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.complement && <Input type="text" name="complement" value={streetLighting?.complement} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.reference && <Label htmlFor="reference"> Referencia </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.reference && <Input type="text" name="reference" value={streetLighting?.reference} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.reason && <Label htmlFor="reason"> Motivo </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.reason && <Input type="text" name="reason" value={streetLighting?.reason} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {streetLighting?.request && <Label htmlFor="request"> Mensagem </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {streetLighting?.request && <Textarea name="request" value={streetLighting?.request} />} </>
                                }
                            </FormItem>
                        </FormGroup>
                    </Fieldset>
                </Form>

                {streetLighting?.replies &&
                    <div>
                        <h5> Histórico do bate papo </h5>

                        <ul>
                            {streetLighting.replies.map((reply, index) => {
                                if (reply.author.original === 1) {
                                    return (
                                        <ChatMessage
                                            key={reply.id}
                                            message={reply.reply}
                                            author={reply.author.formated}
                                        />
                                    );
                                } else {
                                    return (
                                        <ChatMessage
                                            isEntrance
                                            key={reply.id}
                                            message={reply.reply}
                                            author={reply.author.formated}
                                        />
                                    );
                                }
                            })}
                        </ul>
                    </div>
                }
            </LayoutDashboardPanel>
            : <></>
    );
}