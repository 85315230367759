import { useState } from 'react';

// HOOKS...
// import useApi from '../../../../hooks/useApi';

// COMPONENTS...
import { LayoutAuth } from '../../../../components/Layout/LayoutAuth';
import { Fieldset, Input } from '../../../../components/Form/Form';
import { Button } from '../../../../components/Button/Button';
import { Alert } from '../../../../components/Alert/Alert';

// STYLES...
import { FormItem, Label } from '../../../../components/Form/FormStyled';

export function ForgotItClient() {
    // const api = useApi();
    // const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    // STATES...
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [disabled] = useState(false);

    // const handleClear = () => {
    //     setEmail('');
    // }

    return (
        <LayoutAuth
            title="Informe o email para recuperação"
            returnText="home"
            returnTo="/"
            notHave="Lembrou a senha?"
            notHaveText="Logar"
            notHaveTo="/auth/client/login"
        >
            {error &&
                <Alert
                    isError
                    closeAfter={7000}
                    title="Erro"
                    icon="code__errorCircle"
                    message={error}
                    state={error}
                    setState={setError}
                />
            }

            <form className="formAuth" id="formLogin">
                <Fieldset
                    toTheForm="formAuthForgotIt"
                    name="ForgotIt"
                    title="Esqueceu a senha"
                >
                    <FormItem>
                        <Label htmlFor="email"> E-mail </Label>
                        <Input
                            id="email" type="email" placeholder="Informe o email"
                            value={email} onChange={event => setEmail(event.target.value)} disabled={disabled}
                        />
                    </FormItem>
                </Fieldset>

                <FormItem>
                    <Button isFull type="submit"> Entrar </Button>
                </FormItem>
            </form>
        </LayoutAuth>
    );
}