import styled from "styled-components";

export const HeaderContainer = styled.header`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
    height: 5rem;
    border-bottom: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};
    background-color: ${props => props.theme.themeColors.backgroundPrimary};

    display: flex;
    align-items: center;
    justify-content: space-between;

    nav {
        width: 100%;

        ul {
            justify-content: flex-end;
        }
    }
`;