import { useEffect, useState } from "react";
import { useParams } from "react-router";

// HOOKS...
import useApi from "../../../../helpers/hooks/useApi";
import { useAuth } from "../../../../helpers/hooks/useAuth";

// COMPONENTS...
import { LayoutDashboardPanel } from "../../../../components/Layout/LayoutDashboard";
import { Fieldset, Input } from "../../../../components/Form/Form";
import { ChatMessage } from "../../../../components/Chat/Chat";

// STYLES...
import { Form, FormGroup, FormItem, Label, Textarea } from "../../../../components/Form/FormStyled";
import { FakeLoader } from "../../../../components/FakeLoader/FakeLoaderStyled";

// TYPES...
import { PruningTreeType } from "../../../../helpers/types/Types";
import { ButtonPrintPdf } from "../../../../components/Button/Button";

export function PruningTreeShow() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const api = useApi();
    const auth = useAuth();
    const { uuid } = useParams();

    const token = auth.getToken();

    const [backendToken, setBackendToken] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pruningTree, setPruningTree] = useState<PruningTreeType>();

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    useEffect(() => {
        const getPruningTree = async () => {
            const ombu = await api.getPruningTree(host, token, uuid);
            setPruningTree(ombu);
            setLoading(false);
        }
        getPruningTree();
    }, []);

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="pruningTreePanel"
                title="Solicitação: Poda de árvore"
                description="Veja todos os dados da solicitação"
                linkId="showPruningTrees"
                linkRoute="/panel/client/pruning-trees"
                linkName="Voltar"
                linkIcon="text__undo"
            >
                <ButtonPrintPdf
                    title="Imprimir" 
                    target="_blank" 
                    icon="device__printer" 
                    link={api.getApiBaseUrl() + "pruning-trees/" + uuid + "/pdf?host_client=" + host + "&token=" + token} 
                />
                <Form id="formCategory">
                    <Fieldset
                        toTheForm="formCategory"
                        name="dadosCategory"
                        title="Informações"
                    >
                        <FormGroup>
                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.code && <Label htmlFor="code"> Código da solicitação </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="code" value={pruningTree?.code} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.status && <Label htmlFor="status"> Status </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="status" value={pruningTree?.status} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.created_at && <Label htmlFor="created_at"> Data da solicitação </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="created_at" value={pruningTree?.created_at} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.name && <Label htmlFor="name"> Nome </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="name" value={pruningTree?.name} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.email && <Label htmlFor="email"> E-mail </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="email" value={pruningTree?.email} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.telephone && <Label htmlFor="telephone"> Telefone </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="telephone" value={pruningTree?.telephone} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.cellphone && <Label htmlFor="cellphone"> Celular </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="cellphone" value={pruningTree?.cellphone} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.zip_code && <Label htmlFor="zip_code"> CEP </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="zip_code" value={pruningTree?.zip_code} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.state && <Label htmlFor="state"> Estado </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="state" value={pruningTree?.state} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.city && <Label htmlFor="city"> Cidade </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="city" value={pruningTree?.city} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.district && <Label htmlFor="district"> Bairro </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="district" value={pruningTree?.district} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.road && <Label htmlFor="road"> Rua </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="road" value={pruningTree?.road} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.number && <Label htmlFor="number"> Número </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="number" value={pruningTree?.number} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.complement && <Label htmlFor="complement"> Complemento </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="complement" value={pruningTree?.complement} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.reference && <Label htmlFor="reference"> Referencia </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.code && <Input type="text" name="reference" value={pruningTree?.reference} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.reason && <Label htmlFor="reason"> Motivo </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.reason && <Input type="text" name="reason" value={pruningTree?.reason} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {pruningTree?.request && <Label htmlFor="request"> Mensagem </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {pruningTree?.request && <Textarea name="request" value={pruningTree?.request} />} </>
                                }
                            </FormItem>
                        </FormGroup>
                    </Fieldset>
                </Form>

                {pruningTree?.replies &&
                    <div>
                        <h5> Histórico do bate papo </h5>

                        <ul>
                            {pruningTree.replies.map((reply, index) => {
                                if (reply.author.original === 1) {
                                    return (
                                        <ChatMessage
                                            key={reply.id}
                                            message={reply.reply}
                                            author={reply.author.formated}
                                        />
                                    );
                                } else {
                                    return (
                                        <ChatMessage
                                            isEntrance
                                            key={reply.id}
                                            message={reply.reply}
                                            author={reply.author.formated}
                                        />
                                    );
                                }
                            })}
                        </ul>
                    </div>
                }
            </LayoutDashboardPanel>
            : <></>
    );
}