// COMPONENTS...
import { isBuffer } from 'util';
import { Button } from '../Button/Button';
import { Input } from '../Form/Form';

// STYLES...
import { AlertContainer } from './AlertStyled';

// TYPES...
import { AlertType } from './AlertType';

export function Alert({
    title, icon, message, value, state, setState, closeAfter = 0,
    isError = false, isSuccess = false, isWarning = false, isCode = false, isInfo = false
}: AlertType) {
    const handleAlertClose = () => {
        var codigo = state;
        var element = document.querySelector('.alert__');

        if (codigo !== '') {
            element?.classList.add('alert__remove');
            setState('');
        } else {
            element?.classList.remove('alert__remove');
        }
    }

    if (closeAfter) {
        setTimeout(() => {
            var codigo = state;
            var element = document.querySelector('.alert__');

            if (codigo !== '') {
                element?.classList.add('alert__remove');
                setState('');
            } else {
                element?.classList.remove('alert__remove');
            }
        }, closeAfter);
    }

    // const copyToClipboard = () => {
    //     document.querySelector('#codeCopy').select();
    //     document.execCommand('copy');
    // }

    return (
        <AlertContainer className={`alert__ ${isSuccess ? 'alert__success' : ''} ${isError ? 'alert__error' : ''} ${isWarning ? 'alert__warning' : ''} ${isCode ? 'alert__code' : ''} ${isInfo ? 'icon__bt' : ''}`}>
            <Button
                isIcon
                isDanger
                id="close__alert"
                name="close__alert"
                title="Fechar"
                onClick={handleAlertClose}
            >
                <span className="icon__ navigation__close"></span>
            </Button>

            <div className="alert__inner">
                <div className="alert__header">
                    <span className={`icon__ ${icon} icon__alert`}></span>
                    <h6> {title} </h6>
                </div>

                <div className="alert__content">
                    {isCode
                        ? <Input type="text" name="codeCopy" id="codeCopy" value={value} />
                        //  <Button type="button" onClick={copyToClipboard}> Copiar </Button>
                        : <p> {message} </p>
                    }
                </div>
            </div>
        </AlertContainer>
    );
}