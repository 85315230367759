import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// COMPONENTS...
import { Service } from "../pages/Site/Service/Service";
import { Esic } from "../pages/Site/Esic/Esic";
import { Ombudsman } from "../pages/Site/Ombudsman/Ombudsman";
import { PruningTree } from "../pages/Site/PruningTree/PruningTree";
import { StreetLighting } from "../pages/Site/StreetLighting/StreetLighting";
import { SearchCode } from "../pages/Site/SearchCode/SearchCode";
import { Solicitation } from "../pages/Site/Solicitation/Solicitation";

// TYPES...
import { PrivateRouteType } from "../helpers/types/Types";
import { NotFound } from "../pages/Site/NotFound/NotFound";

/*
    ETAPAS DO PROCESSO
    1 - Cria uma state boolean buscando no localStorage o subdominio.
    2 - useEffect, dependencia a state (1).
        2.1 - Seta no localStorage o valor da state (1).
        2.2 - Função que verifica o domínio, recebe um parametro com o valor da url atual.
            2.2.1 - Verifica se o projeto é produção ou local e seta uma variavel para a url padrão.
                2.2.1.1 - Se for produção, seta o valor do dominio real.
                2.2.1.2 - Se for local, seta o valor do localhost.
            2.2.2 - Verifica se a url atual é igual a url padrão.
                2.2.2.1 - Se for igual, limpa o localStorage e seta a state como false.
                2.2.2.2 - Se for diferente, seta a state como true.
            2.2.3 - retorna a state.
        2.3 - Chama a função (2.2)
    3 - Verifica se a state é verdadeira
        3.1 - Se for true, retorna o children.
        3.2 - Se for false, retorna o redirect para outra rota.
*/

function PrivateSubdomain({ children }: PrivateRouteType) {
    const [isSubdomain, setIsSubdomain] = useState(localStorage.getItem('subdomain') ? true : false); // Verifica se tem item no localStorage e seta a state como true ou false...

    useEffect(() => {
        localStorage.setItem('subdomain', isSubdomain.toString()); // Seta o local storage com o valor da state...

        const verifyDomain = (currentUrl = window.location.href) => { // Recebe a url do browser...
            var patternUrl = '';

            if (process.env.NODE_ENV === 'production') { // Url padrão...
                patternUrl = 'https://servicosaocidadao.net.br';
            } else {
                patternUrl = 'http://localhost:3000/';
            }

            if (currentUrl === patternUrl) {
                localStorage.clear(); // Limpa todo o localStorage...
                setIsSubdomain(false);
            } else {
                setIsSubdomain(true);
            }

            return isSubdomain; // Retorna se é true ou false...
        }
        verifyDomain();
    }, [isSubdomain]);

    if (isSubdomain) {
        return <> {children} </>;
    } else {
        return <Navigate to="/services" />;
    }
}

const Subdomain = () => {
    return (
        <Routes>
            <Route path="/services" element={<PrivateSubdomain> <Service /> </PrivateSubdomain>} />
            <Route path="/services/ombudsman" element={<PrivateSubdomain> <Ombudsman /> </PrivateSubdomain>} />
            <Route path="/services/e-sic" element={<PrivateSubdomain> <Esic /> </PrivateSubdomain>} />
            <Route path="/services/pruning-tree" element={<PrivateSubdomain> <PruningTree /> </PrivateSubdomain>} />
            <Route path="/services/street-lighting" element={<PrivateSubdomain> <StreetLighting /> </PrivateSubdomain>} />
            <Route path="/search-code" element={<PrivateSubdomain> <SearchCode /> </PrivateSubdomain>} />
            <Route path="/solicitation" element={<PrivateSubdomain> <Solicitation /> </PrivateSubdomain>} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default Subdomain;