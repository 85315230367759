// COMPONENTS...
import { LayoutAuth } from '../../../../components/Layout/LayoutAuth';
import { Fieldset, Input } from '../../../../components/Form/Form';
import { Button } from '../../../../components/Button/Button';
import { LinkSimple } from '../../../../components/Link/Link';

// STYLES...
import { FormItem, Label } from '../../../../components/Form/FormStyled';

export function LoginAdmin() {
    return (
        <LayoutAuth
            title="Login"
            subtitle="Informe as credênciais"
            returnText="home"
            returnTo="/"
            notHave="Não possui login?"
            notHaveText="Registre-se"
            notHaveTo="/auth/admin/register"
        >
            <form className="formAuth" id="formLogin">
                <Fieldset
                    toTheForm="formAuthLogin"
                    name="Login"
                    title="Login"
                >

                    <FormItem>
                        <Label htmlFor="email"> E-mail </Label>
                        <Input
                            id="email"
                            type="email"
                            placeholder="Informe o email"
                        />
                    </FormItem>

                    <FormItem>
                        <Label htmlFor="password"> Password </Label>
                        <Input
                            id="password"
                            type="password"
                            placeholder="Informe o password"
                        />
                    </FormItem>
                </Fieldset>

                <div>
                    <LinkSimple link="/admin/forgot-it"> Esqueceu a senha? </LinkSimple>
                </div>

                <Button isFull type="submit"> Entrar </Button>
            </form>
        </LayoutAuth>
    );
}