// COMPONENTS...
import { LayoutBase } from "../../../components/Layout/LayoutBase";
import { Header } from "../../../components/Header/Header";
import { ListNav } from "../../../components/List/List";

// STYLES...
import { HomeContent } from "../Home/HomeStyled";

export function NotFound() {
    const handleReturnHome = () => {
        let uri = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
        var captureUrl = 0;

        if (process.env.NODE_ENV !== 'production') {
            captureUrl = uri.indexOf('localhost:3000');
        } else {
            captureUrl = uri.indexOf('servicosaocidadao.net.br');
        }

        if (captureUrl !== -1) {
            let url = uri.slice(captureUrl);
            window.location.href = `http://${url}`;
        } else {
            console.log('Não existe URL padrão na URI');
        }
    }

    return (
        <LayoutBase name="Not found" id="notFoundPage">
            <Header>
                <ListNav>
                    <li>
                        <span className="itemButton__" onClick={handleReturnHome}>
                            <span className="icon__ home"></span>
                            Voltar para home
                        </span>
                    </li>
                </ListNav>
            </Header>

            <HomeContent>
                <div className="home__infos">
                    <h1> Página não encontrada<span className="full__stop">.</span> :( </h1>
                    <h2> Desculpe, não encontramos a página. Por favor tente novamente. </h2>
                </div>
            </HomeContent>
        </LayoutBase>
    );
}