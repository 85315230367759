import { CustomTheme, DefaultTheme } from 'styled-components';

import darkTheme from './dark';
import lightTheme from './light';

const defaultTheme = {
    fonts: {
        font: 'Rubik, sans-serif',
        title: 'Abril Fatface, cursive',
        text: 'Poppins, sans-serif',

        small: '0.8rem',
        pattern: '1rem',
        medium: '1.5rem',
        large: '2rem',
    },

    colors: {
        colorPrimary: '#1B69D2',
        colorSecondary: '#5390E3',
        colorGradient: 'linear-gradient(224.78deg, #5390E3 8.12%, #1357B3 92.21%)',

        blue: '#1774FF',
        green: '#7FBA7A',
        yellow: '#FFCE73',
        red: '#E87176',
    },
};

function combineTheme(theme: CustomTheme): DefaultTheme {
    return { ...defaultTheme, ...theme };
}

export { combineTheme, darkTheme, lightTheme };