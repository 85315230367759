// COMPONENTS...
import { LayoutBase } from '../../../components/Layout/LayoutBase';
import { ListNav } from "../../../components/List/List";
import { Header } from '../../../components/Header/Header';

// STYLES...
import { HomeContent } from '../Home/HomeStyled';

export function About() {
    const handleReturnHome = () => {
        let uri = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
        // // let captureUrl = uri.indexOf('localhost:3000');
        // let captureUrl = uri.indexOf('servicosaocidadao.net.br');
        var captureUrl = 0;

        if (process.env.NODE_ENV !== 'production') {
            captureUrl = uri.indexOf('localhost:3000');
        } else {
            captureUrl = uri.indexOf('servicosaocidadao.net.br');
        }

        if (captureUrl !== -1) {
            let url = uri.slice(captureUrl);
            window.location.href = `http://${url}`;
        } else {
            console.log('Não existe URL padrão na URI');
        }
    }

    return (
        <LayoutBase name="About Page" id="aboutPage">
            <Header>
                <ListNav>
                    <li>
                        <span className="itemButton__" onClick={handleReturnHome}>
                            <span className="icon__ home"></span>
                            Home
                        </span>
                    </li>
                </ListNav>
            </Header>

            <HomeContent>
                <div className="home__infos">
                    <h1> Sobre<span className="full__stop">.</span> </h1>
                    <h2> Serviços ao cidadão </h2>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam libero explicabo qui at, omnis vel odit est corrupti iusto doloremque aperiam nesciunt possimus recusandae quas quis. Officiis rem velit aperiam. </p>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam libero explicabo qui at, omnis vel odit est corrupti iusto doloremque aperiam nesciunt possimus recusandae quas quis. Officiis rem velit aperiam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam libero explicabo qui at, omnis vel odit est corrupti iusto doloremque aperiam nesciunt possimus recusandae quas quis. Officiis rem velit aperiam. </p>
                </div>
            </HomeContent>
        </LayoutBase>
    );
}