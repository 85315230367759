import styled from "styled-components";

export const AsideContainer = styled.aside`
    width: 22rem;
    height: 100vh;
    overflow: hidden;
    border-right: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};
    background-color: ${props => props.theme.themeColors.backgroundPrimary};

    .aside__wrapper {
        position: relative;

        width: 100%;
        height: 100%;
        padding: 0 1rem;

        button.icon__bt {
            z-index: 20;
            position: absolute;
            top: 60px;
            right: 10px;
        }

        .aside__header {
            height: 5rem;
            border-bottom: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};

            display: flex;
            align-items: center;
            justify-content: center;

            img, svg {
                max-width: 8rem;
            }
        }

        .aside__inner {
            width: 100%;
            height: calc(100% - 5rem - 5rem);
            overflow-y: auto;
            padding: 2rem 0;

            .aside__nav {
                .aside__menu {
                    list-style: none;
                    width: 100%;
                    height: auto;
                    margin-top: 1.5rem;

                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;

                    .sidebar__item {
                        position: relative;

                        width: 100%;
                        height: 3.5rem;
                        margin-bottom: 0.5rem;
                        padding-left: 1.5rem;
                        border-radius: 0.75rem;

                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        &.have__children::after {
                            content: '>';

                            position: absolute;
                            top: 50%;
                            right: 7%;
                            transform: translateY(-50%);

                            color: ${props => props.theme.themeColors.textSecondary};
                        }

                        a {
                            position: relative;

                            width: inherit;
                            height: inherit;
                            padding: 1rem 0;

                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                            .text__ {
                                font-size: 1rem;
                                color: ${props => props.theme.themeColors.textPrimary};
                            }

                            .icon__ {
                                width: 1.4rem;
                                height: 1.4rem;
                                margin-right: 0.7rem;
                                background-color: ${props => props.theme.themeColors.textSecondary};
                            }

                            &[aria-label] {
                                position: relative;

                                &::after {
                                    content: attr(aria-label);

                                    z-index: 100;
                                    position: absolute;
                                    top: 100%;
                                    right: 0;

                                    padding: 0.5rem 0.625rem;
                                    border-radius: 0.44rem;
                                    background-color: ${props => props.theme.themeColors.backgroundSecondary};

                                    font-size: .9em;
                                    color: ${props => props.theme.themeColors.textPrimary};
                                    text-decoration: none;

                                    display: none;
                                }
                            }
                        }

                        &.active,
                        &:hover {
                            /* border-left: 0.3rem solid ${props => props.theme.colors.colorPrimary}; */
                            background-color: ${props => props.theme.themeColors.backgroundSecondary};

                            &.have__children::after {
                                color: ${props => props.theme.colors.colorSecondary};
                            }

                            .icon__ {
                                background-color: ${props => props.theme.colors.colorSecondary};
                            }
                        }

                        &.active::before {
                            content: '';

                            position: absolute;
                            top: 50%;
                            left: -0.1rem;
                            transform: translateY(-50%);

                            width: 0.3rem;
                            height: 2rem;
                            border-radius: 0.75rem;
                            background-color: ${props => props.theme.colors.colorSecondary};
                        }
                    }
                }
            }
        }

        .aside__footer {
            width: 100%;
            height: 5rem;
            border-top: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.aside__small {
        width: 6rem;

        .aside__wrapper {
            padding: 0 1rem;

            .aside__header {
                height: 5rem;

                img, svg {
                    width: 4rem;
                }
            }

            .aside__inner {
                input {
                    display: none;
                }

                .aside__nav {
                    .aside__menu {
                        margin-top: 1.5rem;

                        .sidebar__item {
                            height: 3.5rem;
                            padding-left: 0;

                            justify-content: center;

                            &.have__children::after {
                                display: none;
                            }

                            a {
                                padding: 0;

                                justify-content: center;

                                &[aria-label]:hover::after {
                                    display: block;
                                }

                                .text__ {
                                    display: none;
                                }

                                .icon__ {
                                    width: 1.7rem;
                                    height: 1.7rem;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }

            .aside__footer {
                button {

                    .icon__ {
                        margin-right: 0;
                    }

                    .text__ {
                        display: none;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        width: 6rem;

        .aside__wrapper {
            padding: 0 1rem;

            button.icon__bt {
                display: none;
            }

            .aside__header {
                height: 5rem;

                img, svg {
                    width: 4rem;
                }
            }

            .aside__inner {
                input {
                    display: none;
                }

                .aside__nav {
                    .aside__menu {
                        margin-top: 1.5rem;

                        .sidebar__item {
                            height: 3.5rem;
                            padding-left: 0;

                            justify-content: center;

                            &.have__children::after {
                                display: none;
                            }

                            a {
                                padding: 0;

                                justify-content: center;

                                &[aria-label]:hover::after {
                                    display: block;
                                }

                                .text__ {
                                    display: none;
                                }

                                .icon__ {
                                    width: 1.7rem;
                                    height: 1.7rem;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }

            .aside__footer {
                button {

                    .icon__ {
                        margin-right: 0;
                    }

                    .text__ {
                        display: none;
                    }
                }
            }
        }
    }
`;