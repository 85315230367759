import { useState } from 'react';

// COMPONENTS...
import { Button } from '../Button/Button';

// STYLES...
import { ChatContainer, MessageContainer, MessageNewContainer } from './ChatStyled';
import { Form, Textarea } from '../Form/FormStyled';

// TYPES...
import { ChatType, ChatMessageType } from "./ChatType";

export function Chat({ newMessage = false, ...props }: ChatType) {
    const [reply, setReply] = useState('');

    return (
        <ChatContainer>
            <div className="chat__inner">
                {props.children}
            </div>

            <MessageNewContainer>
                <Form
                    id="formRespostaCidadao"
                    onSubmit={props.onReplySubmission}
                    >
                    <div className="form__content">
                        <Textarea
                            className="reply" id="reply" name="reply" required aria-describedby="info"
                            value={props.reply}
                            onChange={props.setReply}
                            disabled={newMessage ? true : false}
                        ></Textarea>

                        <Button 
                            isIcon 
                            name="responder__cidadao"
                            disabled={newMessage ? true : false}
                        >
                            <span className="icon__ communication__send"></span>
                        </Button>
                    </div>
                </Form>
            </MessageNewContainer>
        </ChatContainer>
    );
}

export function ChatMessage({ isEntrance = false, author, message }: ChatMessageType) {
    return (
        <MessageContainer className={`${isEntrance ? 'message__in' : ''}`}>
            <dd> {message} </dd>

            {author &&
                <dt>
                    <span className={`icon__ ${isEntrance ? 'communication__chat5' : 'communication__chat6'}`}></span>
                    <small> {author} </small>
                </dt>
            }
        </MessageContainer>
    );
}


// export function ChatNewMessage(props: ChatType) {
//     const [reply, setReply] = useState('');

//     return (
//         <Form id="formRespostaCidadao" onSubmit={props.onReplySubmission}>
//             <div className="form__content">
//                 <Textarea
//                     className="reply" id="reply" name="reply" required aria-describedby="info"
//                     value={props.reply}
//                     onChange={props.setReply}
//                 ></Textarea>

//                 <Button isIcon name="responder__cidadao">
//                     <span className="icon__ communication__send"></span>
//                 </Button>
//             </div>
//         </Form>
//     );
// }