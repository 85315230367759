import { Route, Routes } from "react-router-dom";

// CONTEXTS...
import { TenantContextProvider } from "../helpers/contexts/TenantContext";

// COMPONENTS...
import { Home } from "../pages/Site/Home/Home";
import { About } from "../pages/Site/About/About";
import { NotFound } from "../pages/Site/NotFound/NotFound";

// ROUTES...
import Subdomain from "./SubdomainRoute";
import AuthAdmin from "./AuthAdminRoute";
import AuthClient from "./AuthClientRoute";
import PanelClient from "./PanelClientRoute";

export function Routers() {
    return (
        <Routes>
            <Route path="/" element={<TenantContextProvider> <Home /> </TenantContextProvider>} />
            <Route path="/about" element={<TenantContextProvider> <About /> </TenantContextProvider>} />

            {/* TODOS PRECISAM DO PROTECTEDSUBDOMAIN */}
            <Route path="/*" element={<Subdomain />} /> {/* Rotas do subdominio do cliente */}
            <Route path="/auth/client/*" element={<AuthClient />} /> {/* Rotas do login do cliente */}
            <Route path="/panel/client/*" element={<PanelClient />} /> {/* Rotas do painel do cliente */}

            <Route path="/auth/admin/*" element={<AuthAdmin />} /> {/* Admin */}

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}