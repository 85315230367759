import styled from "styled-components";

export const ListNavContainer = styled.nav`
    width: fit-content;
    height: 5rem;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.start__justify{
        justify-content: flex-start;
    }
    
    ul {
        list-style: none;
        width: fit-content;
        height: inherit;
        padding: 0 1rem;

        display: flex;
        align-items: center;
        justify-content: center;

        li {
            width: fit-content;
            height: inherit;
            margin: 0 0.5rem;

            display: flex;
            align-items: center;
            justify-content: center;

            a, .itemButton__ {
                cursor: pointer;
                
                width: fit-content;
                height: auto;
                padding: 0.7rem 1rem;
                border-radius: 0.75rem;
                background-color: ${props => props.theme.themeColors.backgroundPrimary};
                
                font-size: 0.8rem;
                color:  ${props => props.theme.themeColors.textPrimary};

                display: flex;
                align-items: center;
                justify-content: center;

                .icon__ {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-right: 0.5rem;
                    background-color: ${props => props.theme.colors.colorPrimary};
                }

                &:hover {
                    background: ${props => props.theme.colors.colorGradient};
                    /* color: ${props => props.theme.themeColors.textPrimary}; */

                    .icon__ {
                        background-color: ${props => props.theme.themeColors.textPrimary};
                    }
                }
            }

            &.li__profile {
                width: 3.5rem;
                height: 3.5rem;
                border: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};
                border-radius: 50%;

                a {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    border-radius: 50%;
                    background-color: transparent;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    &:hover {
                        background-color: transparent;
                    }

                    .icon__ {
                        width: 70%;
                        height: 70%;
                        margin-right: 0;
                    }
                        
                    img {
                        width: 95%;
                        height: 95%;
                        border-radius: 50%;
                    }
                }
            }

            &.just__icon {
                a {
                    background-color: ${props => props.theme.themeColors.backgroundSecondary};
                    
                    .icon__ {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &.vertical__nav {
        width: 100%;
        height: auto;

        ul {
            flex-direction: column;
            align-items: flex-start;
            
            li {
                width: 100%;
                margin: 1rem 0;

                justify-content: flex-start;
            
                a {
                    width: 100%;

                    justify-content: flex-start;
                }
            }
        }
    }
`;

export const LiProfile = styled.li`
    width: 1.5rem;
    height: 1.5rem;
    border: 0.063rem solid ${props => props.theme.colors.colorSecondary};
    border-radius: 50%;

    a {
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 50%;
        background-color: transparent;
        
        display: flex;
        align-items: center;
        justify-content: center;
        
        &:hover {
            background-color: transparent;
        }

        img {
            width: 95%;
            height: 95%;
        }
    }
`;