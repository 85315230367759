import { Link } from "react-router-dom";

// STYLES...
import { CardDlInfoContainer, CardServiceContainer } from "./CardStyled";

// TYPES...
import { CardDlInfoType, CardServiceType } from "./CardType";

export function CardService({ route, title, description }: CardServiceType) {
    return (
        <CardServiceContainer>
            <Link to={route}></Link>

            <span className="icon__ layout__arrange"></span>
            <h4> {title} </h4>
            <p> {description} </p>
        </CardServiceContainer>
    );
}

export function CardDlInfo({ icon, name, info, isStatus }: CardDlInfoType) {
    return (
        <CardDlInfoContainer
            className={(() => {
                switch (isStatus) {
                    case 'Pendente': return 'warning__'
                    case 'Em andamento': return 'primary__'
                    case 'Concluída': return 'success__'
                    case 'Negada': return 'danger__'
                    default: return ''
                }
            })()}
        >
            <dt>
                <div>
                    <span className={`icon__ ${icon}`}></span>
                </div>
            </dt>

            <dd>
                <small> {name} </small>
                <p> {info} </p>
            </dd>
        </CardDlInfoContainer>
    );
}