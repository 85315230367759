import { Route, Routes } from "react-router-dom";

// COMPONENTS...
import { LoginAdmin } from "../pages/Auth/Admin/Login/Login";
import { RegisterAdmin } from "../pages/Auth/Admin/Register/Register";
import { ForgotItAdmin } from "../pages/Auth/Admin/ForgotIt/ForgotIt";
import { ResetAdmin } from "../pages/Auth/Admin/Reset/Reset";
import { NotFound } from "../pages/Site/NotFound/NotFound";

const AuthAdmin = () => {
    return (
        <Routes>
            <Route path="/login" element={<LoginAdmin />} />
            <Route path="/register" element={<RegisterAdmin />} />
            <Route path="/forgot-it" element={<ForgotItAdmin />} />
            <Route path="/reset" element={<ResetAdmin />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AuthAdmin;