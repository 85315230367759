import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// COMPONENTS...
import { LoginClient } from "../pages/Auth/Client/Login/Login";
import { ForgotItClient } from "../pages/Auth/Client/ForgotIt/ForgotIt";
import { ResetClient } from "../pages/Auth/Client/Reset/Reset";
import { NotFound } from "../pages/Site/NotFound/NotFound";

// TYPES...
import { PrivateRouteType } from "../helpers/types/Types";

function PrivateSubdomain({ children }: PrivateRouteType) {
    const [isSubdomain, setIsSubdomain] = useState(localStorage.getItem('subdomain') ? true : false); // Verifica se tem item no localStorage e seta a state como true ou false...

    useEffect(() => {
        localStorage.setItem('subdomain', isSubdomain.toString()); // Seta o local storage com o valor da state...

        const verifyDomain = (currentUrl = window.location.href) => { // Recebe a url do browser...
            var patternUrl = '';

            if (process.env.NODE_ENV !== 'production') { // Url padrão...
                patternUrl = 'http://localhost:3000/';
            } else {
                patternUrl = 'http://servicosaocidadao.net.br';
            }

            if (currentUrl === patternUrl) { // Verifica se a url atual é igual a url padrão...
                localStorage.clear(); // Limpa todo o localStorage...
                setIsSubdomain(false);
            } else {
                setIsSubdomain(true);
            }

            return isSubdomain; // Retorna se é true ou false...
        }
        verifyDomain();
    }, [isSubdomain]);

    if (isSubdomain) {
        return <> {children} </>;
    } else {
        return <Navigate to="/" />;
    }
}

const AuthClient = () => {
    return (
        <Routes>
            <Route path="/login" element={<PrivateSubdomain> <LoginClient /> </PrivateSubdomain>} />
            <Route path="/forgot-it" element={<PrivateSubdomain> <ForgotItClient /> </PrivateSubdomain>} />
            <Route path="/reset" element={<PrivateSubdomain> <ResetClient /> </PrivateSubdomain>} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AuthClient;