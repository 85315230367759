import { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';

// HOOKS...
import useApi from '../../../../helpers/hooks/useApi';
import { useAuth } from '../../../../helpers/hooks/useAuth';

// COMPONENTS...
import { LayoutDashboardPanel } from '../../../../components/Layout/LayoutDashboard';
import { Fieldset, Input } from '../../../../components/Form/Form';
import { Button } from '../../../../components/Button/Button';
import { Alert } from '../../../../components/Alert/Alert';

// STYLES...
import { Form, FormGroup, FormItem, Label } from '../../../../components/Form/FormStyled';

export function UserProfile() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const api = useApi();
    const auth = useAuth();
    const user = auth.getUser();
    const token = auth.getToken();
    const { id } = useParams();

    const [backendToken, setBackendToken] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const [name, setName] = useState(user.userName);
    const [email, setEmail] = useState(user.userEmail);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    const handleClear = () => {
        setName(user.userName);
        setEmail(user.userEmail);
        setPassword('');
        setConfirmPassword('');
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let data = null;

        if (password === confirmPassword) {
            data = await api.setUserUpdate(host, token, id, name, email, password);
        } else {
            setError('Campos de senhas não conferem, são deferentes!');
        }

        if (data.error) {
            setError(data.error);
            handleClear();
        } else {
            // handleClear();
            // setName(data.name);
            // setEmail(data.email);
            // setPassword(data.password);
            // setConfirmPassword(data.password);

            var userStorage = localStorage.getItem('u');
            let userObject = null;

            if (userStorage !== null) { // Verifica se é diferente de nulo...
                userObject = JSON.parse(userStorage);

                userObject.name = data.user.name;
                userObject.email = data.user.email;
                localStorage.setItem('u', JSON.stringify(userObject));
                setSuccess(data.success);
            }
        }
    }

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="dashboardPanel"
                title="Perfil do usuário"
                description="Confira suas informações e altere seus dados"
            >
                {error &&
                    <Alert
                        isError
                        closeAfter={7000}
                        title="Erro"
                        icon="code__errorCircle"
                        message={error}
                        state={error}
                        setState={setError}
                    />
                }

                {success &&
                    <Alert
                        isSuccess
                        closeAfter={7000}
                        title="Sucesso"
                        icon="navigation__check"
                        message={success}
                        state={success}
                        setState={setSuccess}
                    />
                }

                <Form id="formUser" onSubmit={handleSubmit}>
                    <Fieldset
                        toTheForm="formUser"
                        name="dadosUser"
                        title="Dados"
                    >
                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="name"> Nome </Label>
                                <Input
                                    type="text" name="name"
                                    value={name} onChange={event => setName(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="email"> E-mail </Label>
                                <Input
                                    type="email" name="email"
                                    value={email} onChange={event => setEmail(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="password"> Nova senha </Label>
                                <Input
                                    type="password" name="password"
                                    value={password} onChange={event => setPassword(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="confirm_password"> Repita a senha </Label>
                                <Input
                                    type="password" name="confirm_password"
                                    value={confirmPassword} onChange={event => setConfirmPassword(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Button type="submit" name=""> Enviar </Button>
                            </FormItem>
                        </FormGroup>
                    </Fieldset>
                </Form>

            </LayoutDashboardPanel>
            : <></>
    );
}