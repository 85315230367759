import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// HOOKS....
import useApi from '../../../../helpers/hooks/useApi';
import { useAuth } from '../../../../helpers/hooks/useAuth';

// COMPONENTS...
import { LayoutAuth } from '../../../../components/Layout/LayoutAuth';
import { Fieldset, Input } from '../../../../components/Form/Form';
import { Button } from '../../../../components/Button/Button';
import { Alert } from '../../../../components/Alert/Alert';

// STYLES...
import { FormItem, Label } from '../../../../components/Form/FormStyled';

export function LoginClient() {
    const api = useApi();
    const auth = useAuth();
    const navigate = useNavigate();
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    // STATES...
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [disabled, setDisabled] = useState(false);

    const [error, setError] = useState('');

    const handleClear = () => {
        setEmail('');
        setPassword('');
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Remove o padrão...
        setDisabled(true); // Desabilita os inputs...

        const data = await api.doSignInClient(host, email, password);

        if (data.access_token) {
            handleClear();
            auth.doSignIn(data); // Loga e seta o token...
            navigate('/panel/client/dashboard', { replace: true }); // Redireciona...

        } else {
            setError(data.error);
            handleClear();
        }

        setDisabled(false); // Habilita novamente os inputs...
    }

    return (
        <LayoutAuth
            title="Login"
            subtitle="Informe as credenciais"
            returnText="home"
            returnTo="/"
            notHave="Esqueceu a senha?"
            notHaveText="Recuperar"
            notHaveTo="/auth/client/forgot-it"
        >
            {error &&
                <Alert
                    isError
                    closeAfter={7000}
                    title="Erro"
                    icon="code__errorCircle"
                    message={error}
                    state={error}
                    setState={setError}
                />
            }

            <form className="formAuth" id="formLogin" onSubmit={handleSubmit}>
                <Fieldset
                    toTheForm="formAuthLogin"
                    name="Login"
                    title="Login"
                >
                    <FormItem>
                        <Label htmlFor="email"> E-mail </Label>
                        <Input
                            id="email" type="email" placeholder="Informe o email"
                            required
                            autoFocus
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            disabled={disabled}
                        />
                    </FormItem>

                    <FormItem>
                        <Label htmlFor="password"> Password </Label>
                        <Input
                            id="password" type="password" placeholder="Informe o password"
                            required
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            disabled={disabled}
                        />
                    </FormItem>
                </Fieldset>

                <FormItem>
                    <Button isFull type="submit" disabled={disabled}> Entrar </Button>
                </FormItem>
            </form>
        </LayoutAuth>
    );
}