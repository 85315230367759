import { useEffect, useState } from 'react';

// HOOKS...
import useApi from '../../../../helpers/hooks/useApi';
import { useAuth } from '../../../../helpers/hooks/useAuth';

// COMPONENTS...
import { LayoutDashboardPanel } from '../../../../components/Layout/LayoutDashboard';
import { Table, TableBody, TableHead } from '../../../../components/Table/Table';
import { DropdownButton, DropdownItemLink } from '../../../../components/Dropdown/Dropdown';
import { Paginate, PaginateItem, PaginateItemButton } from '../../../../components/Paginate/Paginate';
import { Alert } from '../../../../components/Alert/Alert';

// STYLES...
import { Form } from '../../../../components/Form/FormStyled';

// TYPES...
import { CategoryType } from '../../../../helpers/types/Types';
import { PaginateType } from '../../../../components/Paginate/PaginateType';

export function CategoryRead() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
    const api = useApi();
    const auth = useAuth();
    const token = auth.getToken();

    const [backendToken, setBackendToken] = useState(false);
    const [error, setError] = useState('');
    const [categories, setCategories] = useState<CategoryType[]>([]);

    const [paginate, setPaginate] = useState<PaginateType>(); // pq undefined???
    const [page, setPage] = useState(1);
    // const [totalPages, setTotalPage] = useState(paginate?.last_page);

    console.log(paginate);

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login';
            }
        }
        verifyTokenBackend();
    }, []);

    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = async () => {
        const cats = await api.getCategories(host);
        setCategories(cats.data);
        setPaginate(cats);
    }

    const handleDelete = async (id: number) => {
        const data = await api.setCategoryDelete(host, token, id);

        if (data.error) {
            setError(data.error);
        } else {
            getCategories(); // Chama a função para consultar...
        }
    }

    const handlePageToggle = (page: number) => {
        setPage(page);
    }

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="dashboardPanel"
                title="Categorias"
                description="Veja todas as categorias abaixo"
                linkId="newCategory"
                linkRoute="/panel/client/categories/create"
                linkName="Adicionar"
                linkIcon="code__plus"
            >
                {error &&
                    <Alert
                        isError
                        closeAfter={7000}
                        title="Erro"
                        icon="code__errorCircle"
                        message={error}
                        state={error}
                        setState={setError}
                    />
                }

                <>
                    <Table id="tableSectors">
                        <TableHead>
                            <tr>
                                <th scope="col"> # </th>
                                <th scope="col"> Nome </th>
                                {/* <th scope="col"> Tipo </th> */}
                                <th scope="col"> Ações </th>
                            </tr>
                        </TableHead>

                        <TableBody>
                            {categories
                                ? categories.map((category, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{category.id}</td>
                                            <td>{category.name}</td>
                                            {/* <td>{category.type.formated}</td> */}
                                            <td className="td__action">
                                                <DropdownButton
                                                    id={category.id.toString()}
                                                    childrenId={category.id.toString()}
                                                    name="Ações"
                                                >
                                                    <DropdownItemLink
                                                        link={`/panel/client/categories/edit/${category.id}`}
                                                        name="Editar"
                                                        icon="general__edit"
                                                    />

                                                    <Form
                                                        className="full__"
                                                        onClick={() => handleDelete(category.id)}
                                                    >
                                                        <DropdownItemLink
                                                            link=""
                                                            name="Deletar"
                                                            icon="general__trash"
                                                        />
                                                    </Form>
                                                </DropdownButton>
                                            </td>
                                        </tr>
                                    );
                                })
                                : null
                            }
                        </TableBody>
                    </Table>

                    <Paginate
                        from={paginate?.from}
                        to={paginate?.to}
                        total={paginate?.total}
                        per_page={paginate?.per_page}
                    >
                        {/* <PaginateItemButton
                            title="Primeira página"
                            icon="navigation__angleDoubleLeft"
                            click={() => handlePageToggle(totalPages - totalPages + 1)}
                        /> */}

                        <PaginateItemButton
                            title="Página anterior"
                            icon="navigation__angleLeft"
                            click={() => handlePageToggle(page + 1)}
                        />
                        {/* {console.log(Array(paginate?.last_page))} */}
                        {Array(paginate?.last_page).splice(1)
                            .fill(0)
                            .map((item, index) => (
                                <PaginateItem
                                    key={index}
                                    active={page}
                                    current={index + 1}
                                    click={() => setPage(index + 1)}
                                />
                            ))
                        }   

                        {/* {Array(paginate?.last_page).fill(0).map((item, index) => (
                            <PaginateItem
                                key={index}
                                active={page}
                                current={index + 1}
                                click={() => setPage(index + 1)}
                            />
                        ))} */}

                        {/* {paginate.last_page <= 1
                            ?   <PaginateItemButton
                                    title="Próxima página"
                                    icon="navigation__angleRight"
                                    click={() => handlePageToggle(page - 1)}
                                />
                            : ''
                        } */}
                        <PaginateItemButton
                            title="Próxima página"
                            icon="navigation__angleRight"
                            click={() => handlePageToggle(page - 1)}
                        />

                        {/* <PaginateItemButton
                            title="Última página"
                            icon="navigation__angleDoubleRight"
                            click={() => handlePageToggle(totalPages)}
                        /> */}
                    </Paginate>
                </>
            </LayoutDashboardPanel>
            : <></>
    );
}
