import styled from 'styled-components';

export const Form = styled.form`
    &.form__ {
        width: 100%;
        height: auto;
        padding: 2rem;
        border-radius: 0.44rem;
        background-color: ${props => props.theme.themeColors.backgroundPrimary};
    }

    &.full__ {
        width: 100%;
    }
`;

export const FieldsetContainer = styled.fieldset`
    margin: 1.5rem 0;
    padding: 2rem 1rem;
    border-radius: 0.75rem;
    border: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};

    legend {
        width: fit-content;
        padding: 0 0.5rem;

        font-size: 1rem;
        text-transform: uppercase;
        color: ${props => props.theme.themeColors.textPrimary};
    }
`;

export const InputContainer = styled.input`
    width: 100%;
    height: 3.125rem;
    margin: 0.5rem 0;
    padding: 0 1rem;
    border: none;
    border-radius: 0.75rem;
    background-color: ${props => props.theme.themeColors.backgroundSecondary};
    color: ${props => props.theme.themeColors.textPrimary};

    &::placeholder {
        font-size: 0.8rem;
        letter-spacing: normal;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: ${props => props.theme.themeColors.backgroundTertiary};
    }
`;

export const Select = styled.select`
    width: 100%;
    height: 3rem;
    margin: 0.5rem 0 !important;
    padding: 0 0.5rem;
    border: none;
    border-radius: 0.75rem;
    background-color: ${props => props.theme.themeColors.backgroundSecondary};
    color: ${props => props.theme.themeColors.textPrimary};
`;

export const Textarea = styled.textarea`
    width: 100%;
    height: 15.625rem;
    resize: none;
    margin: 0.5rem 0;
    padding: 1rem;
    border: none;
    border-radius: 0.44rem;
    background-color: ${props => props.theme.themeColors.backgroundSecondary};
    color: ${props => props.theme.themeColors.textPrimary};

    &:disabled {
        cursor: not-allowed;
        background-color: ${props => props.theme.themeColors.backgroundTertiary};
    }
`;

export const Label = styled.label`
    min-height: 1.688rem;

    font-size: 0.8rem;
    text-transform: uppercase;
    color: ${props => props.theme.themeColors.textPrimary};

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const FormItem = styled.div`
    width: 100%;
    margin: 0.5rem 0.2rem;
`;

export const FormGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .form__item {
        flex: 1;

        min-width: 16.25rem;
    }
`;

export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    input[type = "checkbox"] {
        cursor: pointer;

        width: 1.5rem;
        height: 1.5rem;
        margin: 0;
        border-radius: 0.75rem;
        border-color: ${props => props.theme.themeColors.backgroundSecondary};
    }

    label {
        margin-left: 0.5rem;
    }
`;