import { useState } from "react";

// HOOKS...
import useApi from '../../helpers/hooks/useApi';
import { useAuth } from '../../helpers/hooks/useAuth';

// COMPONENTS...
import { Input } from '../Form/Form';
import { Button } from '../Button/Button';

// STYLES...
import LogoImg from '../../assets/images/logo.png';
import { AsideContainer } from './AsideStyled';

// TYPES...
import { AsideType } from './AsideType';

export function Aside({ children }: AsideType) {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
    const api = useApi();
    const auth = useAuth();
    const [isSmall, setIsSmall] = useState(false);
    // const [drop, setDrop] = useState(false);

    const handleLogout = async () => {
        if (auth.isLogged()) { // Verifica se tem token...
            await api.doLogout(host, auth.getToken()); // Desloga no back...
            auth.doLogout(); // Desloga no front...
            window.location.href = '/services'; // Redireciona dando refresh...
        }
    }

    const handleSmall = () => {
        setIsSmall(!isSmall);
    }

    return (
        <AsideContainer className={`aside__ ${isSmall ? 'aside__small' : ''}`}>
            <div className="aside__wrapper">
                <Button isIcon isClear onClick={handleSmall}>
                    <span className={`icon__ ${isSmall ? 'navigation__angleRight' : 'navigation__angleLeft'}`}></span>
                </Button>

                <div className="aside__header">
                    {/* <img src={LogoImg} alt="" /> */}
                </div>

                <div className="aside__inner">
                    {/* <Input
                        type="search"
                        placeholder="Pesquisar..."
                    /> */}

                    <nav className="aside__nav">
                        <ul className="aside__menu"> {children} </ul>
                    </nav>
                </div>

                <div className="aside__footer">
                    <Button isClear onClick={handleLogout}>
                        <span className="icon__ electric__shutdown"></span>
                        <span className="text__"> Sair </span>
                    </Button>
                </div>
            </div>
        </AsideContainer>
    );
}