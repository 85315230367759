import styled from "styled-components";

export const ServiceWrapper = styled.div`
    position: relative;

    width: 100%;
    height: 100%;
    padding: 5rem;
    border-radius: 1.25rem;

    .service__inner {
        height: 100%;
        margin-top: 2rem;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    @media (max-width: 1024px) {
        padding: 5rem 1rem;
    }

    @media (max-width: 768px) {
        .service__inner {
            justify-content: center;
        }
    }
`;