// STYLES...
import { PaginateContainer, PaginateItemButtonContainer, PaginateItemContainer } from "./PaginateStyled";

// TYPES...
import { PaginateItemButtonType, PaginateItemType, PaginateType } from "./PaginateType";

export function Paginate({ from, to, total, per_page, current_page, last_page, children }: PaginateType) {
    return (
        <PaginateContainer>
            <div>
                <small> Registros por página, {per_page} </small>
                <small> Itens do {from} ao {to} de {total} registros. </small>
            </div>

            <ul>
                {children}
            </ul>
        </PaginateContainer>
    );
}

export function PaginateItem({ current, active }: PaginateItemType) {
    return (
        <PaginateItemContainer className={`${active === current ? 'active__' : ''}`}>
            {current}
            {/* <a href={}> {current} </a> */}
        </PaginateItemContainer>
    );
}

export function PaginateItemButton({ icon, title }: PaginateItemButtonType) {
    return (
        <PaginateItemButtonContainer title={title}>
            <span className={`icon__ ${icon}`}></span>
        </PaginateItemButtonContainer>
    );
}