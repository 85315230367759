import { useEffect, useState } from "react";
import { useParams } from "react-router";

// HOOKS...
import useApi from "../../../../helpers/hooks/useApi";
import { useAuth } from "../../../../helpers/hooks/useAuth";

// COMPONENTS...
import { LayoutDashboardPanel } from "../../../../components/Layout/LayoutDashboard";
import { Fieldset, Input } from "../../../../components/Form/Form";
import { ChatMessage } from "../../../../components/Chat/Chat";

// STYLES...
import { Form, FormGroup, FormItem, Label, Textarea } from "../../../../components/Form/FormStyled";
import { FakeLoader } from "../../../../components/FakeLoader/FakeLoaderStyled";

// TYPES...
import { OmbudsmanType } from "../../../../helpers/types/Types";
import { Button, ButtonItemLink, ButtonPrintPdf } from "../../../../components/Button/Button";
import { LinkSimple } from "../../../../components/Link/Link";
import { ButtonContainer } from "../../../../components/Button/ButtonStyled";

export function OmbudsmanShow() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const api = useApi();
    const auth = useAuth();
    const { uuid } = useParams();
    const token = auth.getToken();

    const [backendToken, setBackendToken] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ombudsman, setOmbudsman] = useState<OmbudsmanType>();

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    useEffect(() => {
        const getOmbudsman = async () => {
            const ombu = await api.getOmbudsman(host, token, uuid);
            setOmbudsman(ombu);
            setLoading(false);
        }
        getOmbudsman();
    }, []);

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="ombudsmanPanel"
                title="Solicitação: Ouvidoria"
                description="Veja todos os dados da solicitação"
                linkId="returnListOmbudsmans"
                linkRoute="/panel/client/ombudsmans"
                linkName="Voltar"
                linkIcon="text__undo"
            >
                
                <ButtonPrintPdf 
                    title="Imprimir" 
                    target="_blank" 
                    icon="device__printer" 
                    link={api.getApiBaseUrl() + "ombudsmans/" + uuid + "/pdf?host_client=" + host + "&token=" + token} 
                />
               
                
                <Form id="formCategory">
                    <Fieldset
                        toTheForm="formCategory"
                        name="dadosCategory"
                        title="Informações"
                    >
                        <FormGroup>
                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {ombudsman?.code && <Label htmlFor="code"> Código da solicitação </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {ombudsman?.code && <Input type="text" name="code" value={ombudsman?.code} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {ombudsman?.status && <Label htmlFor="status"> Status </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {ombudsman?.status && <Input type="text" name="status" value={ombudsman?.status} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {ombudsman?.created_at && <Label htmlFor="created_at"> Data da solicitação </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {ombudsman?.created_at && <Input type="text" name="created_at" value={ombudsman?.created_at} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {ombudsman?.sector.name && <Label htmlFor="sector"> Setor </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {ombudsman?.sector.name && <Input type="text" name="sector" value={ombudsman?.sector.name} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {ombudsman?.category.name && <Label htmlFor="category"> Categoria </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {ombudsman?.category.name && <Input type="text" name="category" value={ombudsman?.category.name} />} </>
                                }
                            </FormItem>

                            {ombudsman?.anonymous === false &&
                                <>
                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.name && <Label htmlFor="name"> Nome </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.name && <Input type="text" name="name" value={ombudsman?.name} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.sex && <Label htmlFor="sex"> Sexo </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.sex && <Input type="text" name="sex" value={ombudsman?.sex} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.email && <Label htmlFor="email"> E-mail </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.email && <Input type="text" name="email" value={ombudsman?.email} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.telephone && <Label htmlFor="telephone"> Telefone </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.telephone && <Input type="text" name="telephone" value={ombudsman?.telephone} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.cellphone && <Label htmlFor="cellphone"> Celular </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.cellphone && <Input type="text" name="cellphone" value={ombudsman?.cellphone} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.schooling && <Label htmlFor="schooling"> Escolaridade </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.schooling && <Input type="text" name="schooling" value={ombudsman?.schooling} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.occupation && <Label htmlFor="occupation"> Ocupação </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.occupation && <Input type="text" name="occupation" value={ombudsman?.occupation} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.zip_code && <Label htmlFor="zip_code"> CEP </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.zip_code && <Input type="text" name="zip_code" value={ombudsman?.zip_code} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.state && <Label htmlFor="state"> Estado </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.state && <Input type="text" name="state" value={ombudsman?.state} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.city && <Label htmlFor="city"> Cidade </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.city && <Input type="text" name="city" value={ombudsman?.city} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.district && <Label htmlFor="district"> Bairro </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.district && <Input type="text" name="district" value={ombudsman?.district} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.road && <Label htmlFor="road"> Rua </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.road && <Input type="text" name="road" value={ombudsman?.road} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.number && <Label htmlFor="number"> Número </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.number && <Input type="text" name="number" value={ombudsman?.number} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.complement && <Label htmlFor="complement"> Complemento </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.complement && <Input type="text" name="complement" value={ombudsman?.complement} />} </>
                                        }
                                    </FormItem>

                                    <FormItem>
                                        {loading
                                            ? <FakeLoader width={10} height={1.8} />
                                            : <> {ombudsman?.reference && <Label htmlFor="reference"> Referencia </Label>} </>
                                        }

                                        {loading
                                            ? <FakeLoader width={90} height={3} />
                                            : <> {ombudsman?.reference && <Input type="text" name="reference" value={ombudsman?.reference} />} </>
                                        }
                                    </FormItem>
                                </>
                            }

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {ombudsman?.resume && <Label htmlFor="resume"> Resumo </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {ombudsman?.resume && <Input type="text" name="resume" value={ombudsman?.resume} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {ombudsman?.request && <Label htmlFor="request"> Mensagem </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {ombudsman?.request && <Textarea name="request" value={ombudsman?.request} />} </>
                                }
                            </FormItem>
                        </FormGroup>
                    </Fieldset>
                </Form>

                {ombudsman?.replies &&
                    <div>
                        <h5> Histórico do bate papo </h5>

                        <ul>
                            {ombudsman.replies.map((reply, index) => {
                                if (reply.author.original === 1) {
                                    return (
                                        <ChatMessage
                                            key={reply.id}
                                            message={reply.reply}
                                            author={reply.author.formated}
                                        />
                                    );
                                } else {
                                    return (
                                        <ChatMessage
                                            isEntrance
                                            key={reply.id}
                                            message={reply.reply}
                                            author={reply.author.formated}
                                        />
                                    );
                                }
                            })}
                        </ul>

                        {(ombudsman.status === 'Pendente' || ombudsman.status === 'Em andamento') &&
                            <LinkSimple
                                link={`/panel/client/ombudsmans/reply/${ombudsman.uuid}`}
                                // name="Responder"
                                // icon="communication__chat5"
                                target="_blank"
                            >
                                Responder
                            </LinkSimple>
                        }
                    </div>
                }
            </LayoutDashboardPanel>
            : <></>
    );
}
