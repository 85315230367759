import styled from "styled-components";

export const ChatContainer = styled.dl`
    position: relative;

    width: 100%;
    height: 100%;
    padding: 1rem;
    border-radius: 0.75rem;

    dt {
        width: 25rem;
        max-width: 25rem;
        height: auto;
        margin-bottom: 0.5rem;
        margin-top: 1rem;

        display: flex;
        align-items: center;

        .icon__ {
            margin-right: 0.5rem;
        }
    }

    dd {
        width: 25rem;
        max-width: 25rem;
        height: auto;
        margin-bottom: 1rem;
        padding: 0.5rem;
        border-radius: 0.44rem;
        background-color: ${props => props.theme.themeColors.backgroundPrimary};

        color: ${props => props.theme.themeColors.textPrimary};
    }

    .chat__inner {
        width: 100%;
        height: calc(100% - 6.25rem);
        overflow-y: auto;
        padding: 1rem 1.5rem 1rem 0;
    }
`;

export const MessageContainer = styled.div`
    width: 100%;
    margin-bottom: 0.5rem;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;

    dt {
        width: auto;
        margin: 0.5rem 0;

        text-align: right;
        color: ${props => props.theme.themeColors.textSecondary};

        justify-content: flex-end;

        .icon__ {
            width: 1.5rem;
            height: 1.5rem;
            background-color: ${props => props.theme.colors.blue};
        }
    }

    &.message__in {
        align-items: flex-start;
        justify-content: flex-start;

        dt {
            text-align: left;

            justify-content: flex-start;
        }
    }

    dd {
        width: fit-content;
        max-width: 21.875rem;
        height: auto;
        margin: 0;
        padding: 1rem;
        border-radius: 0.75rem;
        background-color: ${props => props.theme.themeColors.backgroundSecondary};

        word-wrap: break-word;
    }
`;

export const MessageNewContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    width: 100%;
    height: 6.25rem;
    padding: 1rem;
    border-top: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};
    background-color: ${props => props.theme.themeColors.backgroundPrimary};

    .form__content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        textarea {
            height: 4.375rem;
            overflow-y: auto;
            border-radius: 0.75rem;
        }

        button {
            margin-left: 0.5rem;
        }
    }
`;