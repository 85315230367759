import styled from "styled-components";

// TYPES...
import { FakeLoaderType } from "./FakeLoaderType";

export const FakeLoader = styled.div<FakeLoaderType>`
    width: ${props => props.width || 4}rem;
    max-width: 100%;
    height: ${props => props.height || 4}rem;
    margin: 0.4rem;
    border-radius: 0.75rem;
    border: 0.063rem solid ${props => props.theme.themeColors.backgroundPrimary};
    /* background-color: ${props => props.theme.themeColors.backgroundTertiary}; */

    background-color: ${props => props.theme.themeColors.colorGlass};
    -webkit-backdrop-filter: blur(0.375rem);
    backdrop-filter: blur(0.375rem);
`;