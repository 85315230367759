// STYLES...
import { BadgeContainer } from "./BadgeStyled";

// TYPES...
import { BadgeType } from "./BadgeType";

export function Badge({
    isSuccess = false,
    isWarning = false,
    isProcess = false,
    isDanger = false,
    children
}: BadgeType) {
    return (
        <BadgeContainer
            className={`${isSuccess ? 'badge__success' : ''} ${isWarning ? 'badge__warning' : ''} ${isProcess ? 'badge__process' : ''} ${isDanger ? 'badge__danger' : ''}`}
        > {children} </BadgeContainer>
    );
}