import styled from "styled-components";

export const HomeContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 1.5rem;

    .home__infos {
        flex: 1;

        height: inherit;
        border-radius: 1.25rem;

        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    @media (max-width: 505px) {
        padding-bottom: 10rem;
    }
`;

export const HomeBox = styled.div`
    position: absolute;
    bottom: -75px;
    left: 50%;
    transform: translateX(-50%);

    width: 70%;
    height: 9.375rem;
    padding: 0 2rem;
    border-radius: 1.25rem;
    border: 0.063rem solid ${props => props.theme.themeColors.backgroundPrimary};
    /* background-color: ${props => props.theme.themeColors.backgroundPrimary}; */

    display: flex;
    align-items: center;
    justify-content: center;

    &.box__alternate {
        height: auto;
        padding: 1rem 2rem;
        background-color: ${props => props.theme.themeColors.colorGlass};
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(6px);

        form {
            width: 100%;
            padding: 0;
            background-color: transparent;

            button {
                margin-top: 1.7rem;
                margin-left: 1rem;
            }
        }
    }

    @media (max-width: 768px) {

        &.box__alternate {
            width: 90%;
            padding: 1rem;

            form {
                .form__item {
                    flex: 0 1 auto;
                    min-width: 100%;
                    width: 100%;
                }

                button {
                    width: 100%;
                    margin: 1rem 0;
                }
            }
        }
    }
`;