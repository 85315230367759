import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// COMPONENTS...
import { LayoutDashboardPanel } from '../../../../components/Layout/LayoutDashboard';
import { Fieldset, Input } from '../../../../components/Form/Form';
import { Button } from '../../../../components/Button/Button';

// HOOKS....
import useApi from '../../../../helpers/hooks/useApi';
import { useAuth } from '../../../../helpers/hooks/useAuth';
import { Alert } from '../../../../components/Alert/Alert';

// STYLES...
import { Form, FormGroup, FormItem, Label } from '../../../../components/Form/FormStyled';

export function CategoryCreate() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const api = useApi();
    const auth = useAuth();
    const navigate = useNavigate();
    const token = auth.getToken();

    const [backendToken, setBackendToken] = useState(false);
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    // const [type, setType] = useState('');

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    const handleClear = () => {
        setName('');
        // setType('');
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = await api.setCategory(host, token, name);

        if (data.error) {
            setError(data.error);
        } else {
            handleClear();
            navigate('/panel/client/categories');
        }
    }

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="dashboardPanel"
                title="Nova Categoria"
                description="Adicione uma nova categoria"
                linkId="showCategories"
                linkRoute="/panel/client/categories"
                linkName="Voltar"
                linkIcon="text__undo"
            >
                {error &&
                    <Alert
                        isError
                        closeAfter={7000}
                        title="Erro"
                        icon="code__errorCircle"
                        message={error}
                        state={error}
                        setState={setError}
                    />
                }

                <Form id="formCategory" onSubmit={handleSubmit}>
                    <Fieldset
                        toTheForm="formCategory"
                        name="dadosCategory"
                        title="Dados"
                    >
                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="name"> Nome <span id="info" className="required__field"> * </span> </Label>
                                <Input otherClass="name" id="name" type="text" name="name" value={name} onChange={event => setName(event.target.value)} />
                            </FormItem>

                            {/* <FormItem>
                                <Label htmlFor="sector"> Tipo <span id="info" className="required__field"> * </span> </Label>
                                <Select
                                    className="" name="type" id="type" required aria-describedby="info"
                                    value={type} onChange={event => setType(event.target.value)}
                                >
                                    <option value="0" disabled> Selecione uma opção </option>
                                    <option value="1"> Ouvidoria </option>
                                    <option value="2"> e-SIC </option>
                                    <option value="3"> Poda de árvore </option>
                                    <option value="4"> Iluminação pública </option>
                                </Select>
                            </FormItem> */}
                        </FormGroup>
                    </Fieldset>

                    <FormGroup>
                        <FormItem>
                            <Button type="submit" name="enviar__poda"> Enviar </Button>
                        </FormItem>
                    </FormGroup>
                </Form>
            </LayoutDashboardPanel>
            : <> </>
    );
}