import { createContext, useEffect, useState } from 'react';

// HOOKS...
import useApi from '../hooks/useApi';

// TYPES...
import { TenantContextProviderType, TenantContextType, TenantType } from '../types/Types';

// CONTEXT...
export const TenantContext = createContext({} as TenantContextType);

// PROVIDER...
export function TenantContextProvider(props: TenantContextProviderType) {
    const api = useApi();
    const [tenant, setTenant] = useState<TenantType[]>([]);

    useEffect(() => { 
        selectTheTenant(); 
    }, []);
    
    const selectTheTenant = async () => {
        const response = await api.getTenants();
        setTenant(response);
    };

    return (
        <TenantContext.Provider value={{ tenant, selectTheTenant }}>
            {props.children}
        </TenantContext.Provider>
    );
}
