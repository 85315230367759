import { FormEvent, useState } from "react";
import { Link } from "react-router-dom";

// HOOKS...
import useApi from "../../../helpers/hooks/useApi";

// COMPONENTS...
import { LayoutBase } from "../../../components/Layout/LayoutBase";
import { ListNav } from "../../../components/List/List";
import { Header } from "../../../components/Header/Header";
import { Fieldset, Input } from "../../../components/Form/Form";
import { Button } from "../../../components/Button/Button";
import { Alert } from "../../../components/Alert/Alert";

// STYLES...
import { Form, FormGroup, FormItem, Label, Select, Textarea } from '../../../components/Form/FormStyled';

export function Esic() {
    const api = useApi();
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    // STATES...
    const [error, setError] = useState('');
    const [codeSolicitation, setCodeSolicitation] = useState('');

    const [name, setName] = useState('');
    const [sex, setSex] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [cellphone, setcellphone] = useState('');
    const [schooling, setSchooling] = useState('');
    const [occupation, setOccupation] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [road, setRoad] = useState('');
    const [number, setNumber] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [complement, setComplement] = useState('');
    const [reference, setReference] = useState('');
    const [answerMode, setAnswerMode] = useState('');
    const [resume, setResume] = useState('');
    const [request, setRequest] = useState('');

    const handleClear = () => {
        setName('');
        setSex('');
        setEmail('');
        setTelephone('');
        setcellphone('');
        setSchooling('');
        setOccupation('');
        setZipCode('');
        setRoad('');
        setNumber('');
        setDistrict('');
        setCity('');
        setState('');
        setComplement('');
        setReference('');
        setAnswerMode('');
        setResume('');
        setRequest('');
    }

    const handleQueryCep = async (event: any) => {
        const cep = event.target.value?.replace(/[^0-9]/g, '');
        setZipCode(cep);

        if (cep?.length === 8) {
            const data = await api.getCep(cep);

            setZipCode(data.cep.replace(/[^0-9]/g, ''));
            setState(data.uf);
            setCity(data.localidade);
            setDistrict(data.bairro);
            setRoad(data.logradouro);
            setComplement(data.complemento);
        }
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = await api.setEsic(host, name, sex, email, telephone, cellphone, schooling, occupation, zipCode, state, city, district, road, number, complement, answerMode, resume, request);

        if (data.code) {
            setCodeSolicitation(data.code);
            handleClear();

        } else {
            setError(data.error);
        }
    }

    const handleReturnHome = () => {
        let uri = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
        var captureUrl = 0;

        if (process.env.NODE_ENV !== 'production') {
            captureUrl = uri.indexOf('localhost:3000');
        } else {
            captureUrl = uri.indexOf('servicosaocidadao.net.br');
        }

        if (captureUrl !== -1) {
            let url = uri.slice(captureUrl);
            window.location.href = `http://${url}`;
        } else {
            console.log('Não existe URL padrão na URI');
        }
    }

    return (
        <LayoutBase name="About Page" id="aboutPage">
            <Header>
                <ListNav>
                    <li>
                        <span className="itemButton__" onClick={handleReturnHome}>
                            <span className="icon__ home"></span>
                            Home
                        </span>
                    </li>

                    <li>
                        <Link to="/services">
                            <span className="icon__ general__settings2"></span>
                            Serviços
                        </Link>
                    </li>
                </ListNav>
            </Header>

            <div className="container__">
                <div>
                    <h1> Solicitar e-SIC<span className="full__stop">.</span> </h1>
                    <p> O Sistema Eletrônico do Serviço de Informações ao Cidadão (e-SIC) permite que qualquer pessoa, física ou jurídica, encaminhe pedidos de acesso à informação, acompanhe o prazo e receba a resposta da solicitação realizada para órgãos públicos. </p>
                </div>

                {error &&
                    <Alert
                        isError
                        closeAfter={7000}
                        title="Erro"
                        icon="code__errorCircle"
                        message={error}
                        state={error}
                        setState={setError}
                    />
                }

                {codeSolicitation &&
                    <Alert
                        isCode
                        title="Código da solicitação"
                        icon="shopping__ticket"
                        value={codeSolicitation}
                        state={codeSolicitation}
                        setState={setCodeSolicitation}
                    />
                }

                <Form id="formEsic" onSubmit={handleSubmit}>
                    <Fieldset
                        toTheForm="formEsic"
                        name="dadosEsic"
                        title="Dados"
                    >
                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="name"> Nome <span id="info" className="required__field"> * </span> </Label>
                                <Input
                                    type="text"
                                    id="name"
                                    name="name"
                                    required
                                    aria-describedby="info"
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="sex"> Sexo </Label>
                                <Select
                                    id="sex"
                                    name="sex"
                                    value={sex}
                                    onChange={event => setSex(event.target.value)}
                                >
                                    <option value="" disabled> Selecione uma opção </option>
                                    <option value="1"> Feminino </option>
                                    <option value="2"> Masculino </option>
                                    <option value="3"> Outro </option>
                                    <option value="4"> Prefiro não informar </option>
                                </Select>
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="email"> E-mail </Label>
                                <Input
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="telephone"> Telefone </Label>
                                <Input
                                    id="telephone"
                                    type="tel"
                                    name="telephone"
                                    minLength={8}
                                    maxLength={10}
                                    value={telephone}
                                    onChange={event => setTelephone(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="cellphone"> Celular </Label>
                                <Input
                                    id="cellphone"
                                    type="tel"
                                    name="cellphone"
                                    className="cellphone"
                                    minLength={11}
                                    maxLength={11}
                                    value={cellphone}
                                    onChange={event => setcellphone(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="schooling"> Escolaridade </Label>
                                <Select
                                    id="schooling"
                                    name="schooling"
                                    value={schooling}
                                    onChange={event => setSchooling(event.target.value)}
                                >
                                    <option value="" disabled> Selecione uma opção </option>
                                    <option value="1"> Analfabeto </option>
                                    <option value="2"> Ensino Fundamental incompleto </option>
                                    <option value="3"> Ensino Fundamental completo </option>
                                    <option value="4"> Ensino Médio completo </option>
                                    <option value="5"> Ensino Médio incompleto </option>
                                    <option value="6"> Graduação ou Tecnologo </option>
                                    <option value="7"> Pós-graduação </option>
                                    <option value="8"> Mestrado </option>
                                    <option value="9"> Doutorado </option>
                                    <option value="10"> Pós-Doutorado </option>
                                </Select>
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="occupation"> Ocupação </Label>
                                <Input
                                    id="occupation"
                                    type="text"
                                    name="occupation"
                                    className="occupation"
                                    value={occupation}
                                    onChange={event => setOccupation(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>
                    </Fieldset>

                    <Fieldset
                        toTheForm="formEsic"
                        name="enderecoEsic"
                        title="Endereço"
                    >
                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="zip_code"> CEP </Label>
                                <Input
                                    id="zip_code"
                                    type="text"
                                    name="zip_code"
                                    className="zip_code"
                                    minLength={8}
                                    maxLength={8}
                                    data-mask="00000-000"
                                    value={zipCode}
                                    onChange={handleQueryCep}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="road"> Rua </Label>
                                <Input
                                    type="text"
                                    name="road"
                                    className="road"
                                    id="road" value={road}
                                    onChange={event => setRoad(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="number"> Número </Label>
                                <Input
                                    id="number"
                                    type="text"
                                    name="number"
                                    className="number"
                                    value={number}
                                    onChange={event => setNumber(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="district"> Bairro </Label>
                                <Input
                                    id="district"
                                    type="text"
                                    name="district"
                                    className="district"
                                    value={district}
                                    onChange={event => setDistrict(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="city"> Cidade </Label>
                                <Input
                                    id="city"
                                    type="text"
                                    name="city"
                                    className="city"
                                    value={city}
                                    onChange={event => setCity(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="state"> Estado </Label>
                                <Input
                                    id="state"
                                    type="text"
                                    name="state"
                                    className="state"
                                    minLength={2}
                                    maxLength={2}
                                    value={state}
                                    onChange={event => setState(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="complement"> Complemento </Label>
                                <Input
                                    id="complement"
                                    type="text"
                                    name="complement"
                                    className="complement"
                                    value={complement}
                                    onChange={event => setComplement(event.target.value)}
                                />
                            </FormItem>

                            <FormItem>
                                <Label htmlFor="reference"> Referência </Label>
                                <Input
                                    id="reference"
                                    type="text"
                                    name="reference"
                                    className="reference"
                                    value={reference}
                                    onChange={event => setReference(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>
                    </Fieldset>

                    <Fieldset
                        toTheForm="formEsic"
                        name="solicitacaoEsic"
                        title="Solicitação"
                    >
                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="answer_mode"> Como deseja receber o retorno <span id="info" className="required__field"> * </span> </Label>
                                <Select
                                    id="answer_mode"
                                    name="answer_mode"
                                    value={answerMode}
                                    onChange={event => setAnswerMode(event.target.value)}
                                >
                                    <option value="" disabled> Selecione uma opção </option>
                                    <option value="1"> Em mãos (pode haver custos) </option>
                                    <option value="2"> No email </option>
                                    <option value="3"> Na resposta </option>
                                </Select>
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="resume"> Resumo <span id="info" className="required__field"> * </span> </Label>
                                <Input
                                    id="resume"
                                    type="text"
                                    name="resume"
                                    className="resume"
                                    required
                                    aria-describedby="info"
                                    value={resume}
                                    onChange={event => setResume(event.target.value)}
                                />
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="request"> Descrição da solicitação <span id="info" className="required__field"> * </span> </Label>
                                <Textarea
                                    id="request"
                                    name="request"
                                    className="request"
                                    required
                                    value={request}
                                    onChange={event => setRequest(event.target.value)}
                                    aria-describedby="info"
                                ></Textarea>
                            </FormItem>
                        </FormGroup>
                    </Fieldset>

                    <FormGroup>
                        <FormItem>
                            <Button type="submit" name="enviar__esic"> Enviar </Button>
                        </FormItem>
                    </FormGroup>
                </Form>
            </div>
        </LayoutBase>
    );
}