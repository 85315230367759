import { useEffect, useState } from 'react';

// HOOKS...
import useApi from '../../../../helpers/hooks/useApi';
import { useAuth } from '../../../../helpers/hooks/useAuth';

// COMPONENTS...
import { LayoutDashboardPanel } from '../../../../components/Layout/LayoutDashboard';
import { Table, TableHead, TableBody } from '../../../../components/Table/Table';
import { Badge } from '../../../../components/Badge/Badge';
import { DropdownButton, DropdownItemLink } from '../../../../components/Dropdown/Dropdown';
import { Alert } from '../../../../components/Alert/Alert';

// TYPES...
import { EsicType } from '../../../../helpers/types/Types';

// STYLES...
import { Form } from '../../../../components/Form/FormStyled';

export function EsicRead() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const api = useApi();
    const auth = useAuth();

    const token = auth.getToken();

    const [esics, setEsics] = useState<EsicType[]>([]);
    const [error, setError] = useState('');

    const [backendToken, setBackendToken] = useState(false);

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    const getEsics = async () => {
        const pruning = await api.getEsics(host, token);
        setEsics(pruning.data);
    }

    useEffect(() => { getEsics(); }, []);

    const handleStatus = async (uuid: string, status: number) => {
        const data = await api.setEsicStatus(host, token, uuid, status);

        if (data.error) {
            setError(data.error);
        } else {
            getEsics();
        }
    }

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="dashboardPanel"
                title="e-SICs"
                description="Lista de e-sics solicitados"
            >
                {error &&
                    <Alert
                        isError
                        closeAfter={7000}
                        title="Erro"
                        icon="code__errorCircle"
                        message={error}
                        state={error}
                        setState={setError}
                    />
                }

                <Table id="tableSectors">
                    <TableHead>
                        <tr>
                            <th scope="col"> # </th>
                            <th scope="col"> Código </th>
                            <th scope="col"> Nome </th>
                            <th scope="col"> Data </th>
                            <th scope="col"> Status </th>
                            <th scope="col"> Ações </th>
                        </tr>
                    </TableHead>

                    <TableBody>
                        {esics.map((esic, index) => {
                            return (
                                <tr key={index}>
                                    <td> {esic.id} </td>
                                    <td> {esic.code} </td>
                                    <td> {esic.name} </td>
                                    <td> {esic.created_at} </td>
                                    <td>
                                        {(() => {
                                            if (esic.status === 'Pendente') {
                                                return <Badge isWarning> {esic.status} </Badge>

                                            } else if (esic.status === 'Em andamento') {
                                                return <Badge isProcess> {esic.status} </Badge>

                                            } else if (esic.status === 'Concluída') {
                                                return <Badge isSuccess> {esic.status} </Badge>

                                            } else if (esic.status === 'Negada') {
                                                return <Badge isDanger> {esic.status} </Badge>
                                            }
                                        })()}
                                    </td>
                                    <td className="td__action">
                                        <DropdownButton
                                            id={esic.uuid}
                                            childrenId={esic.uuid}
                                            name="Ações"
                                        >
                                            <DropdownItemLink
                                                link={`/panel/client/e-sics/show/${esic.uuid}`}
                                                name="Detalhes"
                                                icon="general__visible"
                                            />

                                            {(esic.status === 'Pendente' || esic.status === 'Em andamento') &&
                                                <DropdownItemLink
                                                    link={`/panel/client/e-sics/reply/${esic.uuid}`}
                                                    name="Responder"
                                                    icon="communication__chat5"
                                                />
                                            }

                                            <Form
                                                className="full__"
                                                onClick={() => handleStatus(esic.uuid, 3)}
                                            >
                                                <DropdownItemLink
                                                    link=""
                                                    name="Concluir solicitação"
                                                    icon="navigation__check"
                                                />
                                            </Form>

                                            {/* <Form
                                                className="full__"
                                                onClick={() => handleStatus(esic.uuid, 4)}
                                            >
                                                <DropdownItemLink
                                                    link=""
                                                    name="Negar solicitação"
                                                    icon="navigation__close"
                                                />
                                            </Form> */}
                                        </DropdownButton>
                                    </td>
                                </tr>
                            );
                        })}
                    </TableBody>
                </Table>
            </LayoutDashboardPanel>
            : <></>
    );
}