// COMPONENTS...
import { LinkSimple } from "../Link/Link";

// TYPES...
import { LayoutAuthType } from "./LayoutAuthType";

// STYLES...
import peopleImg from '../../assets/images/people2.png';
import { AsideAuthContainer, LayoutAuthContainer } from "./LayoutAuthStyled";

export function LayoutAuth(props: LayoutAuthType) {
    return (
        <LayoutAuthContainer>
            <AsideAuthContainer>
                <img src={peopleImg} alt="" />
                <h1> Serviços <br /> ao cidadão<span className="full__stop">.</span> </h1>
                <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit. </p>
            </AsideAuthContainer>

            <main className="auth__wrapper">
                <LinkSimple link={props.returnTo} id="returnBack">
                    Voltar para {props.returnText}
                </LinkSimple>

                <div className="auth__content">
                    <h3> {props.title} <br /> {props.subtitle} </h3>

                    {props.children}

                    {props.notHave &&
                        <>
                            <div className="auth__separator"> OU </div>

                            <div>
                                <span className="text__sm">
                                    {props.notHave}

                                    <LinkSimple link={props.notHaveTo ?? ''}>
                                        {props.notHaveText}
                                    </LinkSimple>
                                </span>
                            </div>
                        </>
                    }
                </div>
            </main>
        </LayoutAuthContainer>
    );
}