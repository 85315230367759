import styled from 'styled-components'

export const BadgeContainer = styled.span`
    width: fit-content;
    height: auto;
    padding: 0.2rem 0.8rem;
    border-radius: 0.44rem;
    background-color: ${props => props.theme.themeColors.backgroundSecondary};

    font-size: 0.7rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.themeColors.textPrimary};
    
    &.badge__success {
        background-color: ${props => props.theme.colors.green};
    }

    &.badge__warning {
        background-color: ${props => props.theme.colors.yellow};
        color: #000;
    }

    &.badge__process {
        background-color: ${props => props.theme.colors.blue};
        
    }

    &.badge__danger {
        background-color: ${props => props.theme.colors.red};
    }
`;