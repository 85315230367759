import styled from 'styled-components';

export const FooterDomainContainer = styled.footer`
    position: fixed;
    right: 20px;
    bottom: 10px;

    address {
        font-size: ${props => props.theme.fonts.small};
    }
`;
