import { useEffect, useState } from "react";
import { useParams } from "react-router";

// HOOKS...
import useApi from "../../../../helpers/hooks/useApi";
import { useAuth } from "../../../../helpers/hooks/useAuth";

// COMPONENTS...
import { LayoutDashboardPanel } from "../../../../components/Layout/LayoutDashboard";
import { Fieldset, Input } from "../../../../components/Form/Form";
import { ChatMessage } from "../../../../components/Chat/Chat";

// STYLES...
import { Form, FormGroup, FormItem, Label, Textarea } from "../../../../components/Form/FormStyled";
import { FakeLoader } from "../../../../components/FakeLoader/FakeLoaderStyled";

// TYPES...
import { EsicType } from "../../../../helpers/types/Types";
import { ButtonPrintPdf } from "../../../../components/Button/Button";

export function EsicShow() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const api = useApi();
    const auth = useAuth();
    const { uuid } = useParams();

    const token = auth.getToken();

    const [loading, setLoading] = useState(true);
    const [esic, setEsic] = useState<EsicType>();
    const [backendToken, setBackendToken] = useState(false);

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    useEffect(() => {
        const getEsic = async () => {
            const esc = await api.getEsic(host, token, uuid);
            setEsic(esc);
            setLoading(false);
        }
        getEsic();
    }, []);

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="esicPanel"
                title="Solicitação: e-SIC"
                description="Veja todos os dados da solicitação"
                linkId="returnListEsic"
                linkRoute="/panel/client/e-sics"
                linkName="Voltar"
                linkIcon="text__undo"
            >
                <ButtonPrintPdf 
                    title="Imprimir" 
                    target="_blank" 
                    icon="device__printer" 
                    link={api.getApiBaseUrl() + "e-sics/" + uuid + "/pdf?host_client=" + host + "&token=" + token} 
                />
                <Form id="formCategory">
                    <Fieldset
                        toTheForm="formCategory"
                        name="dadosCategory"
                        title="Informações"
                    >
                        <FormGroup>
                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.code && <Label htmlFor="code"> Código da solicitação </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.code && <Input type="text" name="code" value={esic?.code} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.status && <Label htmlFor="status"> Status </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.status && <Input type="text" name="status" value={esic?.status} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.created_at && <Label htmlFor="created_at"> Data da solicitação </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.created_at && <Input type="text" name="created_at" value={esic?.created_at} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.name && <Label htmlFor="name"> Nome </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.name && <Input type="text" name="name" value={esic?.name} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.sex && <Label htmlFor="sex"> Sexo </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.sex && <Input type="text" name="sex" value={esic?.sex} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.email && <Label htmlFor="email"> E-mail </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.email && <Input type="text" name="email" value={esic?.email} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.telephone && <Label htmlFor="telephone"> Telefone </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.telephone && <Input type="text" name="telephone" value={esic?.telephone} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.cellphone && <Label htmlFor="cellphone"> Celular </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.cellphone && <Input type="text" name="cellphone" value={esic?.cellphone} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.schooling && <Label htmlFor="schooling"> Escolaridade </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.schooling && <Input type="text" name="schooling" value={esic?.schooling} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.occupation && <Label htmlFor="occupation"> Ocupação </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.occupation && <Input type="text" name="occupation" value={esic?.occupation} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.zip_code && <Label htmlFor="zip_code"> CEP </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.zip_code && <Input type="text" name="zip_code" value={esic?.zip_code} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.state && <Label htmlFor="state"> Estado </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.state && <Input type="text" name="state" value={esic?.state} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.city && <Label htmlFor="city"> Cidade </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.city && <Input type="text" name="city" value={esic?.city} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.district && <Label htmlFor="district"> Bairro </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.district && <Input type="text" name="district" value={esic?.district} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.road && <Label htmlFor="road"> Rua </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.road && <Input type="text" name="road" value={esic?.road} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.number && <Label htmlFor="number"> Número </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.number && <Input type="text" name="number" value={esic?.number} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.complement && <Label htmlFor="complement"> Complemento </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.complement && <Input type="text" name="complement" value={esic?.complement} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.reference && <Label htmlFor="reference"> Referencia </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.reference && <Input type="text" name="reference" value={esic?.reference} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.resume && <Label htmlFor="resume"> Resumo </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.resume && <Input type="text" name="resume" value={esic?.resume} />} </>
                                }
                            </FormItem>

                            <FormItem>
                                {loading
                                    ? <FakeLoader width={10} height={1.8} />
                                    : <> {esic?.request && <Label htmlFor="request"> Mensagem </Label>} </>
                                }

                                {loading
                                    ? <FakeLoader width={90} height={3} />
                                    : <> {esic?.request && <Textarea name="request" value={esic?.request} />} </>
                                }
                            </FormItem>
                        </FormGroup>
                    </Fieldset>
                </Form>

                {esic?.replies &&
                    <div>
                        <h5> Histórico do bate papo </h5>

                        <ul>
                            {esic.replies.map((reply, index) => {
                                if (reply.author.original === 1) {
                                    return (
                                        <ChatMessage
                                            key={reply.id}
                                            message={reply.reply}
                                            author={reply.author.formated}
                                        />
                                    );
                                } else {
                                    return (
                                        <ChatMessage
                                            isEntrance
                                            key={reply.id}
                                            message={reply.reply}
                                            author={reply.author.formated}
                                        />
                                    );
                                }
                            })}
                        </ul>
                    </div>
                }
            </LayoutDashboardPanel>
            : <></>
    );
}