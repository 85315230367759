const light = {
    title: 'lightTheme',

    themeColors: {
        textPrimary: '#1F2029',
        textSecondary: '#2B2C38',

        backgroundPrimary: '#FFFFFF',
        backgroundSecondary: '#F2F2F3',
        backgroundTertiary: '#EBEBEB',

        colorGlass: 'rgba(255, 255, 255, 0.2)',
    },
};

export default light;