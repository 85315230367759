import { FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router";

// HOOKS...
import useApi from "../../../../helpers/hooks/useApi";
import { useAuth } from "../../../../helpers/hooks/useAuth";

// COMPONENTS...
import { LayoutDashboardPanel } from "../../../../components/Layout/LayoutDashboard";
import { Button } from "../../../../components/Button/Button";
import { Fieldset } from "../../../../components/Form/Form";
import { Alert } from "../../../../components/Alert/Alert";

// STYLES...
import { Form, FormGroup, FormItem, Label, Select, Textarea } from "../../../../components/Form/FormStyled";

export function PruningTreeReply() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const api = useApi();
    const auth = useAuth();
    const { uuid } = useParams();
    const token = auth.getToken();

    const [backendToken, setBackendToken] = useState(false);
    const [error, setError] = useState('');
    const [reply, setReply] = useState('');
    const [status, setStatus] = useState(2);

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const author = 2;
        const data = await api.setPruningTreeReply(host, token, author, uuid, reply, status);

        if (data.error) {
            setError(data.error);
        } else {
            setReply('');
        }
    }

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="pruningTreeReply"
                title="Responder a solicitação de poda de árvore"
                description="Responda ao cidadão."
                linkId="returnListPruningTree"
                linkRoute="/panel/client/pruning-trees"
                linkName="Voltar"
                linkIcon="text__undo"
            >
                {error &&
                    <Alert
                        isError
                        closeAfter={7000}
                        title="Erro"
                        icon="code__errorCircle"
                        message={error}
                        state={error}
                        setState={setError}
                    />
                }

                <Form id="formReplyPruningTree" onSubmit={handleSubmit}>
                    <Fieldset
                        toTheForm="formReplyPruningTree"
                        name="dadosReplyPruningTree"
                        title="Responda"
                    >
                        <FormGroup>
                            <p className="text__danger"> Só utilize o campo de status se realmente for encerrar a solicitação (negada/concluída) caso ainda não tenha finalizado a solicitação, deixe o campo em branco. Pois se utilizar uma das duas opções, a mesma será encerrada e o cidadão não poderá mais contata-los através dessa solicitação. </p>
                            <FormItem>
                                <Label htmlFor="sector"> Status </Label>
                                <Select
                                    name="type" id="type"
                                    value={status}
                                    onChange={event => setStatus(parseInt(event.target.value))}
                                >
                                    <option value="2"> Em andamento </option>
                                    <option value="3"> Concluída </option>
                                    <option value="4"> Negada </option>
                                </Select>
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Label htmlFor="reply"> Mensagem <span id="info" className="required__field"> * </span> </Label>
                                <Textarea className="reply" id="reply" name="reply" value={reply} onChange={event => setReply(event.target.value)} required aria-describedby="info"></Textarea>
                            </FormItem>
                        </FormGroup>

                        <FormGroup>
                            <FormItem>
                                <Button type="submit" name="replyPruningTree"> Responder </Button>
                            </FormItem>
                        </FormGroup>
                    </Fieldset>
                </Form>
            </LayoutDashboardPanel>
            : <></>
    );
}
