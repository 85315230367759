import styled from "styled-components";

export const LayoutBaseContainer = styled.div`
    position: relative;

    width: 100%;
    min-height: 100vh;
    padding: 5rem 3rem 0 3rem;
    background-color: ${props => props.theme.themeColors.backgroundTertiary};

    display: flex;
    align-items: flex-start;
    justify-content: center;

    main {
        position: relative;

        width: 100%;
        min-height: 60vh;
        border-radius: 1.25rem;
        border: 0.063rem solid ${props => props.theme.themeColors.backgroundPrimary};
        background-color: ${props => props.theme.themeColors.backgroundTertiary};

        section {
            height: inherit;
            padding: 2rem 5rem;

            display: flex;
        }
    }

    &.layoutVariation__ {
        height: 100vh;
        padding: 0;
        background-color: ${props => props.theme.colors.colorPrimary};

        main {
            height: 100%;
            border: none;
            background-color: transparent;

            section {
                width: inherit;
                padding: 2rem;

                display: inline;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 5rem 1rem 1rem 1rem;

        main {
            min-height: 70vh;

            section {
                min-height: 70vh;
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    @media (max-width: 500px) {
        main {
            min-height: 100%;

            section {
                min-height: 100%;
            }
        }
    }
`;
