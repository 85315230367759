import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

// CONTEXTS...
import { TenantContext } from '../../../helpers/contexts/TenantContext';

// COMPONENTS...
import { LayoutBase } from '../../../components/Layout/LayoutBase';
import { ListNav } from '../../../components/List/List';
import { Header } from '../../../components/Header/Header';

// STYLES...
import { HomeBox, HomeContent } from './HomeStyled';
import { Select } from '../../../components/Form/FormStyled';

export function Home() {
    const { tenant } = useContext(TenantContext);

    const [tenantPrefix, setTenantPrefix] = useState('');
    const [linkTenant, setLinkTenant] = useState('');

    function handleSelectedTenant(host: string) {
        let hostFull = host;
        var hostPrefix = '';

        if (process.env.NODE_ENV !== 'production') {
            hostPrefix = hostFull.replace('.localhost:3000', '');
        } else {
            hostPrefix = hostFull.replace('.servicosaocidadao.net.br', '');
        }

        let newUrl = `http://${hostFull}`;

        setTenantPrefix(hostPrefix);
        setLinkTenant(`${newUrl}/services`);
    }

    const handleReturnHome = () => {
        let uri = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
        var captureUrl = 0;

        if (process.env.NODE_ENV !== 'production') {
            captureUrl = uri.indexOf('localhost:3000');
        } else {
            captureUrl = uri.indexOf('servicosaocidadao.net.br');
        }

        if (captureUrl !== -1) {
            let url = uri.slice(captureUrl);
            window.location.href = `http://${url}`;
        } else {
            console.log('Não existe URL padrão na URI');
        }
    }

    return (
        <LayoutBase name="Home Page" id="homePage">
            <Header>
                <ListNav>
                    <li>
                        <span className="itemButton__" onClick={handleReturnHome}>
                            <span className="icon__ home"></span>
                            Home
                        </span>
                    </li>

                    <li>
                        <Link to="/about">
                            <span className="icon__ code__infoCircle"></span>
                            Sobre
                        </Link>
                    </li>
                </ListNav>
            </Header>

            <HomeContent>
                <div className="home__infos">
                    <h1> Serviços <br /> ao cidadão<span className="full__stop">.</span> </h1>
                    <p> Selecione abaixo o órgão público em que deseja fazer fazer a sua solicitação. </p>
                </div>

                {/* <div className="home__img"> */}
                    {/* <img src={peopleImg} alt="" /> */}
                {/* </div> */}
            </HomeContent>

            <HomeBox>
                <Select name="select" defaultValue={tenantPrefix} onChange={(event) => handleSelectedTenant(event.target.value)}>
                    <option value="" disabled> Selecione </option>

                    {tenant?.map(tenant => {
                        return (
                            <option
                                key={tenant.id}
                                id={tenant.id.toString()}
                                value={tenant.host}
                            >
                                {tenant.name}
                            </option>
                        );
                    })}
                </Select>

                <a className="" id="selectClient" href={linkTenant}> Selecionar </a>
            </HomeBox>
        </LayoutBase>
    );
}
