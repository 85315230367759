import styled from 'styled-components';

export const HeaderContainer = styled.header`
    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    width: 100%;
    height: auto;
    padding: 0 1.8rem;
    /* border-bottom: 1px solid ${props => props.theme.themeColors.backgroundPrimary}; */
    background-color: ${props => props.theme.themeColors.colorGlass};
    -webkit-backdrop-filter: blur(0.375rem);
    backdrop-filter: blur(0.375rem);

    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const HeaderFormContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 2rem;
    padding-bottom: 0;
`;