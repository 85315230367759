import { useEffect, useState } from 'react';

// HOOKS...
import useApi from '../../../../helpers/hooks/useApi';
import { useAuth } from '../../../../helpers/hooks/useAuth';

// COMPONENTS...
import { LayoutDashboardPanel } from '../../../../components/Layout/LayoutDashboard';
import { Table, TableHead, TableBody } from '../../../../components/Table/Table';
import { Badge } from '../../../../components/Badge/Badge';
import { DropdownButton, DropdownItemLink } from '../../../../components/Dropdown/Dropdown';
import { Alert } from '../../../../components/Alert/Alert';

// TYPES...
import { StreetLightingType } from '../../../../helpers/types/Types';

// STYLES...
import { Form } from '../../../../components/Form/FormStyled';

export function StreetLightingRead() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const api = useApi();
    const auth = useAuth();

    const token = auth.getToken();
    const [streetLightings, setStreetLightings] = useState<StreetLightingType[]>([]);
    const [error, setError] = useState('');

    const [backendToken, setBackendToken] = useState(false);

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    const getStreetLightings = async () => {
        const street = await api.getStreetLightings(host, token);
        setStreetLightings(street.data);
    }

    useEffect(() => { getStreetLightings(); }, []);

    const handleStatus = async (uuid: string, status: number) => {
        const data = await api.setStreetLightingStatus(host, token, uuid, status);

        if (data.error) {
            setError(data.error);
        } else {
            getStreetLightings();
        }
    }

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="dashboardPanel"
                title="Iluminações públicas"
                description="Lista de serviços para iluminações públicas"
            >
                {error &&
                    <Alert
                        isError
                        closeAfter={7000}
                        title="Erro"
                        icon="code__errorCircle"
                        message={error}
                        state={error}
                        setState={setError}
                    />
                }

                <Table id="tableSectors">
                    <TableHead>
                        <tr>
                            <th scope="col"> # </th>
                            <th scope="col"> Código </th>
                            <th scope="col"> Nome </th>
                            <th scope="col"> Data </th>
                            <th scope="col"> Status </th>
                            <th scope="col"> Ações </th>
                        </tr>
                    </TableHead>

                    <TableBody>
                        {streetLightings.map((streetLighting, index) => {
                            return (
                                <tr key={index}>
                                    <td> {streetLighting.id} </td>
                                    <td> {streetLighting.code} </td>
                                    <td> {streetLighting.name} </td>
                                    <td> {streetLighting.created_at} </td>
                                    <td>
                                        {(() => {
                                            if (streetLighting.status === 'Pendente') {
                                                return <Badge isWarning> {streetLighting.status} </Badge>

                                            } else if (streetLighting.status === 'Em andamento') {
                                                return <Badge isProcess> {streetLighting.status} </Badge>

                                            } else if (streetLighting.status === 'Concluída') {
                                                return <Badge isSuccess> {streetLighting.status} </Badge>

                                            } else if (streetLighting.status === 'Negada') {
                                                return <Badge isDanger> {streetLighting.status} </Badge>
                                            }
                                        })()}
                                    </td>
                                    <td className="td__action">
                                        <DropdownButton
                                            id={streetLighting.uuid}
                                            childrenId={streetLighting.uuid}
                                            name="Ações"
                                        >
                                            <DropdownItemLink
                                                link={`/panel/client/street-lightings/show/${streetLighting.uuid}`}
                                                name="Detalhes"
                                                icon="general__visible"
                                            />

                                            {(streetLighting.status === 'Pendente' || streetLighting.status === 'Em andamento') &&
                                                <DropdownItemLink
                                                    link={`/panel/client/street-lightings/reply/${streetLighting.uuid}`}
                                                    name="Responder"
                                                    icon="communication__chat5"
                                                />
                                            }

                                            <Form
                                                className="full__"
                                                onClick={() => handleStatus(streetLighting.uuid, 3)}
                                            >
                                                <DropdownItemLink
                                                    link=""
                                                    name="Concluir solicitação"
                                                    icon="navigation__check"
                                                />
                                            </Form>

                                            {/* <Form
                                                className="full__"
                                                onClick={() => handleStatus(streetLighting.uuid, 4)}
                                            >
                                                <DropdownItemLink
                                                    link=""
                                                    name="Negar solicitação"
                                                    icon="navigation__close"
                                                />
                                            </Form> */}
                                        </DropdownButton>
                                    </td>
                                </tr>
                            );
                        })}
                    </TableBody>
                </Table>
            </LayoutDashboardPanel>
            : <></>
    );
}