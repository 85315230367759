// COMPONENTS...
import { FooterDomain } from "../Footer/Footer";

// STYLES...
import { LayoutBaseContainer } from "./LayoutBaseStyled";

// TYPES...
import { LayoutBaseType } from "./LayoutBaseType";

export function LayoutBase({ id, name, children, variant }: LayoutBaseType) {
    return (
        <LayoutBaseContainer className={`${variant ? 'layoutVariation__' : ''}`}>
            <main>
                <section id={id}>
                    <h6 hidden> {name} </h6>

                    {children}
                </section>
            </main>

            <FooterDomain />
        </LayoutBaseContainer>
    );
}