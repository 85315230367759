// STYLES...
import { TableContainer, TheadContainer, TbodyContainer } from './TableStyled';

// TYPES...
import { TableElementsType, TableType } from './TableType';

export function Table({ id, children }: TableType) {
    return (
        <TableContainer> {children} </TableContainer>
    );
}

export function TableHead({ children }: TableElementsType) {
    return (
        <TheadContainer> {children} </TheadContainer>
    );
}

export function TableBody({ children }: TableElementsType) {
    return (
        <TbodyContainer> {children} </TbodyContainer>
    );
}
