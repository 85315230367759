// COMPONENTS...
import { LayoutAuth } from '../../../../components/Layout/LayoutAuth';
import { Fieldset, Input } from '../../../../components/Form/Form';
import { Button } from '../../../../components/Button/Button';

// STYLES...
import { FormItem, Label } from '../../../../components/Form/FormStyled';

export function ForgotItAdmin() {
    return (
        <LayoutAuth
            title="Informe o email para recuperação"
            returnText="home"
            returnTo="/"
            notHave="Lembrou a senha?"
            notHaveText="Logar"
            notHaveTo="/auth/admin/login"
        >
            <form className="formAuth" id="formLogin">
                <Fieldset
                    toTheForm="formAuthForgotIt"
                    name="ForgotIt"
                    title="Esqueceu a senha"
                >
                    <FormItem>
                        <Label htmlFor="email"> E-mail </Label>
                        <Input
                            id="email"
                            type="email"
                            placeholder="Informe o email"
                        />
                    </FormItem>
                </Fieldset>

                <Button isFull type="submit"> Entrar </Button>
            </form>
        </LayoutAuth>
    );
}