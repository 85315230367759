import { Link } from 'react-router-dom';

// STYLES...
import { FooterDomainContainer } from './FooterStyled';

export function FooterDomain() {
    return (
        <FooterDomainContainer>
            <address>
                &copy; Todos os direitos reservados.
                <Link to="http://www.digitaliza.com.br"> Digitaliza Tecnologia </Link>
            </address>
        </FooterDomainContainer>
    );
}