import { Link } from "react-router-dom";

// HOOKS....
import { useAuth } from "../../../helpers/hooks/useAuth";

// COMPONENTS...
import { LayoutBase } from "../../../components/Layout/LayoutBase";
import { ListNav } from "../../../components/List/List";
import { CardService } from "../../../components/Card/Card";
import { Header } from "../../../components/Header/Header";

// STYLES...
import { ServiceWrapper } from "./ServiceStyled";

export function Service() {
    const auth = useAuth();

    const handleReturnHome = () => {
        let uri = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
        var captureUrl = 0;

        if (process.env.NODE_ENV !== 'production') {
            captureUrl = uri.indexOf('localhost:3000');
        } else {
            captureUrl = uri.indexOf('servicosaocidadao.net.br');
        }

        if (captureUrl !== -1) {
            let url = uri.slice(captureUrl);
            window.location.href = `http://${url}`;
        } else {
            console.log('Não existe URL padrão na URI');
        }
    }

    return (
        <LayoutBase name="Service Page" id="servicePage">
            <Header>
                <ListNav>
                    <li>
                        <span className="itemButton__" onClick={handleReturnHome}>
                            <span className="icon__ home"></span>
                            Home
                        </span>
                    </li>

                    <li>
                        <Link to="/search-code">
                            <span className="icon__ general__search"></span>
                            Consultar solicitação
                        </Link>
                    </li>

                    {auth.isLogged()
                        ? <li>
                            <Link to="/panel/client/dashboard">
                                <span className="icon__ layout__arrange"></span>
                                Dashboard
                            </Link>
                        </li>

                        : <li>
                            <Link to="/auth/client/login">
                                <span className="icon__ communication__shieldUser"></span>
                                Login
                            </Link>
                        </li>
                    }
                </ListNav>
            </Header>

            <ServiceWrapper>
                <h1> Nossos <br /> Serviços<span className="full__stop">.</span> </h1>
                <p> Selecione qual o tipo de serviço que você precisa no momento e faça uma solicitação ao órgão. </p>

                <div className="service__inner">
                    <CardService
                        route="ombudsman"
                        title="Ouvidoria"
                        description="É o canal por meio do qual o cidadão pode apresentar sugestões, reclamações, solicitações, elogios e denúncias sobre a prestação de serviços públicos."
                    />

                    <CardService
                        route="e-sic"
                        title="e-SIC"
                        description="Permite que qualquer pessoa, encaminhe pedidos de acesso à informação, acompanhe o prazo e receba a resposta da solicitação realizada para órgãos públicos."
                    />

                    <CardService
                        route="pruning-tree"
                        title="Poda de árvore"
                        description="Através deste serviço poderá ser solicitado a poda de árvores localizadas em vias públicas."
                    />

                    <CardService
                        route="street-lighting"
                        title="Iluminação pública"
                        description="Manutenção da rede de iluminação pública municipal nas áreas urbanas, incluindo a troca das lâmpadas, manutenção, extensão das redes."
                    />
                </div>
            </ServiceWrapper>
        </LayoutBase>
    );
}
