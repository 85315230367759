import styled from "styled-components";

export const DropdownContainer = styled.div`
    position: relative;

    cursor: pointer;

    height: 3rem;
    padding: 0 2rem;
    border: none;
    border-radius: 0.75rem;
    background-color: ${props => props.theme.themeColors.backgroundSecondary};
    transition: background-color .2s;

    font-weight: 500;
    font-size: 1rem;
    color: ${props => props.theme.themeColors.textPrimary};

    display: flex;
    align-items: center;
    justify-content: center;

    .button__icon.icon__ {
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);

        width: 1.3rem;
        height: 1.3rem;
        background-color: ${props => props.theme.themeColors.textSecondary};
    }
`;

export const DropdownChildrenContainer = styled.ul`
    z-index: 10;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);

    list-style-type: none;
    width: 15rem;
    height: auto;
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 1.25rem;
    border: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary}; 
    background-color: ${props => props.theme.themeColors.backgroundPrimary};
    box-shadow: 0px 18px 32px -18px rgb(71 57 157 / 30%);

    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &::before {
        content: "";

        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%) rotate(49deg);

        width: 1.25rem;
        height: 1.25rem;
        /* border-radius: 0.44rem; */
        border-top: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};
        border-left: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};
        background-color: ${props => props.theme.themeColors.backgroundPrimary};
    }

    &.isOpen__ {
        display: flex;
    }

    a {
        width: 100%;
        margin: 0.2rem 0;
        padding: 0.8rem 0 0.8rem 0.5rem;
        border-radius:0.75rem;
        border-bottom: 0.063rem solid ${props => props.theme.themeColors.backgroundSecondary};

        font-style: normal;
        font-size: 0.9rem;
        color: ${props => props.theme.themeColors.textSecondary};
        text-decoration: none;
        outline: none;
        
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .icon__ {
            width: 1.3rem;
            height: 1.3rem;
            margin-right: 0.5rem;
            background-color: ${props => props.theme.themeColors.textSecondary};
        }

        &:hover {
            background-color: ${props => props.theme.themeColors.backgroundSecondary};

            .icon__ {
                background-color: ${props => props.theme.colors.colorPrimary};
            }
        }
    }
`;