import styled from "styled-components";

export const HomeContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 2rem 5rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .home__infos,
    .home__img {
        flex: 1;

        max-width: 800px;
        height: inherit;
        border-radius: 1.25rem;

        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    .home__infos {
        padding: 1.5rem;
        padding-left: 4rem;

        h1 {
            font-size: 4.3rem;
            color: ${props => props.theme.themeColors.textPrimary};
        }
    }

    .home__img {
        align-items: flex-end;

        img {
            width: auto;
            height: 37.5rem;
        }
    }

    @media (max-width: 768px) {
        padding-right: 0;
        padding-left: 0;

        .home__infos {
            width: 100%;
            max-width: 100%;
        }
    }

    @media (max-width: 500px) {
        .home__infos {
            padding-left: 1.5rem;

            h1 {
                font-size: 2.5rem;
            }
        }
    }
`;

export const HomeBox = styled.div`
    position: absolute;
    bottom: -75px;
    left: 50%;
    transform: translateX(-50%);

    width: 70%;
    height: 9.375rem;
    padding: 0 2rem;
    border-radius: 1.25rem;
    border: 0.063rem solid ${props => props.theme.themeColors.backgroundPrimary};
    background-color: ${props => props.theme.themeColors.colorGlass};
    -webkit-backdrop-filter: blur(0.375rem);
    backdrop-filter: blur(0.375rem);

    display: flex;
    align-items: center;
    justify-content: center;

    select {
        flex: 1;

        cursor: pointer;
        height: 3.5rem;
        padding: 0 1rem;
    }

    &.box__alternate {
        height: auto;
        padding: 1rem 2rem;

        form {
            width: 100%;
            padding: 0;
            background-color: transparent;
        }
    }

    #selectClient {
        cursor: pointer;

        height: 3rem;
        margin-left: 1rem;
        padding: 0 1.5rem;
        border: none;
        border-radius: 0.75rem;
        background: ${props => props.theme.colors.colorGradient};
        transition: background-color .2s;

        font-weight: 500;
        font-size: 1rem;
        color: ${props => props.theme.themeColors.textPrimary};

        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 768px) {
        flex-direction: column;

        height: auto;
        padding: 1rem;

        select, #selectClient {
            width: 100%;
            margin: 0.7rem 0;
        }
    }

    @media (max-width: 500px) {
        width: 90%;
    }
`;