import styled from "styled-components";

export const CardServiceContainer = styled.article`
    position: relative;

    width: 19rem;
    height: 20rem;
    margin: 0.5rem;
    padding: 2rem;
    border-radius: 1.25rem;
    border: 0.063rem solid ${props => props.theme.themeColors.backgroundPrimary};
    transition: 1s background-color ease-in-out;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    a {
        position: absolute;
        inset: 0 0 0 0;
    }

    .icon__ {
        background-color: ${props => props.theme.colors.colorPrimary};
    }

    h2 {
        margin-bottom: 2rem;

        text-align: center;
    }

    p {
        line-height: 1.5;
        /* text-align: justify; */
    }

    &:not(:disabled):is(:hover, :focus) {
        background-color: ${props => props.theme.colors.colorPrimary};

        .icon__ {
            background-color: ${props => props.theme.themeColors.backgroundPrimary};
        }

        p {
            color: ${props => props.theme.themeColors.backgroundPrimary};
        }
    }
`;

export const CardDlInfoContainer = styled.article`
    width: fit-content;
    min-width: 21.875rem;
    min-height: 4.375rem;
    margin: 0.5rem;
    border-radius: 0.74rem;
    border: 0.063rem solid ${props => props.theme.themeColors.backgroundPrimary};

    display: flex;

    dt {
        width: 4rem;
        height: inherit;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;

        display: flex;
        align-items: center;
        justify-content: center;

        div {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            background-color: ${props => props.theme.themeColors.backgroundSecondary};

            display: flex;
            align-items: center;
            justify-content: center;

            .icon__ {
                width: 1.5rem;
                height: 1.5rem;
                background-color: ${props => props.theme.colors.blue};
            }
        }
    }

    dd {
        width: 100%;
        height: inherit;
        padding: 0.3rem 0.5rem 0.3rem 1rem;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        line-height: 1.4rem;

        small {
            display: block;

            font-style: italic;
            color: ${props => props.theme.themeColors.textSecondary};
        }
    }

    &.success__ {
        dt div {
            background-color: ${props => props.theme.colors.green};

            .icon__ {
                background-color: ${props => props.theme.themeColors.textPrimary};
            }
        }

        dd {
            p {
                color: ${props => props.theme.colors.green};
                font-weight: bold;
            }
        }
    }

    &.danger__ {
        dt div {
            background-color: ${props => props.theme.colors.red};

            .icon__ {
                background-color: ${props => props.theme.themeColors.textPrimary};
            }
        }

        dd {
            p {
                color: ${props => props.theme.colors.red};
                font-weight: bold;
            }
        }
    }

    &.primary__ {
        dt div {
            background-color: ${props => props.theme.colors.colorPrimary};

            .icon__ {
                background-color: ${props => props.theme.themeColors.textPrimary};
            }
        }

        dd {
            p {
                color: ${props => props.theme.colors.colorPrimary};
                font-weight: bold;
            }
        }
    }

    &.warning__ {
        dt div {
            background-color: ${props => props.theme.colors.yellow};

            .icon__ {
                background-color: #000;
            }
        }

        dd {
            p {
                color: ${props => props.theme.colors.yellow};
                font-weight: bold;
            }
        }
    }
`;