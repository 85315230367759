// import Cookies from 'js-cookie';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

// HOOKS...
// import useApi from './useApi';

// TYPES...
type UserCredentialsType = {
    access_token?: string;
    rememberPassword?: boolean;
    user?: UserType;
}

type UserType = {
    id?: number;
    name?: string;
    email?: string;
}

export function useAuth() {
    // const api = useApi();
    const navigate = useNavigate();
    const [user, setUser] = useState<UserCredentialsType>({});
    const [logged, setLogged] = useState(false);

    function doSignIn(data: UserCredentialsType) { // Fazer login...
        const userStorage = {
            token: data?.access_token,
            id: data.user?.id,
            name: data.user?.name,
            email: data.user?.email,
        }
        const userJson = JSON.stringify(userStorage);

        if (data.rememberPassword) {
            localStorage.setItem('u', userJson);
            setLogged(true);

        } else { // Limitado ao navegador, quando fechar, limpa o cookie...
            localStorage.setItem('u', userJson);
            setLogged(true);
        }
    }

    function doLogout() { // Fazer logout...
        setLogged(false);
        localStorage.removeItem('u');
        navigate('/auth/client/login');
    }

    function isLogged() { // Está logado?...
        const user = transformJsonInObject();
        let token = '';

        if (user !== null) {
            token = user.token;
        }

        return (token) ? true : false;
    }

    function getToken() { // Pega o token...
        const user = transformJsonInObject();
        const token = user.token;

        return token;
    }

    function getUser() { // Pegas os dados do usuário...
        const user = transformJsonInObject();
        const userId = user.id;
        const userName = user.name;
        const userEmail = user.email;

        return { userId, userName, userEmail }
    }

    function transformJsonInObject() { // Busca no localStorage o usuário e transforma em objeto...
        const userStorage = localStorage.getItem('u'); // Pega no localStorage...
        let user = null;

        if (userStorage !== null) { // Verifica se é diferente de nulo...
            user = JSON.parse(userStorage); // Converte o JSON em objeto...
        }

        return user;
    }

    return { doSignIn, doLogout, isLogged, getToken, getUser };
}