import styled from "styled-components";

// EXTENDING...
import { LayoutBaseContainer } from "../../../components/Layout/LayoutBaseStyled";
import { ChatContainer } from "../../../components/Chat/ChatStyled";

export const SolicitationPage = styled(LayoutBaseContainer)`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding: 0;
    background-color: ${props => props.theme.themeColors.backgroundTertiary};

    display: flex;
    flex-wrap: wrap;

    .base__content {
        height: 100%;
        border: none;
        background-color: transparent;

        .base__inner {
            width: inherit;
            padding: 2rem;

            display: inline;
        }
    }

    @media (max-width: 768px) {
        overflow: auto;
        display: block;
    }
`;

export const SolicitationContent = styled.div`
    position: relative;
    flex: 1;

    height: 100vh;
    overflow-y: auto;
    padding: 2rem;

    header {
        left: auto;
        right: 40.625rem;
    }

    .solicitation__header {
        margin-top: 5rem;
        margin-bottom: 2rem;
        padding: 2rem;
        border-bottom: 0.063rem solid ${props => props.theme.themeColors.backgroundPrimary};
    }

    dl {
        padding: 0 2rem;

        .line__itens {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
        }
    }

    @media (max-width: 1024px) {
        padding: 2rem 0;

        dl {
            .line__itens {
                article {
                    min-width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
        overflow-y: visible;
    }
`;

export const SolicitationChat = styled(ChatContainer)`
    width: 40.625rem;
    min-height: 100vh;
    border-radius: 0;
    background-color: ${props => props.theme.themeColors.backgroundPrimary};

    @media (max-width: 1024px) {
        flex: 1;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
        min-height: auto;

        dl {
            .chat__inner {
                height: 18.75rem;
                overflow-y: auto;
            }

            div {
                position: relative;
                right: auto;
                right: auto;
                left: auto;

                form {
                    .form__content {

                    }
                }
            }
        }
    }

    @media (max-width: 425px) {
        dl {
            padding: 0.5rem;

            .chat__inner {
                padding: 0;
            }

            div {
                padding: 1rem 0;

                form {
                    .form__content {

                    }
                }
            }
        }
    }
`;