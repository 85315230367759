import { useEffect, useState } from 'react';

// HOOKS...
import useApi from '../../../../helpers/hooks/useApi';
import { useAuth } from '../../../../helpers/hooks/useAuth';

// COMPONENTS...
import { LayoutDashboardPanel } from '../../../../components/Layout/LayoutDashboard';
import { Table, TableHead, TableBody } from '../../../../components/Table/Table';
import { DropdownButton, DropdownItemLink } from '../../../../components/Dropdown/Dropdown';
import { Alert } from '../../../../components/Alert/Alert';

// TYPES...
import { SectorType } from '../../../../helpers/types/Types';

// STYLES...
import { Form } from '../../../../components/Form/FormStyled';

export function SectorRead() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];

    const api = useApi();
    const auth = useAuth();
    const token = auth.getToken();

    const [backendToken, setBackendToken] = useState(false);
    const [error, setError] = useState('');
    const [sectors, setSectors] = useState<SectorType[]>([]);

    useEffect(() => {
        const verifyTokenBackend = async () => {
            const tk = await api.isMe(host, token);

            if (tk.token_active) {
                setBackendToken(true);
            } else {
                setBackendToken(false);
                auth.doLogout(); // Desloga no front...
                window.location.href = '/auth/client/login'; // Redireciona...
            }
        }
        verifyTokenBackend();
    }, []);

    const getSectors = async () => {
        const secs = await api.getSectors(host);
        setSectors(secs.data);
    }

    useEffect(() => { getSectors(); }, []);

    const handleDelete = async (id: number) => {
        const data = await api.setSectorDelete(host, token, id);

        if (data.error) {
            setError(data.error);
        } else {
            getSectors(); // Chama a função para consultar...
        }
    }

    return (
        token && backendToken
            ?
            <LayoutDashboardPanel
                id="dashboardPanel"
                title="Setores"
                description="Lista de setores cadastrados"
                linkId="returnListSectors"
                linkRoute="/panel/client/sectors/create"
                linkName="Adicionar"
                linkIcon="code__plus"
            >
                {error &&
                    <Alert
                        isError
                        closeAfter={7000}
                        title="Erro"
                        icon="code__errorCircle"
                        message={error}
                        state={error}
                        setState={setError}
                    />
                }

                <Table id="tableSectors">
                    <TableHead>
                        <tr>
                            <th scope="col"> # </th>
                            <th scope="col"> Nome </th>
                            {/* <th scope="col"> Tipo </th> */}
                            <th scope="col"> Ações </th>
                        </tr>
                    </TableHead>

                    <TableBody>
                        {sectors.map((sector, index) => {
                            return (
                                <tr key={index}>
                                    <td> {sector.id} </td>
                                    <td> {sector.name} </td>
                                    {/* <td> {sector.type.formated} </td> */}
                                    <td className="td__action">
                                        <DropdownButton
                                            id={sector.id.toString()}
                                            childrenId={sector.id.toString()}
                                            name="Ações"
                                        >
                                            <DropdownItemLink
                                                link={`/panel/client/sectors/edit/${sector.id}`}
                                                name="Editar"
                                                icon="general__edit"
                                            />

                                            <Form
                                                className="full__"
                                                onClick={() => handleDelete(sector.id)}
                                            >
                                                <DropdownItemLink
                                                    link=""
                                                    name="Deletar"
                                                    icon="general__trash"
                                                />
                                            </Form>
                                        </DropdownButton>
                                    </td>
                                </tr>
                            );
                        })}
                    </TableBody>
                </Table>
            </LayoutDashboardPanel>
            : <></>
    );
}