import { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// HOOKS...
import useApi from "../../../helpers/hooks/useApi";

// COMPONENTS...
import { LayoutBase } from "../../../components/Layout/LayoutBase";
import { Input } from "../../../components/Form/Form";
import { Button } from "../../../components/Button/Button";
import { ListNav } from "../../../components/List/List";
import { Alert } from "../../../components/Alert/Alert";
import { Header } from "../../../components/Header/Header";

// STYLES...
import { Form, FormGroup, FormItem, Label } from '../../../components/Form/FormStyled';
import { HomeBox, HomeContent } from "./SearchCodeStyled";

export function SearchCode() {
    const host = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
    const api = useApi();
    const navigate = useNavigate();

    const [error, setError] = useState('');
    const [searchCode, setSearchCode] = useState('');

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = await api.setSearchCode(host, searchCode);

        if (data.error) {
            setError(data.error);
        } else {
            setSearchCode('');
            localStorage.removeItem('solicitation');
            localStorage.setItem('solicitation', JSON.stringify(data));
            navigate('/solicitation');
        }
    }

    const handleReturnHome = () => {
        let uri = window.location.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``).split('/')[0];
        var captureUrl = 0;

        if (process.env.NODE_ENV !== 'production') {
            captureUrl = uri.indexOf('localhost:3000');
        } else {
            captureUrl = uri.indexOf('servicosaocidadao.net.br');
        }

        if (captureUrl !== -1) {
            let url = uri.slice(captureUrl);
            window.location.href = `http://${url}`;
        } else {
            console.log('Não existe URL padrão na URI');
        }
    }

    return (
        <LayoutBase name="Home Page" id="homePage">
            {error &&
                <Alert
                    isError
                    closeAfter={7000}
                    title="Erro"
                    icon="code__errorCircle"
                    message={error}
                    state={error}
                    setState={setError}
                />
            }

            <Header>
                <ListNav>
                    <li>
                        <span className="itemButton__" onClick={handleReturnHome}>
                            <span className="icon__ home"></span>
                            Home
                        </span>
                    </li>

                    <li>
                        <Link to="/services">
                            <span className="icon__ general__settings2"></span>
                            Serviços
                        </Link>
                    </li>
                </ListNav>
            </Header>

            <HomeContent>
                <div className="home__infos">
                    <h1> Consultar solicitação<span className="full__stop">.</span> </h1>
                    <p> Informe aqui seu código e acompanhe todo o andamento da sua solicitação. </p>
                </div>
            </HomeContent>

            <HomeBox className="box__alternate">
                <Form method="post" id="formSearch" onSubmit={handleSubmit}>
                    <FormGroup>
                        <FormItem className="form__item">
                            <Label htmlFor="searchCode"> Informe o código <small id="info" className="required__field"> * </small> </Label>
                            <Input
                                id="searchCode" type="search" name="searchCode" required aria-describedby="info"
                                value={searchCode} onChange={event => setSearchCode(event.target.value)}
                            />
                        </FormItem>

                        <Button type="submit" name="enviar__search">
                            <span className="icon__ general__search"></span>
                            Pesquisar
                        </Button>
                    </FormGroup>
                </Form>
            </HomeBox>
        </LayoutBase>
    );
}