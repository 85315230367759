import { useState } from "react";
import { Link } from "react-router-dom";

// STYLES...
import { DropdownChildrenContainer, DropdownContainer } from "./DropdownStyled";

// TYPES...
import { DropdownButtonType, DropdownItemButtonType, DropdownItemLinkType } from "./DropdownType";

export function DropdownButton({ id, name, children, childrenId }: DropdownButtonType) {
    const [isOpen, setIsOpen] = useState(false);

    const handleDropdown = (id: string, childrenId: string) => {
        let dad = id;
        let children = childrenId;

        if (isOpen === true) {
            if (dad === children) setIsOpen(false);
        } else {
            if (dad === children) setIsOpen(true);
        }
    }

    return (
        <DropdownContainer
            id={id}
            onClick={event => handleDropdown(id, childrenId)}
        >
            <span className="button__icon icon__ navigation__angleDown"></span>
            {name}

            <DropdownChildrenContainer
                id={childrenId}
                className={`${isOpen ? 'isOpen__' : ''}`}
            >
                {children}
            </DropdownChildrenContainer>
        </DropdownContainer>
    );
}

export function DropdownItemLink({ name, link, icon }: DropdownItemLinkType) {
    return (
        <Link to={link}>
            {icon && <span className={`icon__ ${icon}`}></span>}
            {name}
        </Link>
    );
}

export function DropdownItemButtom({ name, icon }: DropdownItemButtonType) {
    return (
        <button>
            {icon && <span className={`icon__ ${icon}`}></span>}
            {name}
        </button>
    );
}