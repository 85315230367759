// COMPONENTS...
import { LinkSimple } from "../Link/Link";

// STYLES...
import { HeaderContainer, HeaderFormContainer } from "./HeaderStyled";

// TYPES...
import { HeaderFormType, HeaderType } from "./HeaderType";

export function Header({ children }: HeaderType) {
    return (
        <HeaderContainer> {children} </HeaderContainer>
    );
}

export function HeaderForm({ title, description }: HeaderFormType) {
    return (
        <HeaderFormContainer>
            <h2> {title} </h2>
            <p> {description} </p>
            <p>
                Seus dados pessoais estarão protegidos, nos termos da
                <LinkSimple
                    link="http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2017/Lei/L13460.htm"
                    linkType="noreferrer"
                    target="_blank"
                > Lei 13.460/2017 </LinkSimple>.
            </p>
            <p> Todos os campos com  <span className="text__ text__danger"> * </span> são de preenchimento obrigatórios </p>
        </HeaderFormContainer>
    );
}

